import React from 'react';
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaArticleScreen } from '../constants/metaDescriptions';
import "../assets/styles/ArticleScreen.css";
import ArticleTitleSection from '../components/Article/ArticleTitleSection';
import ArticleTextSection from '../components/Article/ArticleTextSection';
import ArticleDidYouKnowSection from '../components/Article/ArticleDidYouKnowSection';
import ArticleFAQSection from '../components/Article/ArticleFAQSection';
import ArticleRelatedContainer from '../components/Article/ArticleRelatedContainer';
import Articles from '../constants/Articles';
import ArticleListComponent from '../components/Article/ArticleListComponent';

const ArticleScreen = (props) => {

  let url = props.match.params.articleURL;
  let id = 0;

  for (let i = 0; i < Articles.length; i++) {
    if (Articles[i].url === url) {
      id = Articles[i].id;
    }
  }

  return (
    <div style={{ background: 'radial-gradient(#aefbe7, #fafffe)' }}>
      <Helmet>
        <title>Rapunzl | Articles To Learn More</title>
        <meta name="description" content={MetaArticleScreen} />
        <meta property="og:title" content="Rapunzl | Articles To Learn More" />
        <meta property="og:description" content={MetaArticleScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/article" />
        <link rel="canonical" href="https://www.rapunzl.com/article" />
      </Helmet>
      <Header />
      <ArticleTitleSection
        title={Articles[id].title}
        subtitle={Articles[id].subtitle}
      />
      {Articles[id].content.map((item, index) => {
        if (item.type === 'text') {
          return (
            <ArticleTextSection
              subtitle={item.subtitle}
              text={item.content}
            />
          );
        } else if (item.type === 'didYouKnow') {
          return (
            <ArticleDidYouKnowSection
              text={item.content}
            />
          );
        } else if (item.type === 'list') {
          return (
            <ArticleListComponent
              data={item}
              index={index}
            />
          )
        } else if (item.type === 'faq') {
          return (
            <ArticleFAQSection
              content={item.content}
            />
          );
        } else if (item.type === 'related') {
          return (
            <ArticleRelatedContainer
              relatedArticles={item.content}
            />
          );
        } else {
          return <div />
        }
      })}
      <GoToTop />
      <Footer />
    </div>
  );
};
export default ArticleScreen;