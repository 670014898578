import React from "react";
import "../../assets/styles/EducatorsScreen.css";

const Accessibility = (props) => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '0px', marginTop: '0px', paddingBottom: props.screenName === 'course' ? '150px' : '30px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="Rapunzl personal finance program was built for accessibility" src="/images/Educators/Accessibility.webp"/>
      <div>
        <h2 className="educator-header">
          Accessible & Engaging Personal Finance Curriculum
        </h2>
        <div className="educator-text">
        Rapunzl is accessible to students, teachers, parents, and guardians seeking to promote financial literacy.
        <br /><br />
        All products are ADA compliant, making it suitable for educating students with diverse learning needs, including those who need support with English as a Second Language, Special Education, cognitive disabilities, and auditory processing.
          </div>
      </div>
    </div>
  );
};

export default Accessibility;