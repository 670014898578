import React from 'react';
import { Link } from 'react-router-dom';
import GoToTop from './GoToTop';

const NotFoundContainer = () => {
  return (
    <div className='route-subcontainer' style={{ paddingTop: 280, paddingBottom: 200, backgroundColor: '#007154' }}>
      <img
        alt='Empty 404 Page For rapunzl persoanl finance program website' 
        className='not-found-img'
        src='/images/Admin/NotFound.webp'
      />
      <h1 className='not-found-h1'>
        Rapunzl 404 Error
      </h1>
      <div className='not-found-p'>
        The requested URL was not found on this server. You may have clicked an expired or corrupted link.
      </div>
      <Link to='/'>
        <div className='not-found-return-button'>
          Back to Main Site
        </div>
      </Link>
      <Link to='/contact'>
        <div className='not-found-issue-button'>
          Report An Issue With Rapunzl
        </div>
      </Link>
      <GoToTop />
    </div>
  );
};

export default NotFoundContainer;