import React from "react";
import { Link } from "react-router-dom";
import '../../assets/styles/TeacherTestimonials.css';

const TeacherTestimonials = (props) => {
  return (
    <div className={props.alt ? "testimonials-alt-container" : "testimonials-container"}>
      <img loading="lazy" alt="microphone stylistic" className="testimonials-header-image" src="/images/Educators/Testimonials.webp"/>
      <h2 className="testimonials-header-text" style={{ color: props.alt ? '#ffc600' : '#02281e' }}>
        What Teachers Are Saying About Rapunzl
      </h2>
      <div className="testimonials-width-restriction">
        <div className="testimonials-card">
          <div className="testimonials-card-header">
            <img loading="lazy" alt='testimonial rapunzl teacher financial literacy thumbnail' className="testimonial-image" src="/images/Educators/JoshuaLevine.webp"/>
          </div>
          <div className="testimonial-card-text">
            Rapunzl has been a game-changer for my students since I started using it in 2019.
            <br/>
            <br/>
            I can provide hands-on experience with real-world learning, bringing economic theories to life and enabling students to see the immediate impact of market changes while learning discipline in a risk-free environment.
          </div>
          <div className="testimonial-card-name">
            Joshua Levine
          </div>
          <div className="testimonial-card-position">
            CTE & Business Teacher
          </div>
          <div className="testimonial-card-location">
            New Utrecht High School<br/><br/>
            <span style={{ fontStyle: 'italic' }}>
              New York City, NY
            </span>
          </div>
        </div>
        <div className="testimonials-card">
          <div className="testimonials-card-header">
            <img loading="lazy" alt='rapunzl personal finance course testimonial teacher thumbnail' className="testimonial-image" src="/images/Educators/ChristineWard.webp" />
          </div>
          <div className="testimonial-card-text">
            Rapunzl was an amazing resource when teaching my personal finance unit.
            <br/><br/>
            My learners loved the interactive stock market simulation and still check their portfolios a month after concluding the unit. The activities regarding the basics of investing were incredily helpful to get students engaged.
          </div>
          <div className="testimonial-card-name">
            Christine Ward
          </div>
          <div className="testimonial-card-position">
            Social Sciences & Civics Teacher
          </div>
          <div className="testimonial-card-location">
            Northern Cass High School<br/><br/>
            <span style={{ fontStyle: 'italic' }}>
            Fargo, ND
            </span>
          </div>
        </div>
        <div className="testimonials-card">
          <div className="testimonials-card-header">
            <img loading="lazy" alt='testimonial from rapunzl personal finance teacher thumbnail' className="testimonial-image" src="/images/Educators/BenCraig.webp" />
          </div>
          <div className="testimonial-card-text">
            Integrating Rapunzl into our curriculum has sparked renewed interest in math among my students and provide a risk-free way to teach about investing.
            <br/><br/>
            It's been really helpful to bridge the gap between theory and practical skills by showing the real-world relevance of math skills.
          </div>
          <div className="testimonial-card-name">
            Benjamin Craig
          </div>
          <div className="testimonial-card-position">
            Mathematics Teacher
          </div>
          <div className="testimonial-card-location">
            Mather High School<br/><br/>
            <span style={{ fontStyle: 'italic' }}>
              Chicago, IL
              </span>
          </div>
        </div>
      </div>
      {props.includeContact && (
        <div className="testimonials-button-flex">
          <Link to="/contact">
            <div className="testimonials-button testimonials-button-left">
              Hear How Teachers Love Rapunzl
            </div>
          </Link>
          <Link to="/demo">
            <div className="testimonials-button testimonials-button-right">
              Setup Rapunzl Educator Demo
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TeacherTestimonials;