import React from "react";
import SummerFAQ from "../components/Summer/SummerFAQ";
import BenefitsOpportunities from "../components/Summer/BenefitsOpportunities";
import SummerHeader from "../components/Summer/SummerHeader";
import WhatToExpect from "../components/Summer/WhatToExpect";
import ProgramSchedule from "../components/Summer/ProgramSchedule";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/SummerScreen.css";
import { MetaSummer2024Screen } from "../constants/metaDescriptions";

const Summer2024Screen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Financial Literacy Summer Camp</title>
        <meta name="description" content={MetaSummer2024Screen} />
        <meta property="og:title" content="Rapunzl | Financial Literacy Summer Camp" />
        <meta property="og:description" content={MetaSummer2024Screen} />
        <meta property="og:url" content="https://www.rapunzl.com/summer" />
        <link rel="canonical" href="https://www.rapunzl.com/summer" />
      </Helmet>
      <Header />
      <div className="route-subcontainer">
        <SummerHeader />
        <WhatToExpect />
        <ProgramSchedule />
        <BenefitsOpportunities />
        <SummerFAQ />
        <GoToTop />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default Summer2024Screen;