import React from 'react';
import { Link } from 'react-router-dom';

const ArticleRelatedContainer = (props) => {
  return (
    <div className='article-related-container'>
      <h2 className='related-article-subtitle'>
        Related Rapunzl Articles
      </h2>
      <div className='related-article-flex'>
        {props.relatedArticles.map((item, index) => {
          return (
            <Link to={"/article/" + item.link}>
              <div key={index} className='article-related-item'>
                <div className='article-related-item-title'>
                  {item.title}
                </div>
                <div className='article-related-item-content'>
                  {item.description}
                </div>
              </div>  
            </Link>
          )}
        )}
      </div>
    </div>
  );
};
export default ArticleRelatedContainer;