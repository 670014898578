import React from "react";

const OurImpact = () => {
  return (
    <div className="dfk-section-flex dfk-section impact">
      <div className="impact-left">
        <h2 className="impact-h1">
          Rapunzl Is Changing
          <br/>
          <span style={{ color: '#007154' }}>
          The Future Of Finance
          </span>
        </h2>
        <div className="impact-p" style={{ paddingBottom: "18px" }}>
          High school students, college students & educators across the country already rely upon Dreams For Kids for scholarship competitions and financial education curriculum.
        </div>
        <div className="impact-p">
          Join us to help provide scholarship prizes & free teacher training as we continue providing financial literacy programs to communities of color.
        </div>
        <div className="impact-asterix">
          * 81% of participating students identify as Black or LatinX & 83% of participating schools are located in low-to-middle income communities.
        </div>
      </div>
      <div className="impact-right">
        <div className="impact-right-title">
          OUR IMPACT
        </div>
        <div className="impact-flex">
          <div className="impact-item">
            <div className="impact-item-h1">
              100k+
            </div>
            <div className="impact-item-p">
              Students*
            </div>
          </div>
          <div className="impact-item">
            <div className="impact-item-h1">
              500+
            </div>
            <div className="impact-item-p">
              Schools
            </div>
          </div>
          <div className="impact-item">
            <div className="impact-item-h1">
              $312k
            </div>
            <div className="impact-item-p">
              Scholarships
            </div>
          </div>
          <div className="impact-item">
            <div className="impact-item-h1">
              370+
            </div>
            <div className="impact-item-p">
              Competitions
            </div>
          </div>
          <div className="impact-item">
            <div className="impact-item-h1">
              385+
            </div>
            <div className="impact-item-p">
              Student<br />Shareholders
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurImpact;