import React from "react";

const SpendingStandard = () => {
  return (
    <div className="educator-section reverse-educator-section national-standard-container" style={{ margin: 'auto' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="30-day-trial to access Rapunzl curriculum for free" src="/images/Standards/Spending.webp"/>
      <div className="standard-margin-shift-1">
        <h2 className="national-standard-subtext">
          Financial Literacy Strand 3:
          <br/>
          <span className="national-standard-bold">Spending</span>
        </h2>
        <div className="educator-text" style={{ width: '100%' }}>
          This financial literacy standard educates students about how to make informed and conscious decisions with their money. 
          <br/><br/>
          Students understand needs versus wants, budgeting, and the impact of spending choices on their financial health. 
          <br/><br/>
          By mastering these concepts, students learn to prioritize their expenditures, avoid impulsive purchases, and manage their resources efficiently.
        </div>  
      </div>
    </div>
  );
};

export default SpendingStandard;