import React from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/HomeScreen.css";

const HomeSchool = () => {
  return (
    <div className="home-school-banner-container">
      <img loading="lazy" alt="home cartoon illustrating homeschool personal finance program from Rapunzl" className="home-school-banner-image" src="/images/Home/Homeschool.webp" />
      <h2 className="testimonials-header-text" style={{ color: '#ffb100' }}>
        Homeschool with Rapunzl
      </h2>
      <div className="home-school-banner-text" style={{ paddingTop: 20, marginBottom: 35 }}>
        Rapunzl provides a comprehensive personal finance curriculum that fits easily into your homeschooling education plan. Plus, the grading interface helps you track your child’s progress and learning outcomes.
      </div>
      <Link to="/parents">
        <div className="checkout-rapunzl" style={{ margin: 'auto' }}>
          Learn About Homeschooling
        </div>
      </Link>
    </div>
  );
};

export default HomeSchool;