import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { CONTACT_RAPUNZL } from '../graphql/mutations/ContactRapunzl';
import { GRAPHQL_URL } from "../constants";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/ContactScreen.css";
import { MetaContactScreen } from '../constants/metaDescriptions';

const ContactScreen = () => {
  // Allows us to track the name, email, phone number, message, loading, and error/success stage
  const [nameUsernameInput, setNameUsername] = useState('');
  const [emailInput, setEmail] = useState('');
  const [phoneInput, setPhone] = useState('');
  const [messageInput, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('drafting');

  // Updates Different State Values
  function changeNameUsername(value) {
    setNameUsername(value);
  }
  function changeEmail(value) {
    setEmail(value);
  }
  function changePhone(value) {
    setPhone(value);
  }   
  function changeMessage(value) {
    setMessage(value)
  }

  // Validates Email Address - TODO
  function validateEmail() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (!re.test(this.state.email)) {
    //   this.setState({
    //     alertVisible: true,
    //     alertTitle: 'Email Invalid',
    //     alertMessage: 'You probably made a typo while inputting your email address. Give it another try!',
    //     onErrorEmail: true,
    //     loading: false,
    //   });
    // }
  }

  function contactRapunzl() {
    setLoading(true);
    axios.post(GRAPHQL_URL, { query: CONTACT_RAPUNZL(nameUsernameInput, emailInput, phoneInput, messageInput) }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((json) => {
        if ('errors' in json.data) {
          setLoading(false);
          setStatus('error');
        } else {
          setLoading(false);
          setStatus('success');
        }
      })
    .catch(error => {
      setLoading(false);
      setStatus('error');
    });
  }

  return (
    <div>
      <Helmet>
        <title>Rapunzl | Get In Touch With Us</title>
        <meta name="description" content={MetaContactScreen} />
        <meta property="og:title" content="Rapunzl | Get In Touch With Us" />
        <meta property="og:description" content={MetaContactScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/contact" />
        <link rel="canonical" href="https://www.rapunzl.com/contact" />
      </Helmet>
      <Header />
      <div className="contact-flex">
        <div className="contact-left">
          <h1 className="contact-h1">
            {status === 'drafting' ? 'Contact Rapunzl' : status === 'success' ? `Message Sent To Rapunzl!` : 'Error Sending Message To Rapunzl'}
          </h1>
          {status === 'drafting' && (
            <div className="contact-p">
              Fill out the form below and connect with someone from our team shortly! Please note that for technology & prize support issues, you must contact Rapunzl support through our mobile or web platform and may not receive a response through this form.
            </div>
          )}
          {status === 'success' && (
            <div>
              <div className="contact-p">
                Your message has been delivered to Rapunzl's inboxes. A Rapunzl team member will be in touch over the next couple days.
              </div>
              <Link to="/">
                <div className='contact-back-to-home'>
                  Back To Rapunzl Home
                </div> 
              </Link>
            </div>
          )}
          {status === 'error' && (
            <div className="contact-p">
              We had an issue sending your message. Please refresh the browser and try again, or email us directly.
            </div>
          )}
          {loading && (
            <div>
              <CircularProgress />
              <div className='contact-loading-text'>
                Loading...
              </div>
            </div>
          )}
          {status === 'drafting' && !loading && (
            <div className='centering-contact-inputs-mobile'>
              <div className='contact-form-subtitle'>
                Name/Username
              </div>
              <input
                onChange={(event) => changeNameUsername(event.target.value)}
                type="text"
                id="Name"
                name="Name"
                className="contact-inputs"
                required
              ></input>
              <div className='contact-form-subtitle'>
                Email Address
              </div>
              <input
                onChange={(event) => changeEmail(event.target.value)}
                type="email"
                id="Email"
                name="Email"
                className="contact-inputs"
                required
              ></input>
              <div className='contact-form-subtitle'>
                Phone
              </div>
              <input 
                onChange={(event) => changePhone(event.target.value)}
                type="tel"
                id="Phone"
                name="Phone"
                className="contact-inputs"
              ></input>
              <div className='contact-form-subtitle'>
                Message
              </div>
              <textarea
                onChange={(event) => changeMessage(event.target.value)}
                type="text"
                id="Message"
                name="Message"
                style={{height: "150px"}}
                className="contact-inputs"
                required
              ></textarea>
              <div className="contact-inputs contact-send-message">
                <button
                  onClick={() => contactRapunzl()}
                  type="submit"
                  id="fcf-button"
                  className="submit-contact-details"
                >
                  Send Message
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="contact-right">
          <div className="center-column-flex">
            <div className="column-item">
              <img className="column-contact-img" src="/images/Contact/Jobs.webp" alt="Rapunzl Financial Literacy Company Jobs" />
              <div>
                <div className="contact-h4">Interested In Careers?</div>
                <div className="contact-p1">
                  Email us at: <div className="contact-link">careers@rapunzl.com</div>
                </div>
              </div>
            </div>
            <div className="column-item">
              <img className="column-contact-img" src="/images/Contact/Press.webp" alt="Rapunzl Financial Literacy Company Press Inquiries" />
              <div>
                <div className="contact-h4">Press Inquires</div>
                <div className="contact-p1">
                  Email us at: <div className="contact-link">hello@rapunzl.com</div>
                </div>
              </div>
            </div>
            <div className="column-item">
              <img className="column-contact-img" src="/images/Contact/Office.webp" alt="Contact Rapunzl - Financial Literacy Company " />
              <div>
                <div className="contact-h4">Mailng Address</div>
                <div className="contact-p1">
                  323 West Schiller St., #1W
                  <br/>
                  Chicago IL, 60610
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default ContactScreen;