import React from "react";

const InvestingStandard = () => {
  return (
    <div className="educator-section national-standard-container" style={{ marginTop: 0 }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="personal finance curriculum and resources for educators and parents" src="/images/Standards/Investing.webp"/>
      <div className="standard-margin-shift-1">
        <h2 className="national-standard-subtext">
          Financial Literacy Strand 6:
          <br/>
          <span className="national-standard-bold">Investing</span>
        </h2>
        <div className="educator-text" style={{ width: '100%' }}>
          This financial literacy standard introduces students to the concept of building generational wealth through stocks, bonds, ETFs, mutual funds, and real estate. 
          <br/><br/>
          It’s important to emphasize the importance of investing early, understanding risk versus return, and how to leverage the power of compound interest. 
          <br/><br/>
          This knowledge encourages proactive financial planning, contributes to students’ financial growth and security, and helps students build a strong foundation for wealth accumulation.
        </div>
      </div>
    </div>
  );
};

export default InvestingStandard;