import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { CONTACT_RAPUNZL } from '../graphql/mutations/ContactRapunzl';
import { GRAPHQL_URL } from "../constants";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaRequestStandards } from '../constants/metaDescriptions';
import "../assets/styles/StandardRequestScreen.css";
import SelectState from '../components/StandardsRequest/SelectState';
import SetContactInfo from '../components/StandardsRequest/SetContactInfo';
import SetPositionInfo from '../components/StandardsRequest/SetPositionInfo';
import RequestResult from '../components/StandardsRequest/RequestResult';

const StandardsRequestScreen = () => { 
  // General
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('state');

  // Phase 1 Requirement
  const [selectedState, setSelectedState] = useState('');
  const handleSelectState = (value) => {
    setSelectedState(value);
    setStatus('position');
  }

  // Phase 2 Learning More
  const [schoolName, setSchoolName] = useState('');
  const [curriculumTiming, setCurriculumTiming] = useState('');
  const [selectedPurchasingPosition, setPurchasingPosition] = useState('');
  const [studentEstimate, setStudentEstimate] = useState('');
  const [curriculumLocation, setCurriculumLocation] = useState('');
  const [titleOne, setTitleOne] = useState('');
  const handleLearningMore = (data) => {
    setSchoolName(data.schoolName);
    setCurriculumTiming(data.implementationTimeline);
    setPurchasingPosition(data.roleInPurchasing);
    setStudentEstimate(data.numberOfStudents);
    setCurriculumLocation(data.curriculumUse);
    setTitleOne(data.titleOneProgress);
    setStatus('contact');
  }

  // Phase 3 State Requirements
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailInput, setEmail] = useState('');
  const [phoneInput, setPhone] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const handleContactInfo = (data) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.Email);
    setPhone(data.Phone.replace(/[^0-9]/g, ''));
    setJobTitle(data.Position);
    contactRapunzl(data.firstName, data.lastName, data.Email, data.Phone, data.Position);
  }

  function calculateValuePriority(position) {
    let totalScore = 0;
    let priority = 0;
    // Initial Position Is The Most Valuable Attribute & Will Be Multipled By Others
    if (position === 'Superintendent / Asst Super') {
      totalScore += 10;
      priority += 5;
    } else if (position === 'Curriculum Director' || position === 'Chief Academic Officer') {
      totalScore += 8;
      priority += 4;
    } else if (position === 'Other District Staff') {
      totalScore += 6;
      priority += 3;
    } else if (position === 'Principal / Asst Principal') {
      totalScore += 6;
      priority += 5;
    } else if (position === 'Teacher') {
      totalScore += 1;
      priority += 1;
    } else if (position === 'Parent') {
      totalScore += 5;
      priority += 1;
    }
    // Handles Priority States
    const priorityStates = [
      "Alabama",
      "Iowa",
      "Mississippi",
      "Missouri",
      "Tennessee",
      "Utah",
      "Virginia",
      "Florida",
      "Georgia",
      "Kansas",
      "Michigan",
      "Minnesota",
      "North Carolina",
      "Ohio",
      "Rhode Island",
      "South Carolina",
      "Connecticut",
      "Nebraska",
      "New Mexico",
      "California"
    ];
    if (priorityStates.includes(selectedState)) {
      totalScore = totalScore * 2;
      priority = priority * 4;
    }
    if (studentEstimate === "250+") {
      totalScore = totalScore + 8;
      priority = priority * 2;
    }
    if (selectedPurchasingPosition === "Final Decision Maker") {
      totalScore = totalScore + 30;
      priority = priority * 5;
    }
    if (curriculumTiming === "Less than a month" || curriculumTiming === "1-3 months") {
      priority = priority * 3;
    }
    if (priority + totalScore > 90) {
      return 'High Priority Contact';
    } else if (priority + totalScore > 40) {
      return 'Medium Priority Contact';
    } else {
      return 'Low Priority Contact';
    }
  }

  function contactRapunzl(first, last, email, phone, position) {
    setLoading(true);
    let name = first + ' ' + last;
    let message = `
      (STANDARDS REQUEST) ${name} from ${selectedState.name} (${selectedState.code}), recently submitted a form on the Rapunzl website requesting Standards Alignment for ${selectedState.name}.

      ${'  '}The contact is associated with ${schoolName} (${curriculumLocation}), listed their job title as: ${position}. In terms of their role in purchasing decions, they listed the answer: ${selectedPurchasingPosition}.${'  '}
      
      The contact who submitted the form said they are interested in implementing the Rapunzl program in: ${curriculumTiming}.${'  '}
      
      Contact believes that this program will reach an estimated student body of ${studentEstimate}. Based on the information provided, this contact has been determined to be a ${calculateValuePriority(position)}.
      
      If this does not seem to be correct, the priority calculator is a work in progress. Please flag any suspiciously high or low priority contacts to Brian.
    `;

    // TODO - CHRIS
    // It would be great to check if we could see if the email address domain provided matches any schools
    // since that would indicate other teachers/schools in the district have already used it.
    // That's a low priority though. The higher priority is could we create a Hubspot Record and a Task using the information we collect?

    axios.post(GRAPHQL_URL, { query: CONTACT_RAPUNZL(name, email, phone, message) }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((json) => {
        if ('errors' in json.data) {
          setLoading(false);
          setStatus('error');
        } else {
          setLoading(false);
          setStatus('success');
        }
      })
    .catch(error => {
      setLoading(false);
      setStatus('error');
    });
  }

  const handleBack = () => {
    if (status === 'position') {
      setStatus('state');
    } else if (status === 'contact') {
      setStatus('position');
    }
  }

  return (
    <div className='dark-gradient-route'>
      <Helmet>
        <title>Rapunzl | Request Standards Alignment</title>
        <meta name="description" content={MetaRequestStandards} />
        <meta property="og:title" content="Rapunzl | Request Standards Alignment" />
        <meta property="og:description" content={MetaRequestStandards} />
        <meta property="og:url" content="https://www.rapunzl.com/requestStandards" />
        <link rel="canonical" href="https://www.rapunzl.com/requestStandards" />
      </Helmet>
      <Header />
      <img
        alt="money map of states with financial literacy"
        className='standards-header-image'
        src="/images/Standards/MoneyMap.webp"
        style={{
          maxWidth: status === 'state' ? '370px' : '240px',
          paddingTop: status === 'state' ? '115px' : (status === 'error' || status === 'success') ? '185px' : '100px',
          padddingBottom: status === 'state' ? '0px' : '25px',
        }}
      />
      {status === 'state' && loading !== true && (
        <h1 className='standards-request-title'>
          Align Rapunzl's Personal Finance Course<br/>With Your State Standards
        </h1>
      )}
      {status === 'state' && loading !== true && (
        <SelectState handleSelectState={handleSelectState} />
      )}
      {status === 'position' && loading !== true && (
        <SetPositionInfo
          handleLearningMore={handleLearningMore}
          handleBack={handleBack}  
        />
      )}
      {status === 'contact' && loading !== true && (
        <SetContactInfo
          handleContactInfo={handleContactInfo}
          handleBack={handleBack}  
        />
      )}
      {loading === true && (
        <div className='standards-request-container'>
          <div className='standards-request-progress-bar'>
            <div className='standards-request-progress-bar-inner' />
            <div className='standards-request-progress-bar-inner' />
            <div className='standards-request-progress-bar-inner' />
            <div className='standards-request-progress-bar-inner' />
            <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
          </div>
          <div className='standards-request-loading-container'>
            <CircularProgress className='loading-icon' />
            <div className='standards-request-loading-text'>
              Processing Request...
            </div>
          </div>
        </div>
      )}
      {status === 'success' && loading !== true && (
        <RequestResult
          firstName={firstName}
          school={schoolName}
          state={selectedState.name}
          email={emailInput}
          status='success'
        />
      )}
      {status === 'error' && loading !== true && (
        <RequestResult status="error" />
      )}
      <GoToTop />
      <Footer />
    </div>
  );
};
export default StandardsRequestScreen;