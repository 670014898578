import React, { useState } from 'react';
import UnderstandingStandards from './UnderstandingStandards';

const SelectState = (props) => {
  const listOfStates = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" }
  ];

  const [navigateToDemoIntake, setNavigate] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [isError, setError] = useState(false);

  const handleSelectState = (event) => {
    setError(false);
    const value = event.target.value;
    if (value === "International") {
      setSelectedState({ name: "International", code: "" });
    } else {
      const state = listOfStates.find((state) => state.code === value);
      setSelectedState(state);
    }
  };

  const _handleNext = () => {
    if (selectedState === null) {
      setError(true);
    } else {
      // Handles Updating State Which Is Selected With Pass Through Function
      props.handleSelectState(selectedState);
    }    
  }

  const _handleNextDemo = () => {
    if (selectedState === null) {
      setError(true);
    } else if (selectedState.code !== undefined) {
      window.location.href = '/demoRequest/' + selectedState.name;
    } else {
      window.location.href ='/demoRequest/general';
    }
  }

  if (props.demo) {
    return (
      <div className='standards-request-container dark-gradient-route'>
        <div className='standards-demo-flex'>
          <div className='standards-demo-subcontainer'>
            <h2 className='request-demo-h2'>
              Get Your Free Rapunzl Trial Today
            </h2>
            <div className='request-p standards-demo-p'>
              <span style={{ fontWeight: '600', color: '#34ffb5' }}>
              Explore Rapunzl's personal finance curriculum & real-time simulator for 30 days!
              </span>
              <br/><br/>
              Before we send you an all-access demo, can you you let us know a litle more information to help us share courses aligned to your state standards and classroom goals.
            </div>
            <div className='request-demo-flex-buttons' >
              <select className='request-dropdown' onChange={handleSelectState}>
                <option value="">--  Choose Your State  --</option>
                  {listOfStates.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                <option value="International">International</option>
              </select>
              <div onClick={() => _handleNextDemo()} className={`request-next-button ${selectedState ? 'request-enabled' : 'request-disabled'}`}>
                Go
              </div>
            </div>
            <div className='request-demo-error-message'>
              {isError ? 'You must select a state in order to continue.' : ''}
            </div>
            <a href="https://www.rapunzl.app">
              <div className='access-simulator-button'>
                Looking For The<br/>Rapunzl Simulator?
              </div>
            </a>
          </div>
          <img
            alt="money map of states with financial literacy"
            className='demo-standards-header-image'
            src="/images/Standards/MoneyMap.webp"
          />
        </div>
        
      </div>
    )
  } else {
    return (
      <div className='standards-request-container' style={{ paddingBottom: 0 }}>
        <div className='standards-request-progress-bar'>
          <div className='standards-request-progress-bar-inner' />
          <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
          <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
          <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
          <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
        </div>
        <div>
        <h2 className='request-h2' style={{ fontSize: 36 }}>
          Choose Your State
        </h2>
        <div className='request-p'>
        Financial literacy standards vary significantly from state to state, with some states requiring a half-semester course and others mandating a full semester.
        <br/><br/>
        The placement of financial literacy in the curriculum also differs, as it can be part of the Social Studies or Math departments, or integrated across Economics, Business, and Career and Technical Education (CTE) programs.
        </div>
        </div>
        <div className='request-flex-buttons'>
          <select className='request-dropdown' onChange={handleSelectState}>
            <option value="">--  Choose Your State  --</option>
              {listOfStates.map((state) => (
                <option key={state.code} value={state.code}>
                  {state.name}
                </option>
              ))}
            <option value="International">International</option>
          </select>
          <div onClick={() => _handleNext()} className={`request-next-button ${selectedState ? 'request-enabled' : 'request-disabled'}`}>
            Go
          </div>
        </div>
        <div className='request-error-message'>
          {isError ? 'You must select a state in order to continue.' : ''}
        </div>
        <UnderstandingStandards />
      </div>
    )
  }
};
export default SelectState;