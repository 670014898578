import React from "react";
import "../../assets/styles/HomeScreen.css";
import { Link } from "react-router-dom";

const RealWorldLearning = () => {
  return (
    <div className="section imagine-section">
      <div className='flex-courses-dot-header' style={{ paddingTop: 0 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
      <div className="imagine-section-flex">
        <img loading="lazy" src="/images/Home/MoneyGlobe.webp" className="phone-two"  alt="rapunzl mobile app phone screenshot iOS" />
        <div className="left left-opposite">
          <h2 className="user-line-1" id="home-climb-text">
            <span className="bolder">Real-World Learning </span>& Standards-Aligned Curriculum  
          </h2>
          <div className="educator-text" style={{ marginBottom: 40 }}>
            Rapunzl's curriculum combines<Link className="article-link" to="/article/leveragingTheRapunzlSimulator"> real-world financial experiences</Link> with state and national standards-aligned lessons to ensure students are well-prepared for their financial futures.
            <br/><br/>
            Check out how Rapunzl’s <Link className="article-link" to="/article/valueOfProjectBasedLearning">project-based,</Link> financial literacy courses and supplementary modules aligns with national standards set by the Council for Economic Education and Jump$tart, as well as all state-specific requirements.
          </div>
          <Link to="/requestStandards">
            <div className="intro-sign-up-button">
              How We Align With State Standards
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RealWorldLearning;