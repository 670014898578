import React from "react";

const CryptoFeatures = () => {
  return (
    <div className="s6">
      <img loading="lazy" className="s6-image" id="benefitsImage" alt="Rapunzl simulated investing platform provides Real Time Data" src="/images/Crypto/RealTimeData.webp" />
      <h2 className="s6-header">
        What Sets Rapunzl's Crypto Simulator Apart
      </h2>
      <div className="s6-flex">
        <div className="s6-benefit">
          <img loading="lazy" className="s6-benefit-image" id="lightningBolt" alt="Rapunzl simulated investing platform provides Lightning Fast Data" src="/images/Crypto/LightningBolt.webp" />
          <h4 className="s6-benefit-h1">
            Ridiculously Fast Data
          </h4>
          <div className="s6-benefit-p">
            Real-time data streaming from live crypto exchanges provides Rapunzl with incredibly fast and accurate pricing data.
          </div>
        </div>
        <div className="s6-benefit">
          <img loading="lazy" className="s6-benefit-image" id="fortuneWheel" alt="Rapunzl simulated investing platform provides opportunities to win" src="/images/Crypto/FortuneWheel.webp" />
          <h4 className="s6-benefit-h1">
            Weekly Crypto Contests
          </h4>
          <div className="s6-benefit-p">
            Competitions each week allow you to win prizes picking cryptos and following their price movement each week, non-stop. 
          </div>
        </div>
        <div className="s6-benefit">
          <img loading="lazy" className="s6-benefit-image" id="tradeTools" alt="Rapunzl simulated investing platform provides access to trading tools" src="/images/Crypto/TradeTools.webp" />
          <h4 className="s6-benefit-h1">
            Expert Trade Tools
          </h4>
          <div className="s6-benefit-p">
            Set limit, stop, stop-loss and take profit orders to manage your portfolio’s risk without constantly checking crypto markets.
          </div>
        </div>
        <div className="s6-benefit">
          <img loading="lazy" className="s6-benefit-image" id="gradCoin" alt="Rapunzl simulated investing platform provides access to Crypto Learning Resources." src="/images/Crypto/GradCoin.webp" />
          <h4 className="s6-benefit-h1">
            Crypto Learning Portal
          </h4>
          <div className="s6-benefit-p">
            Access leading industry education tools to better understand crypto prices and what’s driving market movements.
          </div>
        </div>
        <div className="s6-benefit">
          <img loading="lazy" className="s6-benefit-image" id="weeklyCompetitions" alt="Rapunzl simulated investing platform provides access to weekly competitions." src="/images/Crypto/WeeklyCompetitions.webp" />
          <h4 className="s6-benefit-h1">
            Monthly Competitions
          </h4>
          <div className="s6-benefit-p">
            Longer-term trading challenges provide another opportunity to earn prizes while refining your crypto trading strategies.
          </div>
        </div>
        <div className="s6-benefit">
          <img loading="lazy" className="s6-benefit-image" id="cryptoSupport" alt="Rapunzl simulated investing platform provides access & support." src="/images/Crypto/CryptoSupport.webp" />
          <h4 className="s6-benefit-h1">
            Access & Support 
          </h4>
          <div className="s6-benefit-p">
           24/7 email support and a dedicated team of support staff to help resolve any issues you encounter.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoFeatures;