import React from "react";
import { Link } from "react-router-dom";

const GetInTouch = (props) => {
  return (
    <div className="get-in-touch-container" style={{ backgroundColor: props.darkMode ? '#1c2624' : '#8ba39d' }}>
      <img loading="lazy" alt="email sending from phone to Rapunzl support" className="get-in-touch-image" src="/images/Admin/ContactUs.webp" />
      <h2 className="educator-header get-in-touch-subcontainer" style={{ color: props.darkMode ? '#ffbe00' : '#00392a' }}>
        Get In Touch With Rapunzl
      </h2>
      <div className="educator-text get-in-touch-subcontainer" style={{ color: props.darkMode ? '#f7fffb' : '#03251c' }}>
        Interested in financial literacy but not sure where to begin? Perhaps you have a specific question about Rapunzl’s curriculum or are currently exploring best-practices for implementing financial education in the classroom.
        <br /><br />
        Whatever the reason, we’d love to connect and share our insights after spending over 1,000 hours teaching financial education in high schools across the country.
        <br /><br />
        Book a call, drop us a note and let’s connect.
      </div>
      <div className="get-in-touch-flex get-in-touch-subcontainer">
        <Link to="/contact">
          <div className="educator-button-dark" style={{ marginRight: "5px" }}>
            Contact Rapunzl
          </div>
        </Link>
        <a href="https://www.link.rapunzl.com/meetings/brian-zoom/myles-brian">
          <div className="educator-button-dark" style={{ marginLeft: "5px", backgroundColor: '#007154', borderColor: '#007154', color: 'white' }}>
            Schedule A Call
          </div>
        </a>
      </div>
    </div>
  );
};

export default GetInTouch;