import React from "react";

const EarningIncomeStandard = () => {
  return (
    <div className="educator-section reverse-educator-section national-standard-container" style={{ marginBottom: 0 }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="30-day-trial to access Rapunzl curriculum for free" src="/images/Standards/EarningIncome.webp"/>
      <div className="standard-margin-shift-1">
      <h2 className="national-standard-subtext">
          Financial Literacy Strand 1:
          <br/>
          <span className="national-standard-bold">Earning Income</span>
        </h2>
        <div className="educator-text" style={{ width: '100%' }}>
          This financial literacy standard covers everything regarding income, taxes, career planning, & entrepreneurship. 
          <br/><br/>
          This core principle helps students recognize the value of work & employment, the importance of skills and education, and the potential for financial growth through diverse income streams. 
          <br/><br/>
          Knowledge regarding Earning Income empowers students to make informed career choices, pursue opportunities for advancement, understand taxes, and achieve financial independence.
        </div>  
      </div>
    </div>
  );
};

export default EarningIncomeStandard;