import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProgramSchedule = () => {
  // Track Which Question Is Visible, If Any
  const [visibleTab, setVisibleTab] = useState(false);

  // Updates Visible Tab Value - Checking If It Is The Same And Closing
  // This ensures only one tab is open at a time
  function changeVisibleTab(value) {
    if (value === visibleTab) {
      setVisibleTab(false);
    } else {
      setVisibleTab(value);
    }
  }

  return (
    <div className="program-schedule">
      <img loading="lazy" src="/images/Summer/Schedule.webp" className="schedule-img" alt="Rapunzl Personal Finance Summer Schedule" />
      <h2 className="schedule-header">
        Rapunzl Summer Camp Schedule
      </h2>
      <div className="program-schedule-text">
        We are hosting a total of 3 cohorts ranging from 30 to 35 students in the Summer of 2024 with limited availability. In order to maintain a low student-to-teacher ratio, we are offering this 2-week workshop in June, July, and August.
        <br /><br />
        Each day will begin at 10am and end at 4pm. Lunch and snacks will be provided.
      </div>
      <div className="cohort-flex">
        <div className="cohort-container">
          <div className="cohort-date">
            6/10 - 6/21
          </div>
        </div>
        <div className="cohort-container">
          <div className="cohort-date">
            7/8 - 7/19
          </div>
        </div>
        <div className="cohort-container">
          <div className="cohort-date">
            7/29 - 8/9
          </div>
        </div>
      </div>
      <div className="divider"> </div>
      <h3 className="week-header">
        Financial Literacy Camp: Week 1
      </h3>
      <div onClick={() => changeVisibleTab(1)} className="h1" id="day1" style={{ color: "#083026", borderBottom: visibleTab === 1 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 1: </div> Welcome To The Stock Market
      </div>
      <div className="p" id="description1" style={{ display: visibleTab === 1 ? 'block' : 'none' }}>
        On the first day, we start with the basics: What Is A Stock? How Do Stocks Trade? What's the purpose of the stock market? Once we establish a foundational knowledge, we scaffold student learning by following the journey of Warren Buffet, one of the greatest value investors of all time as students setup Rapunzl accounts and begin their investing journey.
      </div>
      <div onClick={() => changeVisibleTab(2)} className="h1" id="day2" style={{ color: "#083026", borderBottom: visibleTab === 2 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 2: </div> Saving Vs. Investing
      </div>
      <div className="p" id="description2" style={{ display: visibleTab === 2 ? 'block' : 'none' }}>
        We dissect the difference between investing & savings, how banks make money by taking risk, and why you can do the same by evaluating companies for their long-term growth potential in order to make your money work smarter for you than for a banker.
      </div>
      <div onClick={() => changeVisibleTab(3)} className="h1" id="day3" style={{ color: "#083026", borderBottom: visibleTab === 3 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 3: </div> Understanding Financial Fitness
      </div>
      <div className="p" id="description3" style={{ display: visibleTab === 3 ? 'block' : 'none' }}>
        This class provides important information about becoming financially fit. This involves sticking to a few key habits in order to have access to capital through credit, and allowing students to learn about how you can prepare for retirement using mutual funds, establishing consistent savings patterns, and possibly investing in insurance.
      </div>
      <div onClick={() => changeVisibleTab(4)} className="h1" id="day4" style={{ color: "#083026", borderBottom: visibleTab === 4 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 4: </div> Benefits & Risks of Debt
      </div>
      <div className="p" id="description4" style={{ display: visibleTab === 4 ? 'block' : 'none' }}>
        Governments and companies borrow capital from institutional lenders by issuing bonds or other debt instruments, but how does credit and debt impact your own financial future? Learn the risks & rewards of proper credit management.
      </div>
      <div onClick={() => changeVisibleTab(5)} className="h1" id="day5" style={{ color: "#083026", borderBottom: visibleTab === 5 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 5: </div> Power of Diversification
      </div>
      <div className="p" id="description5" style={{ display: visibleTab === 5 ? 'block' : 'none' }}>
        Diversification can reduce the risk your portfolio faces and help capture returns from the broader market. Risk is unavoidable when you are investing, but it declines over time; and that’s where the real money can be made. Ever heard the phrase Buy-And-Hold? We won't cover the benefits until Week 2, but rest assured, if you understand that principle and diversification, you're well on your way to being a successful long-term investor.
      </div>
      <h3 className="week-header" style={{ marginTop: "55px" }}>
        Financial Literacy Camp: Week 2
      </h3>
      <div onClick={() => changeVisibleTab(6)} className="h1" id="day6" style={{ color: "#083026", borderBottom: visibleTab === 6 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 6: </div> What Makes A Good Stock?
      </div>
      <div className="p" id="description6" style={{ display: visibleTab === 6 ? 'block' : 'none' }}>
        The sure way to success is to buy the market, but obviously there’s more advanced investment strategies. The simplest? Buy & Hold. We dive into complex analysis including ways to value a company, understand how a stock compares to industry peers, and what time horizon should different investors adopt to achieve their goals.
      </div>
      <div onClick={() => changeVisibleTab(7)} className="h1" id="day7" style={{ color: "#083026", borderBottom: visibleTab === 7 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 7: </div> ETFs & Mutual Funds
      </div>
      <div className="p" id="description7" style={{ display: visibleTab === 7 ? 'block' : 'none' }}>
        Learn how ETFs & Mutual Funds provide a way for investors to invest in a basket of stocks or other assets at a much lower cost than purchasing all of the assets independently. We breakdown complex acronyms and new jargon to explain that ETFs and Mutual Funds are actually incredibly useful retirement tools that provide built-in diversification to reduce overall portfolio risk.
      </div>
      <div onClick={() => changeVisibleTab(8)} className="h1" id="day8" style={{ color: "#083026", borderBottom: visibleTab === 8 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 8: </div> ESG & Social Impact Investing
      </div>
      <div className="p" id="description8" style={{ display: visibleTab === 8 ? 'block' : 'none' }}>
        This module helps students digest ESG and impact investing, explores the different types of ESG investing and show students examples of successful ESG firms. We teach social investing through stories, and look at the history of ESG to gain historical perspective on why it has gained prominence for investors and society as a whole.
      </div>
      <div onClick={() => changeVisibleTab(9)} className="h1" id="day9" style={{ color: "#083026", borderBottom: visibleTab === 9 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 9: </div> Financial Services Careers
      </div>
      <div className="p" id="description9" style={{ display: visibleTab === 9 ? 'block' : 'none' }}>
        This module helps students examine various careers in financial services and appreciate their role within the industry. We discuss potential career paths and relevant skills to thrive in interviews, internships, helping provide a foundational knowledge for future <Link className="article-link" to="/article/promotingSelfDirectedFinancialLiteracy">self-directed learning</Link>.
      </div>
      <div onClick={() => changeVisibleTab(10)} className="h1" id="day10" style={{ color: "#083026", borderBottom: visibleTab === 10 ? '0px' : '1px solid lightgrey' }}>
        <div className="day-subtext">Day 10: </div> How To Get A Job On Wall Street
      </div>
      <div className="p" id="description10" style={{ display: visibleTab === 10 ? 'block' : 'none' }}>
        In the final session of the Bootcamp, we will review best practices on preparing a resume, walk students through mock finance interviews, and discuss some of the challenges faced by people of color entering financial services careers. We <Link className="article-link" to="/article/financialLiteracyInContext">contextualize student's learning</Link> over the past 2 weeks and administer an exit exam to gauge the efficacy of the program for future cohorts.
      </div>
      <a href="https://www.forms.gle/rnkR3mmrmYVqsm379">
        <div className="apply-button schedule-apply">
          Apply To Rapunzl Summer Camp
        </div>
      </a>
    </div>
  );
};

export default ProgramSchedule;