import React from "react";
import { Link } from "react-router-dom";

const BuildCourse = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '10px', paddingBottom: '75px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="how to get started building a personal finance course" src="/images/Demo/TrafficLight.webp"/>
      <div>
        <h2 className="educator-header">
          Get Started Building A Personal Finance Course
        </h2>
        <div className="educator-text">
          With Rapunzl's Educator Dashboard, <Link className="article-link" to="/article/buildingTheRightPersonalFinanceCourse">building a personal finance course</Link> has never been easier with Rapunzl. Our platform offers step-by-step guidance, customizable sequences, and detailed teacher guides to help you feel comfortable bring Rapunzl to your classroom.
          <br /><br />
          Start today and see how Rapunzl can revolutionize your approach to teaching personal finance.
          </div>
      </div>
    </div>
  );
};

export default BuildCourse;