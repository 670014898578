import React from "react";
import { Link } from "react-router-dom";

const DataPrivacy = (props) => {
  return (
    <div className={`imagine-section ${props.darkMode ? 'imagine-section-alt' : ''}`} style={{ paddingBottom: '200px' }}>
      <div className="imagine-section-flex">
        <img loading="lazy" src="/images/Admin/DataPrivacy.webp" className="phone-two"  alt="rapunzl mobile app phone to show data privacy" />
        <div className="left left-opposite">
          <h2 className="user-line-1" id="home-climb-text" style={{ color: props.darkMode ? '#efc052' : '#064f3c'}}>
            <span className="bolder" style={{ color: props.darkMode ?  '#ffb200' : '#007154' }}>Rapunzl Cares About </span>Students’ Data Privacy  
          </h2>
          <div className="educator-text" style={{ color: props.darkMode ? 'white' : 'black' }}>
            Rapunzl complies fully with restrictions set by COPPA and FERPA—we don't store personally identifiable information such as a student’s age, location, gender, or ethnicity.
            <br /><br />
            Under FERPA, parents and eligible students (aged 18 and over) shall be able to access the student’s records upon request to Rapunzl until records are deleted at the end of the school year.
          </div>
          <Link to="/legal/privacyPolicy">
            <div className="checkout-rapunzl">
              View Rapunzl Data Policy
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DataPrivacy;