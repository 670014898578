import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { CONTACT_RAPUNZL } from '../../graphql/mutations/ContactRapunzl';
import { GRAPHQL_URL } from "../../constants";

const DemoInputContainer = () => {
  // Allows us to track the name, email, phone number, message, loading, and error/success stage
  const [firstNameInput, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameInput, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [emailInput, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [phoneInput, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [titleInput, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [schoolInput, setSchool] = useState('');
  const [schoolError, setSchoolError] = useState(false);

  const [passwordInput, setPassword] = useState('');
  const [confirmInput, setConfirm] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('drafting');

  function validateInputs() {
    if (!validateFirstName()) {
      setFirstNameError(true);
      setErrorMessage('Please enter a valid first name that includes at least one character.');
    } else if (!validateLastName()) {
      setLastNameError(true);
      setErrorMessage('Please enter a valid last name that includes at least one character.');
    } else if (!validatePosition()) {
      setTitleError(true);
      setErrorMessage('Please include your position or title at your school, district, or organization.');
    } else if (!validateEmail()) {
      setEmailError(true);
      setErrorMessage('Please enter a valid email address so that we are able to get in touch with your account details.');
    } else if (!validatePhone()) {
      setPhoneError(true);
      setErrorMessage('Please enter a valid, 9 digit phone number in case we need to contact you about your account.');
    } else if (!validateSchool()) {
      setSchoolError(true);
      setErrorMessage('Please enter the name of your school, district, or organization to properly register your account.');
    } else {
      requestDemo();
      setErrorMessage('');
    }
  }

  // Handles First Name State Updates
  function changeFirstName(value) {
    let updatedFirst = value.replace(/\s/g, '').replace(/[^A-Za-z]+-+'/g, '');
    setFirstName(updatedFirst);
    setFirstNameError(false);
    setErrorMessage('');
  }
  function validateFirstName() {
    if (firstNameInput.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  // Handles Last Name State Updates
  function changeLastName(value) {
    let updatedLast = value.replace(/\s/g, '').replace(/[^A-Za-z]+-+'/g, '')
    setLastName(updatedLast);
    setLastNameError(false);
    setErrorMessage('');
  }
  function validateLastName() {
    if (lastNameInput.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  // Handles Position / Title State Updates
  function changeTitle(value) {
    setTitle(value);
    setTitleError(false);
    setErrorMessage('');
  }
  function validatePosition() {
    if (titleInput.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  // Handles Email State Updates
  function changeEmail(value) {
    let updatedEmail = value.replace(/\s/g, '');
    setEmail(updatedEmail);
    setEmailError(false);
    setErrorMessage('');
  }
  function validateEmail() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailInput.length < 1) {
      return false;
    } else if (!re.test(emailInput)){
      return false;
    } else {
      return true;
    }
  }

  // Handles Phone State Updates
  function changePhone(value) {
    let updatedPhone = value.replace(/\s/g, '');
    setPhone(updatedPhone);
    setPhoneError(false);
    setErrorMessage('');
  } 

  function validatePhone() {
    if (phoneInput.length < 9) {
      return false;
    } else {
      return true;
    }
  }

  // Handles School State Updates
  function changeSchool(value) {
    let updatedSchool = value.replace(/\s/g, '');
    setSchool(updatedSchool);
    setSchoolError(false);
    setErrorMessage('');
  }   
  function validateSchool() {
    if (schoolInput.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  // Handle Username State Updates
  // TODO: Add Username Validation From Web - CreateAccountContainer
  function changeNameUsername(value) {
    setNameUsername(value);
    setUsernameError(false);
    setErrorMessage('');
  }
  function validateUsername() {
    if (nameUsernameInput.length < 1) {
      setUsernameError(true);
    } else {
      setUsernameError(false);
    }
  }

  // Handle Password & Confirm Validation
  function changePassword(value) {
    setPassword(value);
    setPasswordError(false);
    setErrorMessage('');
  }
  function changeConfirm(value) {
    setConfirm(value);
    setPasswordError(false);
    setErrorMessage('');
  }
  function validatePassword() {
    if (passwordInput.length < 8) {
      setPasswordError(true);
    }
    else if (passwordInput !== confirmInput) { 
      setPasswordError(true);
    }
    else {
      setPasswordError(false)
    }
  }

  function requestDemo() {
    setLoading(true);
    let name = firstNameInput + ' ' + lastNameInput;
    let message = 'DEMO REQUEST FROM WEBSITE: ' + titleInput + ', ' + schoolInput;
    const infoMutation =  CONTACT_RAPUNZL(name, emailInput, phoneInput, message);
    console.log(infoMutation);
    axios.post(GRAPHQL_URL, { query: infoMutation }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((json) => {
        if ('errors' in json.data) {
          setLoading(false);
          setStatus('error');
        } else {
          setLoading(false);
          setStatus('success');
        }
      })
    .catch(error => {
      console.log(error.response);
      setLoading(false);
      setStatus('error');
    });
  }

  function getTitleText() {
    if (status === 'drafting') {
      return 'Request A Free Rapunzl Educator Demo Account';
    } else if (status === 'success') {
      return `You're All Set!`;
    } else {
      return 'Something Went Wrong...';
    }
  }

  function getSubtitleText() {
    if (status === 'drafting') {
      return `Explore all of Rapunzl's personal finance curriculum and real-time investment simulator free for 30 days.`;
    } else if (status === 'success') {
      return `A Rapunzl team member will be in touch shortly to help share demo access to Rapunzl's Educator Portal and materials to get started!`
    } else {
      return `We had an issue sending your request for a free educator account demo. Please try again or email us directly at hello@rapunzl.com.`
    }
  }

  return (
    <div>
      <img alt='digital banking money on rapunzl phone investing app' className='demo-header-image' src="/images/Educators/Transforming.webp" style={{ paddingTop: status === 'drafting' ? '80px' : '200px' }} />
      <h1 className='demo-title'>
        {getTitleText()}
      </h1>
      <div className='demo-subtitle' style={{ paddingBottom: status === 'drafting' ? '35px' : '300px' }}>
        {getSubtitleText()}
      </div>
      <div className='demo-input-container' style={{ display: status === 'drafting' ? 'block' : 'none' }}>
        <div className='demo-input-flex'>
          <div className='demo-input-flex-item1'>
            <div className='demo-input-title'>
              First Name
            </div>
            <input
              onChange={(event) => changeFirstName(event.target.value)}
              type="text"
              id="Name"
              name="Name"
              className={`demo-input flex-input ${firstNameError ? 'demo-input-error' : ''}`}
              required
            ></input>
          </div>
          <div className='demo-input-flex-item2'>
            <div className='demo-input-title'>
              Last Name
            </div>
            <input
              onChange={(event) => changeLastName(event.target.value)}
              type="text"
              id="Name"
              name="Name"
              className={`demo-input flex-input ${lastNameError ? 'demo-input-error' : ''}`}
              required
            ></input>
          </div>
        </div>
        {/* <div className='demo-input-title'>
          Username
        </div>
        <input
          onChange={(event) => changeNameUsername(event.target.value)}
          type="text"
          id="Name"
          name="Name"
          className="demo-input"
          required
        ></input> */}
        <div className='demo-input-title'>
          Position / Title
        </div>
        <input
          onChange={(event) => changeTitle(event.target.value)}
          type="text"
          id="Position"
          name="Position"
          className={`demo-input ${titleError ? 'demo-input-error' : ''}`}
          required
        ></input>
        <div className='demo-input-title'>
          Email Address
        </div>
        <input
          onChange={(event) => changeEmail(event.target.value)}
          type="email"
          id="Email"
          name="Email"
          className={`demo-input ${emailError ? 'demo-input-error' : ''}`}
          required
        ></input>
        <div className='demo-input-title'>
          Phone
        </div>
        <input 
          onChange={(event) => changePhone(event.target.value)}
          type="tel"
          id="Phone"
          name="Phone"
          className={`demo-input ${phoneError ? 'demo-input-error' : ''}`}
        ></input>
        <div className='demo-input-title'>
          School Name
        </div>
        <input 
          onChange={(event) => changeSchool(event.target.value)}
          type="tel"
          id="School"
          name="School"
          className={`demo-input ${schoolError ? 'demo-input-error' : ''}`}
        ></input>
        {/* <div className='demo-input-title'>
          Password
        </div>
        <input
          onChange={(event) => changePassword(event.target.value)}
          type="text"
          id="Password"
          name="Passwrod"
          className="demo-input"
          required
        ></input>
        <div className='demo-input-title'>
          Confirm Password
        </div>
        <input
          onChange={(event) => changeConfirm(event.target.value)}
          type="text"
          id="Confirm"
          name="Confirm"
          className="demo-input"
          required
        ></input> */}
        <button
          onClick={() => validateInputs()}
          type="submit"
          id="fcf-button"
          className="submit-demo-button"
        >
          Request A Rapunzl Demo
        </button>
        {errorMessage.length > 0 && (
        <div className='demo-error-message'>
          {errorMessage}
        </div>)}
      </div>
      <div className='flex-courses-dot-header' style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
    </div>
  );
};
export default DemoInputContainer;