import React from "react";
import "../../assets/styles/HomeScreen.css";

const PresentationGuides = (props) => {
  return (
    <div className="educator-section step-by-step-courses" style={{ paddingTop: 60, paddingBottom: props.isParents ? '20px' : '100px' }}>
      <img loading="lazy" src="/images/Educators/PresentationGuides.webp" className="phone-two"  alt="illustration of Rapunzl presentation guides and investing curriculum" />
      <div>
        <h2 className={props.isParents ? 'educator-header' : "user-line-1"} id="home-climb-text">
        {props.isParents ? '' : 'Detailed'}<span className="bolder"> Step-By-Step </span>Presentation Guides {props.isParents ? 'For Parents' : ''}
        </h2>
        <div className="educator-text">
        Rapunzl offers detailed step-by-step {props.isParent ? 'parent' : 'teacher'} guides that include engagement prompts and leading questions, making it easier for anyone to facilitate dynamic and interactive lessons. 
          <br/><br/>
          These comprehensive personal finance and investing resources ensure {props.isParent ? 'parents' : 'teachers'} can effectively guide {props.isParents ? 'your kids' : 'students'} through complex financial concepts while encouraging critical thinking and active participation.
          <br/><br/>
          Our investment simulator helps provide the engagement “hook” and then our detailed implementation resources ensure that we transform student engagement into real learning outcomes.
        </div>
        <div className="get-in-touch-flex" style={{ marginTop: 25 }}>
          <a href="/images/PDFs/DemoPresentation.pdf" target="_blank">
            <div className="intro-sign-up-button" style={{ marginRight: 10 }}>
              View Demo Module
            </div>
          </a>
          <a href="/images/PDFs/DemoPresentationGuide.pdf" target="_blank">
            <div className="intro-sign-up-button">
              Access Example Guide
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PresentationGuides;