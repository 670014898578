import React from "react";
import "../../assets/styles/HomeScreen.css";

const PressFeatures = () => {
  return (
    <div className="press-features-section">
      <h2 className="press-features-h1">
        Rapunzl, as featured on...
      </h2>
      <div className="press-features-flex-container">
        <div className="press-features-flex">
          <a className="press-features-logo" href="https://www.markets.businessinsider.com/news/stocks/rapunzl-improves-the-futures-of-american-high-school-students-through-financial-literacy-1031150976">
            <img src="/images/Press/PressLogos/BusinessInsider.webp" alt="rapunzl press feature logo business insider" />
          </a>
          <a className="press-features-smaller" href="https://abc7chicago.com/business/chicago-startup-roundup-returnrunners-and-rapunzl-apps/3439386/">
            <img src="/images/Press/PressLogos/ABC.webp" alt="rapunzl press feature logo abc" />
          </a>
          <a className="press-features-smaller" href="https://www.nbcchicago.com/on-air/as-seen-on/rapunzl-app-507694692.html">
            <img src="/images/Press/PressLogos/NBC.webp" alt="rapunzl press feature logo nbc" />
          </a>
          <a className="press-features-logo" href="https://www.youtube.com/watch?v=R9EyNeI0DuM&feature=youtu.be">
            <img src="/images/Press/PressLogos/CNN.webp" alt="rapunzl press feature logo cnn" />
          </a>
          <a className="press-features-logo" href="https://www.forbes.com/sites/yassprize/2023/04/12/the-perils-of-not-investing-in-financial-literacy/?sh=256dd11c5ab5">
            <img src="/images/Press/PressLogos/ForbesLogo.webp" alt="rapunzl press feature logo forbes" />
          </a>
          <a className="press-features-logo" href="https://www.yassprize.org/awardees/rapunzl/">
            <img src="/images/Home/StopGraphic.webp" alt="rapunzl press feature logo yass" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PressFeatures;