import React from "react";

const StandardsHeader = (props) => {
  return (
    <div className="standards-header-container">
      <div className="standards-header-flex">
        <h1 className="standards-h1">
          Understanding<br/>{props.state ? props.state : 'National'}<br/><span style={{ color: '#ffb200' }}>Financial Literacy<br/>Standards</span>
        </h1>
        <div className="standards-subtext">
          <img alt="logo for the council of economic education" className="standards-cee-logo" src="/images/Standards/CEE.png" />
          <div>
            Learn about the 6 Core Pillars<br/>
            of Personal Financial Education<br/>
            as set nationally by the<br/>
            Council of Economic Education.
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardsHeader;