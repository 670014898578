import React from "react";
import PortalFeatures from "../components/Educators/PortalFeatures";
import GetInTouch from "../components/Educators/GetInTouch";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/EducatorsScreen.css";
import "../assets/styles/PortalScreen.css";
import "../assets/styles/CourseCatalog.css";
import { MetaParentsPage } from "../constants/metaDescriptions";
import CourseCatalog from "../components/Courses/CourseCatalog";
import ForParents from "../components/Educators/ForParents";
import SkillDevelopment from "../components/Educators/SkillDevelopment";
import PresentationGuides from "../components/Educators/PresentationGuides";
import GoogleCloud from "../components/Home/GoogleCloud";
import GamificationBenefits from "../components/Educators/GamificationBenefits";

const ParentsScreen = () => {
  return (
    <div className="route-subcontainer gradient-route">
      <Helmet>
        <title>Rapunzl | Personal Finance For Parents</title>
        <meta name="description" content={MetaParentsPage} />
        <meta property="og:title" content="Rapunzl | Personal Finance For Parents" />
        <meta property="og:description" content={MetaParentsPage}/>
        <meta property="og:url" content="https://www.rapunzl.com/parents" />
        <link rel="canonical" href="https://www.rapunzl.com/parents" />
      </Helmet>
      <Header />
      <img alt="money lifting weights rapunzl financial literacy graphic" className="course-route-image-header" src="/images/Courses/FinancialFitness.webp" />
      <CourseCatalog alt={true} isParents={true} />
      <ForParents />
      <SkillDevelopment isParents={true} />
      <GamificationBenefits />
      <PresentationGuides isParents={true} />
      <GoogleCloud />
      <PortalFeatures isParents={true} />
      <GetInTouch darkMode={true} />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default ParentsScreen;