import React from "react";
import { Link } from "react-router-dom";

const GradientHeader = () => {
  return (
    <div className="dfk-section header css-selector">
      <div className="header-text-container">
        <h1 className="dfk-header-text">
          Empower The Future<br />With Financial Litracy
        </h1>
        <div className="header-text-h1">
          Help us engage and empower students with simulated investing competitions & digital financial literacy curriculum.
        </div>
        <Link to="/summer">
          <div className="summer-button">
            Our Summer Program
          </div>
        </Link>
        <Link to="/educators">
          <div className="summer-button" style={{ marginTop: "10px" }}>
            More Info For Educators
          </div>
        </Link>
      </div>
    </div>
  );
};

export default GradientHeader;