import React from "react";

const DestinyVideo = () => {
  return (
    <div className="destiny-video-container">
      <h2 className="video-text">
        Rapunzl, As Seen On...
      </h2>
      <img loading="lazy" className="cnnLogo" id="cnn" src="/images/Press/PressLogos/CNN.webp" alt="CNN Logo for rapunzl personal finance video" />
      <video className="destinyVideo" controls>
        <source src="/images/About/DestinyVideo.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default DestinyVideo;