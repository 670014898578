import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaStateStandards } from "../constants/metaDescriptions";
import "../assets/styles/ModulePreviewScreen.css";
import ModulePreviewHeader from "../components/Modules/ModulePreviewHeader";
import ModuleAtAGlance from "../components/Modules/ModuleAtAGlance";
import GuidingEnduringPoints from "../components/Modules/GuidingEnduringPoints";
import VocabList from "../components/Modules/VocabList";
import NextPreviousModule from "../components/Modules/NextPreviousModule";
import Modules from "../constants/Modules";

const ModulePreviewScreen = (props) => {
  const moduleObject = Modules.find(module => module.slug === props.match.params.moduleSlug);
  return (
    <div className="route-subcontainer">
      <Helmet>
        <title>{'Rapunzl | Texas Financial Literacy Standards'}</title>
        <meta name="description" content={MetaStateStandards} />
        <meta property="og:title" content="Rapunzl | Texas Financial Literacy Standards" />
        <meta property="og:description" content={MetaStateStandards}/>
        <meta property="og:url" content="https://www.rapunzl.com/parents" />
        <link rel="canonical" href="https://www.rapunzl.com/parents" />
      </Helmet>
      <Header />
      <ModulePreviewHeader
        title={moduleObject.moduleTitle}
        line1={moduleObject.line1}
        line2={moduleObject.line2}
        image={moduleObject.graphic}
        moduleID={moduleObject.id}
      />
      <div className="gradient-route">
      <ModuleAtAGlance
        length={moduleObject.length}
        slideNumber={moduleObject.numberOfSlides}
        activityNumber={moduleObject.numberOfActivities}
        articleNumber={moduleObject.numberOfArticles}
        gradeLevels={moduleObject.gradeLevels}
      />
      <GuidingEnduringPoints
        guidingQuestions={moduleObject.guidingQuestions}
        enduringUnderstandings={moduleObject.enduringUnderstandings}
      />
      <VocabList
        vocabList={moduleObject.vocabList}
      />
      <NextPreviousModule
        slug={moduleObject.slug}
        moduleID={moduleObject.id}
        modulesLength={Modules.length}
      />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default ModulePreviewScreen;