import React, { useState } from 'react';

const StandardsRequestScreen = (props) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    Email: '',
    Phone: '',
    Position: '',
  });
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const formatPhoneNumber = (rawPhone) => {
    // Remove all non-numeric characters
    let cleanedValue = rawPhone.replace(/\D/g, '');
    if (cleanedValue.length === 0) {
      return '';
    }
    // 3126189956
    // Start formatting
    if (cleanedValue[0] === '1') {
      cleanedValue = '+1 (' + cleanedValue.substring(1);
    } else {
      cleanedValue = '+1 (' + cleanedValue;
    }

    if (cleanedValue.length > 6) {
      cleanedValue = cleanedValue.substring(0,7) + ') ' + cleanedValue.substring(7);
    }
    
    if (cleanedValue.length > 12) {
      cleanedValue = cleanedValue.substring(0, 12) + '-' + cleanedValue.substring(12);
    }
    if (cleanedValue.length === 12) {
      cleanedValue = cleanedValue.substring(0, 12) + '-' + cleanedValue.substring(12,16);
    }

    if (cleanedValue.length > 17) {
      cleanedValue = cleanedValue.substring(0, 17)
    }
    return cleanedValue;
  };

  const handleChange = (event) => {
    setError(false);
    let { name, value } = event.target; 
    if (name === 'Phone') {
      value = formatPhoneNumber(value);
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };
  

  const _handleNext = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // Validate All Entries In The Form
    // If There Is An Error, Display An Error Message
    if (formData.firstName.length === undefined || formData.firstName.length < 2) {
      setError(true);
      setErrorMessage('Please provide a first name longer than one character.');
    } else if (formData.lastName.length === undefined || formData.lastName.length < 2) {
      setError(true);
      setErrorMessage('Please provide a last name longer than one character.');
    } else if (!re.test(formData.Email)) {
      setError(true);
      setErrorMessage('Invalid email. You probably made a typo. Please try again.');
    } else if (formData.Phone.length !== 17) {
      setError(true);
      setErrorMessage('Invalid phone number. Please ensure to include the area code.')
      // TODO: Check if Phone Only Contains Numbers
    } else if (formData.Position === '') {
      setError(true);
      setErrorMessage('Please include your position so we can share relevant resources.')
    } 
    // Else Call Pass Through Function To Send Request
    else {
      props.handleContactInfo(formData);
    }
  }

  // TODO: Create A Format Phone Number Function To Display The Value Of The Phone Input (Perhaps Seperate Update Function)

  const validateFormData = () => {
    return Object.values(formData).every(value => value !== '');
  }

  return (
    <div className='standards-request-container'>
      <div className='standards-request-progress-bar'>
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
      </div>
      <div className='standards-input-container'>
        <h2 className='request-h2'>More About You...</h2>
        <h3 className='request-h3'>
          First Name
        </h3>
        <input
          onChange={(event) => handleChange(event)}
          type="text"
          id="firstName"
          name="firstName"
          className="contact-input"
          placeholder='First Name'
          required
        ></input>
        <h3 className='request-h3' style={{ paddingTop: 16 }}>
          Last Name
        </h3>
        <input
          onChange={(event) => handleChange(event)}
          type="text"
          id="lastName"
          name="lastName"
          className="contact-input"
          placeholder='Last Name'
          required
        ></input>
        <h3 className='request-h3' style={{ paddingTop: 16 }}>
          Email Address
        </h3>
        <input
          onChange={(event) => handleChange(event)}
          type="email"
          id="Email"
          name="Email"
          className="contact-input"
          placeholder='Email Address'
          required
        ></input>
        <h3 className='request-h3' style={{ paddingTop: 16 }}>
          Phone
        </h3>
        <input 
          onChange={(event) => handleChange(event)}
          type="tel"
          id="Phone"
          name="Phone"
          className="contact-input"
          placeholder='Phone Number'
          value={formData.Phone}
        ></input>
        <div>
          <h3 className='request-h3' style={{ paddingTop: 16 }}>Your Position / Title</h3>
          <select
            className="request-dropdown dropdown-alt"
            name="Position"
            onChange={handleChange}
            style={{ color: formData.Position.length === 0 ? '#00ffbe' : '#FAFAFA' }}
          >
            <option value="">Select an option</option>
            <option value="Superintendent / Asst Super">Superintendent / Asst Super</option>
            <option value="Curriculum Director">Curriculum Director</option>
            <option value="Chief Academic Officer">Chief Academic Officer</option>
            <option value="Other District Staff">Other District Staff</option>
            <option value="Principal / Asst Principal">Principal / Asst Principal</option>
            <option value="Teacher">Teacher</option>
            <option value="Parent">Parent</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <h3 className='request-h3' style={{ paddingTop: 16 }}>
          Anything else that could help us assist you?
        </h3>
        <textarea
          onChange={(event) => handleChange(event)}
          type="text"
          id="lastName"
          name="lastName"
          className="contact-input"
          placeholder='Last Name'
          required
        ></textarea>
        <div className='request-flex-buttons' style={{ paddingTop: 35 }}>
          <div className='request-back-button' onClick={() => props.handleBack()}>
            Back
          </div>
          <div onClick={() => _handleNext()} className={`request-next-button ${validateFormData() ? 'request-enabled' : 'request-disabled'}`}>
            Go
          </div>
        </div>
        <div className='request-error-message'>
          {isError ? errorMessage : ''}
        </div>
      </div>
    </div>
  );
};
export default StandardsRequestScreen;