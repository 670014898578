import React from "react";
import { Link } from "react-router-dom";

const ModuleAtAGlance = (props) => {
  return (
    <div className="module-glance-container">
      <div className="module-glance-text-container">
        <div className="module-glance-title">
          Module At A Glance
        </div>
        <div className="module-glance-columns-container">
          <div className="module-glance-column1">
            <div className="module-glance-column-title">
              Grade Levels:
            </div>
            <div className="module-glance-column-text">
              {props.gradeLevels}
            </div>
            <div className="module-glance-column-title">
              Est. Length:
            </div>
            <div className="module-glance-column-text">
              {props.length} ({props.slideNumber} slides)
            </div>
            <div className="module-glance-column-title">
              Activities:
            </div>
            <div className="module-glance-column-text">
              {props.activityNumber} Activites
            </div>
            <div className="module-glance-column-title">
              Articles:
            </div>
            <div className="module-glance-column-text">
              {props.articleNumber} Articles
            </div>
          </div>
          <div className="module-glance-column2">
            <div className="module-glance-column-title">
              Languages:
            </div>
            <div className="module-glance-column-text">
              English & Spanish
            </div>
            <div className="module-glance-column-title">
              Curriculum Fit:
            </div>
            <div className="module-glance-column-text">
              Math, Business, Economics, CTE, Social Studies
            </div>
            <div className="module-glance-column-title">
              Standards Alignment:
            </div>
            <div className="module-glance-column-text">
              CEE National Standards, Jump$tart National Standards & Relevant State Standards
            </div>
          </div>
        </div>
      </div>
      <img className="module-glance-image" alt="magnifying glass with stock chart" src="/images/Courses/magnifyingGlass.png" />
    </div>
  );
};

export default ModuleAtAGlance;