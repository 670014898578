import React from "react";
import { Link } from "react-router-dom";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/PressScreen.css";
import { MetaPressScreen } from "../constants/metaDescriptions";

const PressScreen = () => {
  return (
    <div className="route-container">
      <Helmet>
        <title>Rapunzl | Press & Media Requests</title>
        <meta name="description" content={MetaPressScreen} />
        <meta property="og:title" content="Rapunzl | Press & Media Requests" />
        <meta property="og:description" content={MetaPressScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/press" />
        <link rel="canonical" href="https://www.rapunzl.com/press" />
      </Helmet>
      <Header />
      <div className="press-container">
        <div className="press-header">
          <img src="/images/Press/PressGraphic.webp" className="press-header-img" alt="Rapunzl Press Inquiries" />
          <h1 className="press-h1">
            Rapuznl Press Inquiries
          </h1>
        </div>
        <div className="press-h2">
          To get in touch with the Press Team at Rapunzl, please contact us below with your press inquiry & someone will get back to you shortly.
        </div>
        <div className="press-h2">
          If you are interested in sponsorships or providing scholarship prizes to one of Rapunzl's 501(c)3 partners to help bring personal finance to life, please contact us through Dreams For Kids <Link to="/contact">here</Link>.
        </div>
        <div className="press-h2">
          We're unable to respond to customer service requests outside of Rapunzl's Support System. If you're having an issue with Rapunzl's App, Teacher Portal, or personal finance curriculum, please contact support: <Link to="/contact" className="press-click-here">here</Link>
        </div>
        <Link to="/contact">
          <div className="contact-us">
          Share Press Inquiry With Rapunzl
          </div>
        </Link>
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default PressScreen;
