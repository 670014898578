import React from "react";
import "../../assets/styles/EducatorsScreen.css";

const ScholarshipsInfo = () => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '120px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="Personal Finance Curriculum & Scholarships History" src="/images/Educators/Scholarships.webp"/>
      <div>
        <h2 className="educator-header">
          Rapunzl Has Distributed Over $300,000 In Scholarships
        </h2>
        <div className="educator-text">
        Rapunzl's simulated investment scholarship engages students in an immersive real-time portfolio competition that aligns with Rapunzl’s financial literacy curriculum.
          <br/><br/>
          We track students simulated portfolios from the first week of January through the last week of April, ranking students’ percentage performance with a leaderboard which updates every 5 minutes.
          <br/><br/>
          Students submit Portfolio Defense Essays to justify their simulated investments due by mid-May.
          <br/><br/>
          Top-performing students then nationally are awarded scholarships through 529 Accounts, and the best student at each school receives fractional shares in a company of their choosing!
        </div>  
        <div className="get-in-touch-flex">
          <a href="https://www.forms.gle/pi459acNEj63fkRS9">
            <div className="checkout-rapunzl">
              Register School For Rapunzl Scholarship
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipsInfo;