import React from "react";
import { Link } from "react-router-dom";

const PortalFeatures = (props) => {
  return (
    <div className="portal-section-container2">
        
        <div className="portal-s2-top" style={{ paddingTop: props.isParents ? '100px' : '250px' }}>
            {props.isParents && (
                <img loading="lazy" alt="educator dashboard computer" className="portal-features-header-image" src="/images/Educators/3DeviceGraphic.webp" />
            )}
            {!props.isParents && (
                <img loading="lazy" className="portal-s2-lightbulb" src="/images/Portal/Lightbulb.webp" alt="Light Bulb For Rapunzl Personal Finance Curriculum" />
            )}
            {!props.isParents && (
                <div className="portal-h2">
                    Our Educator Dashboard<br/>Unlocks Personal Finance In Classrooms
                </div>
            )}
            {props.isParents && (
                <div className="portal-h2">
                    Our Parent Dashboard<br/>Unlocks Financial Literacy At Home
                </div>
            )}
        </div>
        <div style={{ paddingBottom: '100px' }}>
            <div className="portal-s2-tile">
                <img loading="lazy" src="/images/Portal/BuildCourse_Color.webp" className="portal-badge-icon" alt="Build Courses with Rapunzl Educator Dashboard" />
                <div className="portal-features-flex-item">
                    <div className="portal-s2-title">
                        Get Started In Minutes
                    </div>
                    <div className="portal-s2-text">
                        {props.isParents ? 'Just input the first name, last name, and email address of your child or children.' : 'Upload a CSV or XLSX file to the Educator Dashboard with students first name, last name, and school email.'} We'll create their account{props.isParents ? '(s)' : 's'}, add them to a classroom, and share login details.
                    </div>
                </div>
            </div>
            <div className="portal-s2-tile">
                <img loading="lazy" src="/images/Portal/AddStudents_Color.webp" className="portal-badge-icon" alt="Pre-built Courses with Rapunzl Educator Dashboard" />
                <div className="portal-features-flex-item">
                    <div className="portal-s2-title">
                        Pre-Built Courses
                    </div>
                    <div className="portal-s2-text">
                        Access pre-built financial education courses that range from 3-week activities to 14-week, semester-long programs.
                    </div>
                </div>
            </div>
            <div className="portal-s2-tile">
                <img loading="lazy" src="/images/Portal/CreateClassroom_Color.webp" className="portal-badge-icon" alt="Student Progress with Rapunzl Educator Dashboard" />
                <div className="portal-features-flex-item">
                    <div className="portal-s2-title">
                        Monitor Student Engagement
                    </div>
                    <div className="portal-s2-text">
                        Track {props.isStudents ? `your child's` : `students'`} performance, see what stocks they’re buying and selling, and how often they engage with the Rapunzl platform.
                    </div>
                </div>
            </div>
            <div className="portal-s2-tile">
                <img loading="lazy" src="/images/Portal/Customize_Color.webp" className="portal-badge-icon" alt="Customizable with Rapunzl Educator Dashboard" />
                <div className="portal-features-flex-item">
                    <div className="portal-s2-title">
                        Fully Customizable
                    </div>
                    <div className="portal-s2-text">
                        Incorporate external modules, modify sequencing, update assessments, and amend any course for your {props.isParent ? 'home' : 'class'}.
                    </div>
                </div>
            </div>
            <div className="portal-s2-tile">
                <img loading="lazy" src="/images/Portal/CollectGrades_Color.webp" className="portal-badge-icon" alt="Collect Grades with Rapunzl Educator Dashboard" />
                <div className="portal-features-flex-item">
                    <div className="portal-s2-title">
                        Collect & Track Grades
                    </div>
                    <div className="portal-s2-text">
                        Create customized quiz assessments from our question library, delivered through the Rapunzl platform.
                    </div>
                </div>
            </div>
            <div className="portal-s2-tile" style={{ borderBottom: 'none' }}>
                <img loading="lazy" src="/images/Portal/ExportCourse_Color.webp" className="portal-badge-icon" alt="Control your class data with Rapunzl Educator Dashboard" />
                <div className="portal-features-flex-item">
                    <div className="portal-s2-title">
                        Control Your Class Data
                    </div>
                    <div className="portal-s2-text">
                        Export grades and standards-alignment crosswalks for easy grading, transparency & accountability.
                    </div>
                </div>
            </div>
            {!props.isParents && (
                <Link to="/requestStandards">
                    <div className="portal-demo-button">
                        See How We Align To Your State Standards
                    </div>
                </Link>
            )}
        </div>
    </div>
  );
};

export default PortalFeatures;