import React from "react";
import DestinyVideo from "../components/Educators/DestinyVideo";
import PortalHeader from "../components/Educators/PortalHeader";
import PortalFeatures from "../components/Educators/PortalFeatures";
import GetInTouch from "../components/Educators/GetInTouch";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/EducatorsScreen.css";
import "../assets/styles/PortalScreen.css";
import "../assets/styles/CourseCatalog.css";
import { MetaEducatorsScreen } from "../constants/metaDescriptions";
import CourseCatalog from "../components/Courses/CourseCatalog";
import TeacherTestimonials from "../components/Home/TeacherTestimonials";
import PresentationGuides from "../components/Educators/PresentationGuides";
import GoogleCloud from "../components/Home/GoogleCloud";
import GamificationBenefits from "../components/Educators/GamificationBenefits";

const EducatorsScreen = () => {
  return (
    <div className="route-subcontainer gradient-route">
      <Helmet>
        <title>Rapunzl | Personal Finance Educators</title>
        <meta name="description" content={MetaEducatorsScreen} />
        <meta property="og:title" content="Rapunzl | Personal Finance Educators" />
        <meta property="og:description" content={MetaEducatorsScreen}/>
        <meta property="og:url" content="https://www.rapunzl.com/educators" />
        <link rel="canonical" href="https://www.rapunzl.com/educators" />
      </Helmet>
      <Header />
      <PortalHeader districtURL={false} />
      <CourseCatalog />
      <PresentationGuides /> 
      <DestinyVideo />
      <PortalFeatures />
      <div style={{ height: '125px' }} />
      <GamificationBenefits isEducators={true} />
      <GoogleCloud />
      <TeacherTestimonials includeContact={false} />
      <GetInTouch darkMode={true} />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default EducatorsScreen;