import React, { Suspense } from "react";
import GradientHeader from "../components/Partners/GradientHeader";
import PartnersGrid from "../components/Partners/PartnersGrid";
import OurImpact from "../components/Partners/OurImpact";
import ThisYearMap from "../components/Partners/ThisYearMap";
import ROWhy from "../components/Partners/ROWhy";
import AboutWithVideo from "../components/Partners/AboutWithVideo";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/DFKScreen.css";
import { MetaDFKScreen } from "../constants/metaDescriptions";

const PartnersScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Partners & Sponsorship</title>
        <meta name="description" content={MetaDFKScreen} />
        <meta property="og:title" content="Rapunzl | Partners & Sponsorship" />
        <meta property="og:description" content={MetaDFKScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/partners" />
        <link rel="canonical" href="https://www.rapunzl.com/partners" />
      </Helmet>
      <Header />
      <div className="route-subcontainer" style={{ backgroundColor: '#c4dfd8' }}>
        <GradientHeader />
        <AboutWithVideo />
        <OurImpact />
        <ThisYearMap />
        <ROWhy />
        <PartnersGrid />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default PartnersScreen;