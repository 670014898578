import React from "react";
import "../../assets/styles/HomeScreen.css";

const RapunzlInNumbers = () => {
  return (
    <div className="rapunzl-in-numbers-container">
      <h2 className="in-numbers-subtext">
        Rapunzl's Impact Since 2018...
      </h2>
      <div className="in-numbers-flex-container">
        <div className="in-numbers-flex-item">
          <div className="in-number-flex">
            <div className="in-number-big">
              100,000
            </div>
            <div className="in-number-small">
              +
            </div>
          </div>
          <div className="in-number-subtext">
            Students Simulated Their<br/>First Stock Portfolio
          </div>
        </div>
        <div className="in-numbers-flex-item">
          <div className="in-number-flex">
            <div className="in-number-small">
              $
            </div>
            <div className="in-number-big">
              300,000
            </div>
            <div className="in-number-small">
              +
            </div>
          </div>
          <div className="in-number-subtext">
            Scholarships Prizes<br/>Awarded
          </div>
        </div>
        <div className="in-numbers-flex-item">
          <div className="in-number-flex">
            <div className="in-number-big">
              93
            </div>
            <div className="in-number-small">
              %
            </div>
          </div>
          <div className="in-number-subtext">
            Average Test Scores<br/>Ending The Program*
          </div>
        </div>
      </div>
      <div className="in-numbers-footer">
        *High school students who have completed Rapunzl’s core Personal Finance curriculum score an average of 93% on a test created by the FINRA Foundation to measure Financial Capabilities.
        <br/>
        <br/>
        <b style={{ color: "#ff8800" }}>
          29% higher than the national average
        </b>
      </div>
    </div>
  );
};

export default RapunzlInNumbers;