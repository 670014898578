import React from "react";

const BenefitsOpportunities = () => {
  return (
    <div className="benefits-opportunities">
      <h2 className="section-header">
        Rapunzl Summer Camp<br/>Benefits & Opportunities
      </h2>
      <div className="benefits-flex">
        <div className="benefit">
          <img loading="lazy" className="benefit-img" src="/images/Summer/DiscoverInvesting.webp" alt="Discover Investing with Rapunzl Personal Finance Summer Camp"/>
          <h3 className="benefit-title">
            Improved Financial<br />
            Literacy & Awareness
          </h3>
        </div>
        <div className="benefit">
          <img loading="lazy" className="benefit-img" src="/images/Summer/CashStipends.webp" alt="Funded Trading Accounts with Rapunzl Personal Finance Summer Camp"/>
          <h3 className="benefit-title">
            Funded<br />
            Trading Accounts
          </h3>
        </div>
        <div className="benefit">
          <img loading="lazy" className="benefit-img" src="/images/Summer/IndustryExposure.webp" alt="Insights with Rapunzl Personal Finance Summer Camp"/>
          <h3 className="benefit-title">
            Industry Experts &<br />
            Professional Insights
          </h3>
        </div>
      </div>
      <div className="benefits-flex">
        <div className="benefit">
          <img loading="lazy" className="benefit-img" src="/images/Summer/RealWorldExposure.webp" alt="Industry Exposure with Rapunzl Personal Finance Summer Camp"/>
          <h3 className="benefit-title">
            Real-World<br />
            Industry Exposure
          </h3>
        </div>
        <div className="benefit">
          <img loading="lazy" className="benefit-img" src="/images/Summer/CareerDevelopment.webp" alt="Career Development with Rapunzl Personal Finance Summer Camp"/>
          <h3 className="benefit-title">
            Real-Time<br />
            Career Development
          </h3>
        </div>
        <div className="benefit">
          <img loading="lazy" className="benefit-img" src="/images/Summer/ImproveResume.webp" alt="Improve Resume with Rapunzl Personal Finance Summer Camp" />
          <h3 className="benefit-title">
            Increased Experience<br />
            To Enhance Resumes
          </h3>
        </div>
      </div>
      <a href="https://www.forms.gle/rnkR3mmrmYVqsm379">
        <div className="apply-button benefits-apply">
          Apply To Rapunzl Summer Camp
        </div>
      </a>
    </div>
  );
};

export default BenefitsOpportunities;