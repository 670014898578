import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import NotFoundContainer from "../components/Admin/NotFoundContainer";
import '../assets/styles/Admin/NotFoundScreen.css';
import { MetaNotFoundPage } from "../constants/metaDescriptions";

const NotFoundPage = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | 404 Not Found</title>
        <meta name="description" content={MetaNotFoundPage} />
        <meta property="og:title" content="Rapunzl | 404 Not Found" />
        <meta property="og:description" content={MetaNotFoundPage} />
        <meta property="og:url" content="https://www.rapunzl.com/404" />
        <link rel="canonical" href="https://www.rapunzl.com/404" />
      </Helmet>
      <Header />
      <NotFoundContainer />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default NotFoundPage;