import React from "react";
import { Link } from "react-router-dom";

const UnderstandingStandards = () => {
  return (
    <div className="understanding-standards-container">
      <div className="educator-section" style={{ paddingTop: '10px', paddingBottom: '50px' }}>
        <img loading="lazy" id="Educator-1" className="educator-section-image" alt="all-access pass to Rapunzl financial literacy demo for educators and parents" src="/images/Educators/StandardsAligned.webp"/>
        <div>
          <h2 className="educator-header">
            Understanding Financial Literacy Standards
          </h2>
          <div className="educator-text">
            Financial literacy standards are crucial for equipping students with the knowledge and skills to manage their personal finances effectively. 
            <br /><br />
            While state standards may vary, exploring the national financial literacy standards provides a valuable understanding of common themes. 
            <br/><br/>
            Explore The 6 Core Pillars to understand critical concepts covered in any comprehensive personal financial education.
          </div>
          <Link to="/nationalStandards">
            <div className="understanding-standards-button">
              Learn About National Standards
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnderstandingStandards;