import React from "react";
import "../../assets/styles/EducatorsScreen.css";

const CourseDelivery = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '0px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" style={{ width: '40%' }} alt="Rapunzl offers plug and play project based financial literacy" src="/images/Educators/CourseDelivery.webp"/>
      <div>
        <h2 className="educator-header">
          Rapunzl's Easy Course Delivery
        </h2>
        <div className="educator-text">
        Rapunzl's personal finance curriculum is designed for flexible delivery, accommodating both synchronous and asynchronous learning. 
          <br /><br />
          Our financial literacy courses include detailed teacher pacing guides for an in-person, full-semester course led by an instructor, that can be adapted to fit course and scheduling needs.
          <br /><br />
          These resources can also provide supplementary support to an educator in the classroom, or serve as an extended asynchronous learning opportunity for interested students.
          </div>
      </div>
    </div>
  );
};

export default CourseDelivery;