import React from "react";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from "react-router-dom";
import Modules from "../../constants/Modules";

const NextPreviousModule = (props) => {
  console.log('here', props.moduleID, props.modulesLength)
  const nextID = props.moduleID + 1 > props.modulesLength ? 1 : props.moduleID + 1;
  const pastID = props.moduleID - 1 === 0 ? props.modulesLength : props.moduleID - 1;
  const nextModule = Modules.find(module => module.id === nextID);
  const pastModule = Modules.find(module => module.id === pastID);
  return (
    <div className='module-footer-flex'>
      <Link to={"/module/" + pastModule.slug}>
        <div className='module-footer-item'>
          <KeyboardDoubleArrowLeftIcon className='module-footer-arrow'/>
          <div>
            <div className='module-footer-header'>
              Previous Module
            </div>
            <div className='module-footer-item-text'>
              {pastModule.moduleTitle}
            </div>
          </div>
        </div>
      </Link>
      <Link to={"/module/" + nextModule.slug}>
        <div className='module-footer-item'>
          <KeyboardDoubleArrowRightIcon className='module-footer-arrow' />
          <div>
            <div className='module-footer-header'>
              Next Module
            </div>
            <div className='module-footer-item-text'>
              {nextModule.moduleTitle}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NextPreviousModule;