// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.press-container {
	padding-top: 135px;
	width: 95%;
	max-width: 850px;
	border-bottom: 2px solid white;
	padding-bottom: 165px;
	margin: auto;
}
.press-header-img {
	display: block;
	width: 275px;
	height: 250px;
	margin: auto;
	border-bottom: 6px solid purple;
	margin-bottom: 11px;
}
.press-h1 {
	text-align: center;
	padding-bottom: 30px;
	font-size: 38px;
	font-weight: 600;
}
.press-h2 {
	font-size: 20px;
	font-weight: 200;
	color: #062e24;
	padding-bottom: 14px;
}
.contact-us {
	background-color: #10a27d;
	border-radius: 3px;
	color:white;
	cursor:pointer;
	font-size: 16px;
	font-weight: 500;
	margin:auto;
	margin-top: 50px;
	margin-bottom: 85px;
	padding: 23px;
	padding-top:15px;
	padding-bottom:15px;
	text-align: center;
	width: 175px;
  }
  .contact-us:hover {
	color: #007154;
	background-color: white;
  }
  @media (max-width: 900px) {
	/* h1 {
	  text-align: center;
	} */
	/* h2 {
	  text-align: center;
	}
	h3 {
	  text-align: center;
	} */
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/PressScreen.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,UAAU;CACV,gBAAgB;CAChB,8BAA8B;CAC9B,qBAAqB;CACrB,YAAY;AACb;AACA;CACC,cAAc;CACd,YAAY;CACZ,aAAa;CACb,YAAY;CACZ,+BAA+B;CAC/B,mBAAmB;AACpB;AACA;CACC,kBAAkB;CAClB,oBAAoB;CACpB,eAAe;CACf,gBAAgB;AACjB;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,cAAc;CACd,oBAAoB;AACrB;AACA;CACC,yBAAyB;CACzB,kBAAkB;CAClB,WAAW;CACX,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,WAAW;CACX,gBAAgB;CAChB,mBAAmB;CACnB,aAAa;CACb,gBAAgB;CAChB,mBAAmB;CACnB,kBAAkB;CAClB,YAAY;EACX;EACA;CACD,cAAc;CACd,uBAAuB;EACtB;EACA;CACD;;IAEG;CACH;;;;;IAKG;EACF","sourcesContent":[".press-container {\n\tpadding-top: 135px;\n\twidth: 95%;\n\tmax-width: 850px;\n\tborder-bottom: 2px solid white;\n\tpadding-bottom: 165px;\n\tmargin: auto;\n}\n.press-header-img {\n\tdisplay: block;\n\twidth: 275px;\n\theight: 250px;\n\tmargin: auto;\n\tborder-bottom: 6px solid purple;\n\tmargin-bottom: 11px;\n}\n.press-h1 {\n\ttext-align: center;\n\tpadding-bottom: 30px;\n\tfont-size: 38px;\n\tfont-weight: 600;\n}\n.press-h2 {\n\tfont-size: 20px;\n\tfont-weight: 200;\n\tcolor: #062e24;\n\tpadding-bottom: 14px;\n}\n.contact-us {\n\tbackground-color: #10a27d;\n\tborder-radius: 3px;\n\tcolor:white;\n\tcursor:pointer;\n\tfont-size: 16px;\n\tfont-weight: 500;\n\tmargin:auto;\n\tmargin-top: 50px;\n\tmargin-bottom: 85px;\n\tpadding: 23px;\n\tpadding-top:15px;\n\tpadding-bottom:15px;\n\ttext-align: center;\n\twidth: 175px;\n  }\n  .contact-us:hover {\n\tcolor: #007154;\n\tbackground-color: white;\n  }\n  @media (max-width: 900px) {\n\t/* h1 {\n\t  text-align: center;\n\t} */\n\t/* h2 {\n\t  text-align: center;\n\t}\n\th3 {\n\t  text-align: center;\n\t} */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
