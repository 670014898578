import React from "react";

const GuidingEnduringPoints = (props) => {
  return (
    <div>
      <div className='flex-courses-dot-header' style={{ paddingTop: 0, paddingBottom: 75 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
      <div className="guiding-enduring-points">
        <div className="guiding-container">
          <div className="guiding-title">
            Guiding Questions
          </div>
          {props.guidingQuestions.map((item, index) => {
            return (
              <div key={'a' + index} className="guiding-item">
                - {item}
              </div>
            )
          })}
        </div>
        <div className="guiding-container">
          <div className="guiding-title">
            Enduring Understandings
          </div>
          {props.enduringUnderstandings.map((item, index) => {
            return (
              <div key={'a' + index} className="guiding-item">
                - {item}
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex-courses-dot-header' style={{ paddingTop: 70 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
    </div>
  );
};

export default GuidingEnduringPoints;