import React from "react";

const TopicsCovered = (props) => {

  function getCourseTopics() {
    if (props.courseName.toLowerCase() === 'financialmath') {
      return [
        ['Statistics','Introduce basic statistical concepts and how they apply to financial decisions.','/images/Courses/LearningConcepts/FinancialMath/Statistics.png'],
        ['Probabilities','Explain the principles of probability and their applications in finance.','/images/Courses/LearningConcepts/FinancialMath/Probability.png'],
        ['Exponents','Teach the use of exponents and square roots in financial calculations.','/images/Courses/LearningConcepts/FinancialMath/Exponents.png'],
        ['Equations','Show how to solve equations that are commonly used in financial contexts.','/images/Courses/LearningConcepts/FinancialMath/Equations.png'],
        ['Pre-Algebra','Introduce foundational algebraic concepts relevant to financial math.','/images/Courses/LearningConcepts/FinancialMath/PreAlgebra.png'],
        ['Algebra','Teach more advanced algebraic techniques used in financial analysis.','/images/Courses/LearningConcepts/FinancialMath/Algebra.png']
      ];
    } else if (props.courseName.toLowerCase() === 'personalfinance') {
      return [
        ['Budgeting','Explain how to create and manage a personal budget.','/images/Courses/LearningConcepts/PersonalFinance/Budgeting.png'],
        ['Saving','Explain the principles of saving money and how to create a savings plan.','/images/Courses/LearningConcepts/PersonalFinance/Saving.png'],
        ['Banking','Teach the basics of banking, including different types of accounts and services.','/images/Courses/LearningConcepts/PersonalFinance/Banking.png'],
        ['Debit Cards','Teach the responsible use and management of debit cards.','/images/Courses/LearningConcepts/PersonalFinance/DebitCards.png'],
        ['Credit','Introduce the concept of credit and how to build and maintain a good credit score.','/images/Courses/LearningConcepts/PersonalFinance/Credit.png'],
        ['Loans','Explain the types and terms of loans and how to manage them.','/images/Courses/LearningConcepts/PersonalFinance/Loans.png'],
        ['Job Income','Teach how to understand and manage income from employment.','/images/Courses/LearningConcepts/PersonalFinance/JobIncome.png'],
        ['Investing','Provide an introduction to investing and various investment options.','/images/Courses/LearningConcepts/PersonalFinance/Investing.png'],
        ['Insurance','Explain the importance of insurance and different types of coverage.','/images/Courses/LearningConcepts/PersonalFinance/Insurance.png'],
        ['Retirement','Teach the importance of planning for retirement and various saving options.','/images/Courses/LearningConcepts/PersonalFinance/Retirement.png'],
      ];
    } else if (props.courseName.toLowerCase() === 'collegecareers') {
      return [
        ['Career Paths','Introduce various career options and the pathways to achieve them.','/images/Courses/LearningConcepts/CollegeCareers/Careers.png'],
        ['Applying To College','Explain the college application process and requirements.','/images/Courses/LearningConcepts/CollegeCareers/College.png'],
        ['Financial Aid','Teach how to apply for and manage financial aid for college.','/images/Courses/LearningConcepts/CollegeCareers/FinancialAid.png'],
        ['Apply For Jobs','Guide students through the process of searching and applying for jobs.','/images/Courses/LearningConcepts/CollegeCareers/ApplyJobs.png'],
        ['Buying First Home','Teach the steps and considerations for purchasing a first home.','/images/Courses/LearningConcepts/CollegeCareers/BuyingHome.png'],
      ];
    } else if (props.courseName.toLowerCase() === 'advancedinvesting') {
      return [
        ['Investing','Provide an overview of different investment options and strategies.','/images/Courses/LearningConcepts/Advanced/Investing.png'],
        ['Reading Financials','Teach how to read and interpret financial statements and reports.','/images/Courses/LearningConcepts/Advanced/ReadingFinancials.png'],
        ['Shorting A Stock','Explain the concept and process of short selling in the stock market.','/images/Courses/LearningConcepts/Advanced/Shorting.png'],
        ['Managing Risk','Show strategies for identifying, assessing, and reducing investment risks.','/images/Courses/LearningConcepts/Advanced/ManagingRisk.png'],
        ['Analyze ETFs','Teach students how to evaluate and invest in Exchange-Traded Funds.','/images/Courses/LearningConcepts/Advanced/ETF.png'],
        ['Fundamental Analysis',`Explain assessing a company's financial health and value using fundamental analysis.`,'/images/Courses/LearningConcepts/Advanced/Fundamental.png'],
        ['Technical Analysis','Introduce tools & techniques for analyzing stock market trends and patterns.','/images/Courses/LearningConcepts/Advanced/Technical.png']
      ];
    }
    // Error Catcher For Bad URLs
    else {
      return [
        ['','','']
      ];
    }
  }

  return (     
    <div className="course-content-summary-container">
      <h2 className="course-content-summary-title">
        Topics Covered In This Course
      </h2>
      <div className="course-content-flex-container">
        {getCourseTopics().map((topic, index) => {
          return (
            <div key={index} className="course-content-flex-item">
              <img loading="lazy" alt={`${topic[0]} financial literacy curriculum topic icon`} className="course-content-topic-icon" src={topic[2]} />
              <div>
                <h4 className="course-content-title-text">
                  {topic[0]}
                </h4>
                <div className="course-content-subtext">
                  {topic[1]}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};
export default TopicsCovered;