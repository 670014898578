import React from "react";

const MeetTheMindsHeader = () => {
  return (
    <div className="standards-header-container about-header-container">
      <div className="standards-header-flex">
        <h1 className="standards-h1">
          Meet The Minds<br/><span style={{ color: '#ffb200' }}>Behind The Mission</span>
        </h1>
      </div>
      <div className="standards-header-flex about-screen-header-flex2" style={{ justifyContent: 'flex-end' }}>
        <img className="about-header-image" alt="Brian Curcio Myles Gage Forbes Under 30" src="/images/About/ForbesGraphic.png" />
        <div className="meet-minds-block-text">
          Our founders, Brian Curcio and Myles Gage met freshman year of high school in Chicago during the 2008 financial crisis and bonded over a passion for finance.
          <br/><br/>
          <span style={{ fontWeight: '300', color: '#FAFAFA', fontSize: 20 }}>
          In April 2018, Myles and Brian launched Rapunzl's first scholarship competition in Chicago, driving to more than 120 high schools.
          <br/><br/>They taught in hundreds of classrooms, engaged over 2,500 students, and realized a critical need in classrooms for financial literacy tools.
          <br/><br/>In 2023, Myles and Brian were named to Forbes 30 Under 30, Brian currently serves as a Yass Prize Fellow, and Myles serves as a fellow for the Social Change Fund United.
          </span>
        </div>
      </div>
    </div>
  );
};

export default MeetTheMindsHeader;