import React from "react";

const VocabList = (props) => {
  return (
    <div className="vocab-preview-container">
      <div className="vocab-preview-title">
        Module Vocab & Key Topics
      </div>
      <div className="vocab-flex-container">
        {props.vocabList.map((item, index) => {
          return (
            <div key={'c'+ index} className="vocab-item">
              <div className="vocab-item-title">
                {item.word}
              </div>
              <div className="vocab-item-definition">
                {item.definition}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default VocabList;