import React from 'react';

const ArticleTitleSection = (props) => {
  return (
    <div className='article-top-section'>
      <img alt='financial literacy soaring badge' className="article-title-image" src='/images/Courses/AdvancedInvesting621.webp' />
      <h1 className='article-top-section-title'>
        {props.title}
      </h1>
      {props.subtitle.map((item, index) => {
        return (
          <div key={index} className='article-top-section-subtitle'>
            {item} <br/><br/>
          </div>
      )})}
    </div>
  );
};
export default ArticleTitleSection;