import React from "react";

const ExploreLibrary = () => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '90px', paddingBottom: '70px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="personal finance curriculum and resources for educators and parents" src="/images/Demo/Telescope.webp"/>
      <div>
        <h2 className="educator-header">
          Explore Rapunzl’s Personal Finance Resource Library
        </h2>
        <div className="educator-text">
          Dive into Rapunzl’s extensive personal finance resource library and access a wide range of materials, including lesson plans, video tutorials, and interactive activities that cover essential financial concepts.
          <br/><br/>
          Whether you are looking to enhance your existing curriculum or build a new one from scratch, our resources provide the support you need to create an engaging and real-world learning experience.
        </div>
      </div>
    </div>
  );
};

export default ExploreLibrary;