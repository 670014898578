import React from "react";
import "../../assets/styles/EducatorsScreen.css";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { Link } from "react-router-dom";

const ForParents = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '20px', marginTop: '0px', paddingBottom: '50px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="Help to understand financial literacy for parents and homeschoolers" src="/images/Educators/ElevateMath.webp"/>
      <div>
        <h2 className="educator-header">
          Financial Literacy For Parents & Homeschools
        </h2>
        <div className="educator-text">
          Rapunzl has a library with 100+ hours of financial literacy resources that parents can access. This personal finance curriculum help bring financial literacy concepts to life with real-world learning.
          <br /><br />
          Digital and print activities help provide your child with real-world scenarios that contexualize your students learning, helping parents integrate simulated investment portfolio to increase engagement.
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
            Guide your child into setting a savings goal.
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
            Help your child open their first savings account.
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
            Setup you child’s simulated investing account.
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
            Teach your child about diversification, risk & insurance.
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
            Monitor learning progress through our parent dashboard
          </div>
        </div>
        <div className="get-in-touch-flex">
          <Link to="/demo">
            <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
              Request A Parent Demo
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForParents;