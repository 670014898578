import React from "react";

const CreditStandard = () => {
  return (
    <div className="educator-section national-standard-container" style={{ margin: 'auto' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="personal finance curriculum and resources for educators and parents" src="/images/Standards/ManagingCredit.webp"/>
      <div   className="standard-margin-shift-2">
        <h2 className="national-standard-subtext">
          Financial Literacy Strand 4:
          <br/>
          <span className="national-standard-bold">Credit</span>
        </h2>
        <div className="educator-text">
          This financial literacy standard helps students build healthy credit habits by educating students about credit scores, responsible borrowing, and the consequences of debt. 
          <br/><br/>
          Teaching students about credit management helps them make informed decisions about using credit cards, loans, and other forms of credit. 
          <br/><br/>
          Good credit management skills enable students to access financial opportunities and avoid the pitfalls of excessive debt, contributing to their overall financial well-being and stability.
        </div>
      </div>
    </div>
  );
};

export default CreditStandard;