import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/Admin/Footer.css';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='top'>
        <div className='left'>
          <Link to='/'>
            <img loading="lazy" src='/images/Admin/CompanyLogos/WhiteLogo.png' className='footer-logo' alt='rapunzl investments company logo' />
          </Link>
          <div className='footer-company-description'>
            Rapunzl connects investors with simulated stock portfolios & stock trading competitions. Join the thousands of others investing alongside their friends & competing for free prizes!</div>
          <div className='socialIcons'>
            <a href='https://www.facebook.com/Rapunzl.Investing'>
              <img loading="lazy" id='facebook' alt='rapunzl personal finance app facebook page social icon' src='/images/Admin/SocialIcons/White/facebookicon.png'/>
            </a>
            <a href='https://www.twitter.com/Rapunzl_Invest'>
              <img loading="lazy" id='twitter' alt='rapunzl personal finance app twitter page social icon' src='/images/Admin/SocialIcons/White/twittericon.png' />
            </a>
            <a href='https://www.linkedin.com/company/rapunzl-investing-llc'>
              <img loading="lazy" id='linkedin' alt='rapunzl personal finance app linkedin page social icon' src='/images/Admin/SocialIcons/White/linkedinicon.png' />
            </a>
            <Link to='/contact'>
              <img loading="lazy" id='email' alt='rapunzl personal finance app mail link social icon' src='/images/Admin/SocialIcons/White/mailicon.png' />
            </Link>
          </div>
          <div className='footer-button-container'>
            <a href='https://www.apps.apple.com/us/app/rapunzl-invest-compete-win/id1222181232'>
              <img
                loading="lazy"
                className='footer-download-button'
                src='/images/Admin/AppStoreButtons/AppStore.png'
                alt='download rapunzl personal finance app in the appstore button'
              />
            </a>     
            <a href='https://www.play.google.com/store/apps/details?id=com.rapunzlinvestments.app2'>
              <img
                loading="lazy"
                className='footer-download-button'
                src='/images/Admin/AppStoreButtons/GooglePlay.png'
                alt='download personal finance rapunzl app in the googleplaystore button'
              />
            </a>
          </div>
        </div>
        <div className='right'>
          <div className='item-container'>
            <Link to='/about'>
              <div className='item'>
                ABOUT RAPUNZL
              </div>
            </Link>
            <Link to='/summer'>
              <div className='item'>
                SUMMER 2024
              </div>
            </Link>
            <Link to='/educators'>
              <div className='item'>
                FOR EDUCATORS
              </div>
            </Link>
            <Link to='/districts'>
              <div className='item'>
                FOR DISTRICTS
              </div>
            </Link>
            <Link to='/parents'>
              <div className='item'>
                FOR PARENTS
              </div>
            </Link>
            <Link to='/courses'>
              <div className='item'>
                COURSE CATALOG
              </div>
            </Link>
            <Link to='/partners'>
              <div className='item'>
                CORPORATE PARTNERS
              </div>
            </Link>
            <Link to='/crypto'>
              <div className='item'>
                SIMULATED CRYPTO
              </div>
            </Link>
            <Link to='/contact'>
              <div className='item'>
                RAPUNZL SUPPORT
              </div>
            </Link>
            <Link to='/press'>
              <div className='item' style={{ paddingBottom: 10 }}>
                RAPUNZL PRESS
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='disclaimer'>
        Pricing data is provided by Nasdaq Inc and Coinbase Inc. All information provided 'as is' for informational purposes only. This information is not intended for trading purposes nor is it intended as advice. Neither Rapunzl nor any of its independent
        providers is liable for informational errors, incompleteness, or delays, or for actions taken in reliance on information contained herein. By accessing the Rapunzl platform you agree not to redistribute the information found therein.
        <br/><br/>
        The information contained on this website and from any communication related to this website is for information purposes only.
        Rapunzl Investments LLC (Rapunzl) does not hold itself out as providing any legal, financial or other advice.
        Rapunzl also does not make any recommendation or endorsement as to any investment, advisor or other service or product or to any material submitted by third parties or linked to this website.
        In addition, Rapunzl does not offer any advice regarding the nature, potential value or suitability of any particular investment, security or investment strategy.
        The material on this website does not constitute advice and you should not rely on any material in this website to make (or refrain from making) any decision or take (or refrain from making) any action.
        All investment, borrowing or tax decisions should be made with the advice of an appropriately qualified and licensed advisor.
      </div>
      
      <div className='bottom'>
        <div className='powered'>
          © 2024 Rapunzl. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;