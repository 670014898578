import React from "react";
import { Link } from "react-router-dom";

const PortalHeader = (props) => {
  return (
    <div className={`portal-section-container ${props.districtURL ? 'alt-portal-section-container' : ''}`}>
      <div className="portal-section-flex">
        <div className="portal-section-left">
          {props.districtURL && (<h1 className="portal-h1" style={{ color: '#ffc005'}}>
            Bring Financial Literacy To Your District
          </h1>)}
          {!props.districtURL && (<h1 className="portal-h1" style={{ color: '#0e5241'}}>
            Engaging & Interactive Personal Financial Literacy
          </h1>)}
          {!props.districtURL && (
            <div className="portal-p" style={{ color: props.districtURL ? 'white' : 'black' }}>
              Monitor your classroom, access standards-aligned curriculum, deliver in-app assessments, and automatically collect student grades.
              <br/><br/>
              All while students are competiting in a national scholarship competition.
            </div>
          )}
          {props.districtURL && (
            <div className="portal-p" style={{ color: props.districtURL ? 'white' : 'black' }}>
              We provide teachers with hundreds of hours of standards-aligned financial literacy materials and automatically collect student grades to deliver personalized feedback.
              <br/><br/>
              Setup a time to speak with Rapunzl and learn how we can meet personal finance standards in your district.
            </div>
          )}
          <Link to="/demo">
            <div className="portal-demo-button">
              Request Free Educator Demo
            </div>
          </Link>
        </div>
        <div className="portal-section-right">
          <img src={props.districtURL ? '/images/Educators/3DeviceGraphic.webp' : '/images/Portal/Dashboard_Home.webp'} className="portal-header-image" alt="Rapunzl financial literacy Portal Dashboard" />
        </div>
      </div>
    </div>
  );
};

export default PortalHeader;