import React, { useState } from 'react';

const SummerFAQ = () => {
  // Track Which Question Is Visible, If Any
  const [visibleTab, setVisibleTab] = useState(false);

  // Updates Visible Tab Value - Checking If It Is The Same And Closing
  // This ensures only one tab is open at a time
  function changeVisibleTab(value) {
    if (value === visibleTab) {
      setVisibleTab(false);
    } else {
      setVisibleTab(value);
    }
  }

  return (
    <div className="faq-container">
      <div className="faq">
        <h2 className="week-header" style={{ color: "white" }}>
          Rapunzl Summer Camp FAQ
        </h2> 
        <h3 onClick={() => changeVisibleTab(1)} className="h1" id="question1" style={{ borderBottom: visibleTab === 1 ? '0px' : '1px solid lightgrey' }}>
          How Much Does Rapunzl's Summer Camp Cost?
        </h3>
        <div className="p" id="answer1" style={{ display: visibleTab === 1 ? 'block' : 'none' }}>
          Absolutely nothing except your time, focus, and passion to learn. We are incredibly fortunate to have corporate partnerships which help offset the cost of our Summer Program such that we can provide an incredible learning experience at zero cost to students, parents, and other educators.
        </div>
        <h3 onClick={() => changeVisibleTab(2)} className="h1" id="question2" style={{ borderBottom: visibleTab === 2 ? '0px' : '1px solid lightgrey' }}>
          When Can I Sign-Up For The Summer Camp?
        </h3>
        <div className="p" id="answer2" style={{ display: visibleTab === 2 ? 'block' : 'none' }}>
          Applications for the 2024 Summer Program with the Chicago Sky are officially open! Click the link above to Apply Now and join us in-person this summer. We had an overwhelming number of applicants last year and appreciate your understanding that we will continue to prioritize applications on a first-come first-serve basis.
        </div>
        <h3 onClick={() => changeVisibleTab(3)} className="h1" id="question3" style={{ borderBottom: visibleTab === 3 ? '0px' : '1px solid lightgrey' }}>
          What Are Key Summer Camp Dates?
        </h3>
        <div className="p" id="answer3" style={{ display: visibleTab === 3 ? 'block' : 'none' }}>
          The 2024 Summer Application Process is now open! We've started accepting applications and will continue to accept applications up until the April 15th deadline. We may follow up with certain candidates for virtual interviews, however, not receiving an interview does not disadvantage your application. Applications for the 2024 Summer will be made avaialable in early 2024.
          <br /><br />
          A Virtual Kick-Off Event will be scheduled for sometime in May, during the weekend, where we plan to explain the program to students, parents & guardians, teachers, and other educators who helping facilitate this program. This will be an opportunity to answer any additional questions before we kick-off the Summer!
          <br /><br />
          Specific dates for each of the Summer 2024 cohorts can be found in the section above which outlines the program schedule, and we will release an updated 2024 schedule in early January.
        </div>
        <h3 onClick={() => changeVisibleTab(4)} className="h1" id="question4" style={{ borderBottom: visibleTab === 4 ? '0px' : '1px solid lightgrey' }}>
          How Many Cohorts During The Summer Camp?
        </h3>
        <div className="p" id="answer4" style={{ display: visibleTab === 4 ? 'block' : 'none' }}>
          We are currently planning to host 8 cohorts. This includes 2 in-person cohorts in Chicago during June, July, and August. We also plan to host 2 virtual cohorts during July and August with educators from across the country as we continue to expand our program.
        </div>
        <h3 onClick={() => changeVisibleTab(5)} className="h1" id="question5" style={{ borderBottom: visibleTab === 5 ? '0px' : '1px solid lightgrey' }}>
          Why Are Summer Cohorts Capped At 35 Students?
        </h3>
        <div className="p" id="answer5" style={{ display: visibleTab === 5 ? 'block' : 'none' }}>
          Dreams For Kids and Rapunzl felt that in order to deliver a quality education, we needed to maintain low student-to-teacher ratios. This will allow students to receive more personalized attention throughout the program, ultimately increasing retention and learning outcomes. We have 3 full-time teachers onsite for the duration of the program, with 2 additional support staff to ensure things run smoothly.
        </div>
        <h3 onClick={() => changeVisibleTab(6)} className="h1" id="question6" style={{ borderBottom: visibleTab === 6 ? '0px' : '1px solid lightgrey' }}>
          How Can I Get Started On Rapunzl's Simulator?
        </h3>
        <div className="p" id="answer6" style={{ display: visibleTab === 6 ? 'block' : 'none' }}>
          It’s actually pretty simple. Create a free Rapunzl account using your school email. We’ll send you an email verifying your account and then you’re all set! We give you $10,000 to buy and sell stocks using near real-time data. The top performing portfolios for the competition time period win prizes!
        </div>
        <h3 onClick={() => changeVisibleTab(7)} className="h1" id="question7" style={{ borderBottom: visibleTab === 7 ? '0px' : '1px solid lightgrey' }}>
          What's The Catch?
        </h3>
        <div className="p" id="answer7" style={{ display: visibleTab === 7 ? 'block' : 'none' }}>
          There’s no catch! Our program does not collect personally identifiable information on students or any Rapunzl users for that matter. We strongly believe that people should earn while they learn to invest and that our investment competitions will create a new way for young investors to enter the world of investing.
        </div>
        <h3 onClick={() => changeVisibleTab(8)} className="h1" id="question8" style={{ borderBottom: visibleTab === 8 ? '0px' : '1px solid lightgrey' }}>
          Have Another Question About Rapunzl?
        </h3>
        <div className="p" id="answer8" style={{ display: visibleTab === 8 ? 'block' : 'none' }}>
          If you have any additional questions, including leveraging Rapunzl at your school or hosting a virtual cohort with a group of students, please reach out directly to rapunzl@dreamsforkids.org and we will respond within 3 business days. Thanks and we look forward to hearing from you!
        </div>
      </div>
    </div>
  );
};

export default SummerFAQ;