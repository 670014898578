import React from "react";

const SummerHeader = () => {
  return (
    <div className="summer-top-section" style={{ backgroundImage: `url(/images/Summer/Header.webp)` }}>
      <img className="top-img" src="/images/Summer/DreamsForKidsLogo.webp" alt="Rapunzl DFK Financial Literacy Partnership logo"/>
      <h1 className="top-h1">
        Welcome To Rapunzl's 2024<br />
        <span style={{ color: "#FFD000" }}>Financial Literacy Bootcamp</span>
      </h1>
      <div className="top-subtext">
        This Summer Program demystifies financial markets and helps students learn everything from the fundamentals of personal finance to simulating live stock & crypto portfolios.
      </div>
      <div className="top-button-flex">
          <div className="apply-button">
            Applications Closed
          </div>
      </div>
    </div>
  );
};

export default SummerHeader;