import React from 'react';

const ArticleDidYouKnowSection = (props) => {
  return (
    <div className='article-did-you-know-section'>
      <h2 className='did-you-know-subheader'>
        Did You Know?
      </h2>
      {props.text.map((item, index) => {
        return (
          <div key={index} className='did-you-know-text'>
            {item}
          </div>
      )})}
    </div>
  );
};
export default ArticleDidYouKnowSection;