import React from "react";
import "../../assets/styles/EducatorsScreen.css";
import { Link } from "react-router-dom";

const PlugAndPlay = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '0px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="Rapunzl offers plug and play project based financial literacy" src="/images/Educators/Transforming.webp"/>
      <div>
        <h2 className="educator-header">
          Plug-And-Play, Project-Based Financial Literacy
        </h2>
        <div className="educator-text">
          Rapunzl’s financial literacy program leverages our <Link className="article-link" to="/article/leveragingTheRapunzlSimulator">real-time simulator</Link> to meet students where they already are - their smartphones - and leverages curiosity to drive engagement.
          <br /><br />
          Curriculum modules can provide a full-semester worth of content, however, they have also been designed to integrate within an existing Math, Business, Econ or CTE classroom by <Link className="article-link" to="/article/buildingTheRightPersonalFinanceCourse">building a customized course.</Link>
          </div>
        <div className="get-in-touch-flex">
          <Link to="/educators">
            <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
              View Educator Tools
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PlugAndPlay;