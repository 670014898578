import React from "react";

const ModulePreviewHeader = (props) => {
  return (
    <div className="module-preview-header">
      <div className="module-preview-container">
        <img className="module-preview-graphic" src={props.image} alt="module preview cover" />
        <div className="module-preview-text-container">
          <div className="module-preview-subtext">
            Module {props.moduleID}
          </div>
          <div className="module-preview-title">
            {props.title}
          </div>
          <div className="module-preview-description" style={{ paddingBottom: 20 }}>
            {props.line1}
          </div>
          <div className="module-preview-description">
            {props.line2}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulePreviewHeader;