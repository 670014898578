import React from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/HomeScreen.css";

const WhyRapunzl = () => {
  return (
    <div className="hs2" style={{ backgroundImage: `url(/images/Home/WhyRapunzlBG_2.webp)` }}>
      <div className="homeSection">
        <div>
          <h2 className="homeSectionHeader">
            Why The Name Rapunzl?
          </h2>
          <div className="homeSectionText" style={{ maxWidth: '500px', marginBottom: '60px', fontWeight: '500' }}>
            People often view the world of finance & high-returns of Wall Street as trapped in an Ivory Tower - inaccessible and out of reach for most.
            <br /><br />
            Rapunzl provides everyone with an opportunity to learn how to invest; and by doing so, we are rolling down the hair which allows everyone to climb to financial success.
          </div>
          <Link to="/educators">
            <div className="homeSectionButton hs2ButtonB">
              See Rapunzl's Educator Tools
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhyRapunzl;