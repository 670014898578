const Modules = [
	{
		id: 1,
		slug: 'welcome-to-the-stock-market',
		moduleTitle: 'Welcome To The Stock Market',
		numberOfSlides: 18,
		numberOfActivities: 7,
		numberOfArticles: 7,
		gradeLevels: '7th - 12th',
		line1: 'Dive into the world of investing in the stock market and learn how compound growth can help build generational wealth.',
		line2: 'This module explains what stocks are and how they trade. It then examines one of the greatest stock investors of all time to help guide you as you begin your investing journey.',
		length: '2-4 Hours',
		graphic: `/images/ModuleCovers/M1.jpg`,
		guidingQuestions: [
			`What is a share of stock?`,
			`How does the stock market work?`,
			`Why should you invest in stocks?`,
			`How are stock prices determined?`,
			`When should someone start investing?`,
			`How can I practice investing?`,
			`Who is Warren Buffett and what was his secret to success?`,
		],
		enduringUnderstandings: [
			`The way stocks trade & how this contributes to constantly changing prices.`,
			`The benefits of being a shareholder and how investing provides superior returns to a savings account over time.`,
			`Why Warren Buffett has been such a successful investor for so many years following a few simple principles.`,
			`How compound growth can help any investor become a millionaire over time.`,
		],
		vocabList: [
			{
				word: `Stock`,
				definition: `A share of a company which is sold to investors and represents ownership.`,
			},
			{
				word: `Stock Market`,
				definition: `A place for investors to buy & sell publicly traded companies’ stock. This list includes the NASDAQ, S&P 500, & numerous indexes from around the world.`,
			},
			{
				word: `Stock Price`,
				definition: `The current price per share for a company represents the price from the most recent transaction for a company’s stock, determined by investors’ Bids & Asks.`,
			},
			{
				word: `Dividend`,
				definition: `A quarterly payment made to investors when large companies distribute a percentage of their profits to shareholders.`,
			},
			{
				word: `Volume`,
				definition: `The total number of shares of a company’s stock that trade on a specific day.`,
			},
			{
				word: `Liquidity`,
				definition: `The ease with which an investor may sell their investment. Stocks are considered liquid investments, unlike real estate which is much more difficult to trade.`,
			},
			{
				word: `Supply & Demand`,
				definition: `The amount of goods and services available (supply) compared to the amount of goods and services that people want to buy (demand).`,
			},
			{
				word: `Buy & Hold`,
				definition: `An investing strategy where an investor buys shares in a company with the intention of maintaining that position over a period typically longer than 12 months.`,
			},
			{
				word: `Greed & Fear`,
				definition: `These are emotions that typically lead to losses, when investors get greedy they can lose sight of their investing philosophy & when investors are fearful they may sell out of positions prematurely and incur losses rather than riding out a market cycle.`,
			},
			{
				word: `Risk`,
				definition: `The possibility of incurring a loss on your investment. Typically, the larger the potential return, the higher the risk of losing a portion, or your entire, investment.`,
			},
			{
				word: `Diversification`,
				definition: `The concept of investing across various industries and asset classes to mitigate risk and avoid putting all of your eggs in one basket.`,
			},
		],
	},
	{
		id: 2,
		slug: 'saving-versus-investing',
		moduleTitle: 'Saving Versus Investing',
		numberOfSlides: 25,
		numberOfActivities: 9,
		numberOfArticles: 5,
		gradeLevels: '7th - 12th',
		line1: 'Everyone tells you that saving is critical for the future but is investing better to create long-term wealth?',
		line2: 'This module explains the difference between investing & savings, how banks make money & why you can do the same by evaluating companies for their long-term growth potential in order to make your money work smarter.',
		length: '2-4 Hours',
		graphic: `/images/ModuleCovers/M2.jpg`,
		guidingQuestions: [
			`What is the difference between saving and investing?`,
			`How do banks make money while paying you?`,
			`What is interest and how does it affect you?`,
			`How do you start saving?`,
			`How do you start investing?`,
			`What is compounding growth?`,
			`How can dollar-cost-averaging help you?`,
			`What is the Rule of 72 and how can it help you estimate how fast an investment will double with a fixed interest rate or rate or return?`,
		],
		enduringUnderstandings: [
			`Interest is incredibly important when it comes to saving and in everyday life.`,
			`Interest can either work for you with compound interest, or against you with debt.`,
			`Saving and investing are both important to being financially healthy but they serve different purposes.`,
			`Building a plan to save and invest is best for your future and can make life easier.`,
			`Investing is best if done for the long-term rather than the short.`,
			`Time in the market is more important than timing the market.`,
		],
		vocabList: [
			{
				word: `Savings Account`,
				definition: `A type of financial account used by individuals to save money and earn interest over time. Typically, a savings account offers a higher rate of return than a checking account, allowing your savings to grow faster.`,
			},
			{
				word: `Certificate of Deposit (CD)`,
				definition: `A type of deposit account that pays a fixed interest rate for a predetermined period of time. CDs are often available in short-term (up to one year) and long-term periods (5 years or more).`,
			},
			{
				word: `Money Market Account`,
				definition: `A type of bank account with higher interest rates than those offered on traditional savings accounts, but limits the number of withdrawals you can make each month. These accounts also typically require a minimum balance to open and maintain them.`,
			},
			{
				word: `High-Yield Savings Account`,
				definition: `An online savings account that offers an above-average interest rate compared to traditional banks; these accounts are typically FDIC insured up to $250,000 per depositor and offer low minimum balance requirements with no hidden fees or charges for opening or maintaining the account.`,
			},
			{
				word: `Individual Retirement Account (IRA)`,
				definition: `A tax-advantaged retirement plan that allows you to save money for retirement while potentially reducing your taxes each year; there are several types of IRAs including Traditional IRAs, Roth IRAs, SEP IRAs, SIMPLE IRAs, and Self-Directed IRAs.`,
			},
			{
				word: `529 College Savings Plan`,
				definition: `A tax-advantaged investment program designed specifically for college savings; 529 plans are sponsored by states or educational institutions and allow contributors to invest in mutual funds or other types of investments that are managed professionally by fund managers; contributions made into these plans may be eligible for state tax deductions or credits depending on the state in which they occur.`,
			},
			{
				word: `US Savings Bonds`,
				definition: `A type of low-risk investment issued by the US Treasury that pays interest semiannually until they mature usually after 20 years; they can be purchased electronically through Treasury Direct or through most major banks and brokerages firms at face value starting at $25 increments up to thousands of dollars; they are also exempt from state taxes as well as many local taxes so they can provide an additional source of income during retirement years when taxed income could become an issue if not managed properly beforehand.`,
			},
			{
				word: `Emergency Fund`,
				definition: `A type of financial cushion set aside for unexpected expenses such as job losses, medical bills, car repairs etc.; it is important to establish an emergency fund before investing any extra money in order to protect yourself financially from any unforeseen circumstances; it is recommended that you aim for an emergency fund amount equal to at least three months worth of living expenses.`,
			},
		],
	},
	{
		id: 3,
		slug: 'power-risk-of-credit',
		moduleTitle: 'The Power & Risks Of Credit',
		numberOfSlides: 22,
		numberOfActivities: 8,
		numberOfArticles: 9,
		gradeLevels: '7th - 12th',
		line1: 'Before you can become an experienced investor, you need to know about managing your financial fitness. Much like a workout routine, it involves sticking to a few key habits in order to have access to capital through credit.',
		line2: 'Dive into the world of credit scores, why they matter for everything from credit cards to homes.',
		length: '3 - 4.5 Hours',
		graphic: `/images/ModuleCovers/M3.jpg`,
		guidingQuestions: [
			`What is a credit score and why is it important?`,
			`How are you able to keep a good credit score while still utilizing personal debt?`,
			`Why do people use credit cards?`,
			`How do credit cards actually work behind the scenes?`,
			`When is it beneficial to use a credit card to purchase something? When is it not?`,
			`What are the risks of using a credit card versus paying immediately for something?`,
			`What are your rights as a borrower?`,
		],
		enduringUnderstandings: [
			`Credit scores are a critical aspect of financial independence.`,
			`Lenders use credit scores to determine everything from renting an apartment to how much interest is on your credit card debt.`,
			`Credit cards can be useful if properly utilized, however can be extremely dangerous to your financial health if improperly used.`,
			`For most purchases, there is no need to use a credit card unless you have money to pay it off.`,
			`If you get in over your head in debt, there are still things you can do as a borrower to alleviate the mounting debt.`,
		],
		vocabList: [
			{
				word: `Credit Card`,
				definition: `A type of payment card that allows consumers to purchase goods and services on credit. It is issued by a financial institution such as a bank or credit union, and can be used to make purchases in person or online.`,
			},
			{
				word: `Credit Limit`,
				definition: `The maximum amount of money that a consumer can borrow on their credit card. This may also be called the “credit line” or “line of credit”.`,
			},
			{
				word: `Consumer Debt`,
				definition: `Consumer debt includes any debt incurred for non-business related purchases such as credit cards, medical bills, installment loans, and other consumer spending. High levels of consumer debt can lead to difficulty in making payments and delinquency or default on loans.`,
			},
			{
				word: `Debt Consolidation Loan`,
				definition: `A debt consolidation loan is when multiple debts are grouped into one larger loan that has hopefully a lower interest rate and more manageable payment than multiple smaller ones.`,
			},
			{
				word: `Payday Loans`,
				definition: `Payday loans are short term loans with extremely high interest rates given out with minimal restrictions; these types of loans are considered very predatory due to their costliness and should be avoided unless absolutely necessary.`,
			},
			{
				word: `Credit Score`,
				definition: `A numerical representation of an individual's creditworthiness, based on their past borrowing and repayment history. Generally, the higher the score, the more likely it is that a consumer will be approved for loans and other forms of credit.`,
			},
			{
				word: `Annual Percentage Rate (APR)`,
				definition: `Average annual cost of borrowing money, including interest charges and fees associated with a particular credit card or loan product expressed as a single percentage number.`,
			},
			{
				word: `Balance Transfer Fees`,
				definition: `A fee charged when transferring balances from one credit card to another (i.e., when consolidating debt). This often comes in addition to applicable interest rates; however, some cards offer zero-percent balance transfer offers with no fees at all if done within a certain time period after approval/activation of the new account.`,
			},
			{
				word: `Cash Advance Fees`,
				definition: `A fee charged when withdrawing cash from an ATM using your credit card instead of your bank account or debit card—typically 2-5% of the transaction amount, plus any applicable ATM fees from the receiving institution itself (the ATM owner).`,
			},
			{
				word: `Late Payment Fees`,
				definition: `A fee charged by lenders for late payments on your account balance—typically $25-35 per missed payment depending on issuer policy and/or outstanding balance amount/activity since last statement date(s). The amount of time before a late payment fee is called the grace period, which is typically 25-30 days after the close of the billing cycle.`,
			},
			{
				word: `Minimum Payment Due`,
				definition: `The smallest dollar amount that must be paid each month on your loan or credit card debt in order to avoid additional late payment penalties and keep your account current with respect to borrower obligations outlined in terms & conditions documents at time account was opened/activated/approved by lender/issuer respectively.`,
			},
			{
				word: `Credit Utilization Ratio`,
				definition: `A ratio used to measure how much of your available credit you are utilizing at any given time; lower ratios indicate better overall management of debt while higher ratios may trigger warnings or higher interest rates from lenders/issuers in some circumstances.`,
			},
		],
	},
	{
		id: 4,
		slug: 'make-loans-work-for-you',
		moduleTitle: 'How To Make Loans Work For You',
		numberOfSlides: 24,
		numberOfActivities: 4,
		numberOfArticles: 6,
		gradeLevels: '7th - 12th',
		line1: 'Loans serve as a bedrock of the economy and can turn an intimidating purchase price into much smaller, more manageable payments, that allow consumers to plan purchases into the future.',
		line2: 'In some case, purchasing an asset such as a home using debt can be cheaper than renting. In other situations,  such as a car, it’s a little more complicated...',
		length: '3-5 Hours',
		graphic: `/images/ModuleCovers/M4.jpg`,
		guidingQuestions: [
			`What are loans and how can you utilize debt?`,
			`What are the differences between good and bad debt?`,
			`How do companies and governments borrow money?`,
			`Are there good and bad types of debt for companies and governments as well?`,
			`What are credit scores for large institutions when they want to borrow capital?`,
			`How can a company access capital once they have already had an initial public offering?`,
		],
		enduringUnderstandings: [
			`Good debt helps you achieve growth for a higher earning potential or for an asset that appreciates in value.`,
			`Governments and companies borrow capital issuing bonds or other debt instruments.`,
			`Bond ratings are like credit scores for large borrowers issued by credit rating agencies.`,
			`Bond prices are closely related with interest rates because they are a collection of cash flows which investors discount.`,
			`Bond markets provide a massive source of funding for large companies.`,
		],
		vocabList: [
			{
				word: `Fixed Income Securities`,
				definition: `A type of debt instrument that provides a steady income stream in the form of interest payments (due at predetermined intervals) over the life of the security. Examples include bonds, CDs, and treasury notes.`,
			},
			{
				word: `Bond Yields`,
				definition: `The rate of return on a bond calculated as its coupon rate divided by the current market price.`,
			},
			{
				word: `Credit Ratings`,
				definition: `An evaluation assigned to bonds based on their creditworthiness and ability to repay investor's capital in full and on time. Generally, higher ratings indicate higher quality and more desirable bonds for investors.`,
			},
			{
				word: `Principal`,
				definition: `The amount of capital initially lent to the borrower through the bond.`,
			},
			{
				word: `Face Value`,
				definition: `The net present value of all future cash flows related to an existing bond. This is used to show how much the bond owner will receive through maturity.`,
			},
			{
				word: `Coupon`,
				definition: `The dollar value of the periodic interest payment promised to bondholders (usually paid semiannually), as calculated by: Coupon ($)=Coupon Rate x Face Value`,
			},
			{
				word: `Yield to Maturity`,
				definition: `Maturity is the length of time until the principal is scheduled to be repaid. The yield is the rate of return assuming the investor holds the bond until its maturity date. It rises and falls depending on the market value of bond and number of payments left until maturity.`,
			},
			{
				word: `Internal Rate of Return (IRR)`,
				definition: `The rate of return that sets the net present value of an investment equal to zero.`,
			},
			{
				word: `Interest Rate Risk`,
				definition: `The risk associated with adverse changes in interest rates over time, causing prices for existing bonds to fall if rates rise or remain constant since new issues will be issued at current market rates which are typically lower than existing issues’ coupon rates.`,
			},
			{
				word: `Inflation Risk`,
				definition: `The risk that inflation will erode purchasing power over time and reduce the initial value received from investments with fixed returns such as bonds and other fixed-income securities because they do not provide any protection against inflationary pressures.`,
			},
			{
				word: `Call Provisions`,
				definition: `Certain clauses are written into bond contracts that allow issuers to buy back particular outstanding bonds prior to their maturity date under certain conditions such as reaching a preset price, redemption amount, or exceed a specified cap on maximum coupon payments.`,
			},
		],
	},
	{
		id: 5,
		slug: 'what-makes-good-stock',
		moduleTitle: 'What Makes A Good Stock?',
		numberOfSlides: 26,
		numberOfActivities: 4,
		numberOfArticles: 6,
		gradeLevels: '8th - 12th',
		line1: `Picking a good stock involves taking the time to research a company and understand its financials in order for investors to gain insight into a company's health.`,
		line2: 'In this module, we examine the long-term potential of a company by looking at factors such as competitive advantages, industry trends & technology shifts.',
		length: '2-5+ Hours',
		graphic: `/images/ModuleCovers/M5.jpg`,
		guidingQuestions: [
			`What are dividends and why would a company issue them?`,
			`Why do investors want to own companies that pay dividends?`,
			`What types of companies pay dividends and why?`,
			`What are some of the quantitative and qualitative ways to analyze different investments?`,
			`How does a P/E ratio help you determine the value of a company’s stock?`,
		],
		enduringUnderstandings: [
			`Dividends are divisions of profit between stockholders.`,
			`Qualitatively and quantitatively analyze a company and determine if it fits in your investment criteria.`,
			`P/E ratios are used to compare companies in similar industries to determine future performance.`,
			`Calculating a P/E ratio, ROE, and D/E ratio and what these different ratios actually mean.`,
		],
		vocabList: [
			{
				word: `Stock`,
				definition: `A share of a company which is sold to investors and represents ownership in the underlying company.`,
			},
			{
				word: `Revenue`,
				definition: `Income generated from selling goods and services. These returns are from normal business operations so it does not include windfall gains or tax credits.`,
			},
			{
				word: `Earnings`,
				definition: `Returns from a company’s activities after expenses have been subtracted. This may or may not include tax expense depending on the context.`,
			},
			
			{
				word: `Dividends`,
				definition: `A portion of a company's earnings distributed to its shareholders, typically paid in cash, but can also be in the form of additional shares of stock.`,
			},
			{
				word: `Dividend Yield`,
				definition: `Dividend Yield is a financial ratio that shows how much a company returns in dividends each year relative to its share price.`,
			},
			{
				word: `Blue Chip Stocks`,
				definition: `Blue Chip stocks are well- established, large companies with a history of stable earnings. These companies often pay regular and growing dividends, providing a steady stream of income.`,
			},
			{
				word: `Growth Stocks`,
				definition: `Growth stocks are shares in high-growth companies, which typically reinvest their profits back into their business, rather than pay dividends. `,
			},
			{
				word: `Penny Stocks`,
				definition: `Penny stocks are very low-priced shares of small, often speculative companies. These stocks rarely pay dividends, as these companies need to reinvest any profits to fuel growth.`,
			},
			{
				word: `Price-to-Earnings (PE) Ratio`,
				definition: `A ratio used to value companies which measures current share price against per share earnings to help determine if a company is over or undervalued.`,
			},
			{
				word: `Return On Equity (ROE)`,
				definition: `This percentage is calculated by dividing the shareholder's equity held in a company by the company's net income for the past year, helping providing an indicator of financial performance and profitability.`,
			},
		],
	},
	{
		id: 6,
		slug: 'top-investor-strategies',
		moduleTitle: 'Top Investor Strategies',
		numberOfSlides: 15,
		numberOfActivities: 4,
		numberOfArticles: 6,
		gradeLevels: '7th - 12th',
		line1: 'In this module, we dive into the strategies of the world’s great investors, including Warren Buffet, John Rogers, Sir John Templeton, and many more!',
		line2: 'By studying some of Wall Streets top investors, we explore real-world examples to draw our own conclusions about how to effectively manage an investment portfolio.',
		length: '1-3 Hours',
		graphic: `/images/ModuleCovers/M6.jpg`,
		guidingQuestions: [
			`Who are some famous investors and what can they teach us?`,
			`What do good investment strategies have in common?`,
			`Is there a way to time the market and buy stocks only at a discount?`,
			`What lessons from Warren Buffet should I apply to my own investing practices?`,
			`Are there any investing habits that every famous investor avoids?`,
			`What does it mean to be a contrarian, value, or growth investor?`,
		],
		enduringUnderstandings: [
			`The value of buy-and-hold investing and why is it important to think about the long-term investing.`,
			`Timing the market a bad idea versus the long-term benefits of time in the market with dollar-cost averaging.`,
			`The world’s greatest investors started with a lot less money, but they grew it exponentially with compound growth.`,
			`Be confident enough in any stock you choose to stick with it in case of a decline.`,
			`Stick with industries you know to find great investments. when the price is right.`,
		],
		vocabList: [
			{
				word: `Assets Under Management (AUM)`,
				definition: `AUM refers to the total market value of all assets managed by an investment firm or advisor. This figure can include investments such as stocks, bonds, cash balances, mutual funds and other securities. Typically AUM values are reported quarterly as part of a financial statement issued by an investment firm or advisor.`,
			},
			{
				word: `Growth Investing`,
				definition: `Growth investing is an investment strategy where investors focus on stocks of companies that are expected to experience above-average growth. This type of investing is typically focused on the long-term and involves buying stocks in companies with the potential for future growth and appreciation in value. Examples of growth stocks include technology companies, healthcare providers, biotechnology firms, and consumer discretionary goods.`,
			},
			{
				word: `Value Investing`,
				definition: `Value investing is a strategy where investors focus on buying companies that they believe are undervalued by the market and have the potential to increase in value over time. This strategy typically seeks to identify companies that are undervalued relative to their peers or their intrinsic value. Value investors use fundamental analysis to evaluate a company’s financials, management team, competitive position within their industry, and other factors to determine whether a company is undervalued or not.`,
			},
			{
				word: `Dividends`,
				definition: `Dividends are payments made by corporations to their shareholders out of profits earned during fiscal quarters or fiscal years depending on the board’s decision. These payments generally represent a portion of profits which have been retained by the corporation instead of being reinvested in operations or passed onto executives as bonuses/incentives/salaries etc.. Dividend payments may be made either in cash or stock form depending on corporate policy and shareholders’ preference. The dividend yield is a ratio calculated by dividing annual dividends per share (DPS) by the current stock price per share (SP).`,
			},
			{
				word: `Active Management`,
				definition: `This represents an investment strategy whereby asset managers are routinely placing trades and adjusting their portfolio holdings as based on current market conditions.`,
			},
			{
				word: `Passive Management`,
				definition: `Passive management is an investment strategy whereby asset managers do not actively pick individual securities but rather invest according to predetermined criteria such as benchmarks like indices or sectors rather than picking individual securities. In addition passive managers generally seek low costs through minimal trading activity leading to efficient portfolio construction avoiding excess trading costs as well as taxes.`,
			},
			{
				word: `Contrarian Investing`,
				definition: `This style of investing involves taking an opposite stance to the prevailing market trend. It involves buying stocks or other assets when their prices are low or shorting them when their prices are high. Contrarian investors believe that by going against the majority, they can take advantage of market imbalances and benefit from price movements in either direction.`,
			},
			{
				word: `Emerging Markets`,
				definition: `Emerging markets refer to countries that have recently begun to experience rapid economic growth, such as those located in Asia and South America beginning in the early 2000's. These markets are attractive investment destinations due to their potential for explosive growth and often provide opportunities not found in developed markets. They can be riskier than investing in more established markets, but the potential rewards may be worth the additional risk taken. Investors should research these markets carefully before deciding whether to invest in them.`,
			},
		],
	},
	{
		id: 7,
		slug: 'diversification-and-risk',
		moduleTitle: 'Diversification & Risk',
		numberOfSlides: 18,
		numberOfActivities: 7,
		numberOfArticles: 6,
		gradeLevels: '7th - 12th',
		line1: 'Imagine if you had invested all of your money into restaurants before the Covid pandemic - not pretty. When done correctly, diversification can reduce the risk portfolio faces and help capture returns from the broader market.',
		line2: 'Risk is unavoidable when you investing, but it declines over time, and that’s where the real money can be made!',
		length: '3-5 Hours',
		graphic: `/images/ModuleCovers/M7.jpg`,
		guidingQuestions: [
			`What are the different types of risk?`,
			`How can risk be mitigated through time and/or diversification?`,
			`What does diversification mean when it comes to investing?`,
			`What does the acronym FETCH stand for?`,
			`What does dollar-averaging mean?`,
			`What other investments are there other than the stock market?`,
			`How should you allocate your investments?`,
		],
		enduringUnderstandings: [
			`Everyone has a different risk profile that they are comfortable with.`,
			`It is important to figure out how much risk you personally are comfortable with before investing.`,
			`Investing in different companies and asset classes can reduce a lot of risks but never eliminate all of them.`,
			`FETCH stands for Finance, Energy, Technology, Communications, and Healthcare, representing 5 core industries.`,
			`Deciding on an investment strategy and researching before investing can save you a lot of trouble – and money - in the future.`,
		],
		vocabList: [
			{
				word: `Diversification`,
				definition: `The process of spreading investments across multiple asset classes and markets to reduce the risk of loss. It helps to reduce the volatility of portfolio returns, as well as the potential for high losses due to over-concentration in any one particular asset class or market.`,
			},
			{
				word: `Risk Tolerance`,
				definition: `An individual’s capacity for enduring the fluctuations and losses that accompany investing in different asset classes and markets. Generally speaking, a higher risk tolerance means that an investor is more willing to accept greater risks for greater potential rewards.`,
			},
			{
				word: `Risk/Reward Ratio`,
				definition: `A measure of how much reward comes with risk. This ratio of potential return versus risk is an important metric used by investors when determining which investments to make.`,
			},
			{
				word: `Leverage`,
				definition: `The ability to control more money in investments than you have in capital; this can increase both profits and losses depending on how it is used and how successful an investment strategy becomes.`,
			},
			{
				word: `Margin Trading`,
				definition: `Taking out loans from brokers to fund investments; this increases leverage and can result in greater profits, but also greater losses if markets turn against the investor.`,
			},
			{
				word: `Hedge Funds`,
				definition: `Investment funds that use alternative strategies such as leverage, futures, and options to make money from different kinds of investments including commodities trading.`,
			},
			{
				word: `Hedging`,
				definition: `Making sure an investment is protected from any negative changes in market movements by using derivative instruments such as futures and options contracts; this helps mitigate risk from investments and reduce losses during times of uncertainty in markets.`,
			},
			{
				word: `Asset Correlation`,
				definition: `A measure of how two assets move in relation to each other; if their correlation is high then they will generally move together; if their correlation is low then they will generally move independently or even in opposite directions. Asset correlation plays an important role in portfolio diversification as it allows investors to identify assets that may be uncorrelated or even negatively correlated so as to reduce overall portfolio volatility levels.`,
			},
			{
				word: `Portfolio Allocation`,
				definition: `The process of selecting and allocating funds across different asset classes and markets in order to achieve a desired level of diversification and risk/reward ratio. Portfolio allocation can be done manually or with automated tools that take into account your personal goals, preferences, and risk tolerance.`,
			},
			{
				word: `Rebalancing`,
				definition: `The process of periodically re-allocating funds from certain assets or markets where performance has been strong back into those where performance has been weak so as to maintain desired levels of diversification and risk/return ratios across an entire portfolio on an ongoing basis.`,
			},
			{
				word: `Modern Portfolio Theory (MPT)`,
				definition: `A theory proposed by economist Harry Markowitz that suggests diversifying investments across different asset classes can maximize returns while minimizing associated risks. MPT also postulates that investors can use mathematical formulas to determine optimal portfolios based on expected rates of return, volatility levels, correlation between assets, and other factors.`,
			},
			{
				word: `Futures Contract`,
				definition: `An agreement to buy or sell a specified amount of a commodity at a predetermined price on a future date; this contract is typically traded on the commodities market.`,
			},
			{
				word: `Commodity Market`,
				definition: `A marketplace where commodities (such as crude oil, gold, or silver) are traded for cash or other commodities.`,
			},
			{
				word: `Basis Trading`,
				definition: `Buying and selling different types of commodities based on their differences between spot prices and futures prices; basis traders seek to take advantage of price discrepancies between different contracts on the same underlying asset to make profits without taking ownership of the asset itself.`,
			},
			{
				word: `Options Contracts`,
				definition: `Derivative financial instruments that give buyers the right (but not the obligation) to buy or sell an underlying asset at a predetermined price before expiration date; these are typically used by sophisticated investors seeking to maximize profits while mitigating risk exposure over time periods with high volatility such as near expiry dates for futures contracts when prices can change quickly within short periods of time due to news events or shifts in demand/supply dynamics affecting those assets over short-term windows like weeks or days instead of months or years (which is more typical for long-term investing).`,
			},
		],
	},
	{
		id: 8,
		slug: 'insurance-and-retirement',
		moduleTitle: 'Insurance & Retirement',
		numberOfSlides: 25,
		numberOfActivities: 4,
		numberOfArticles: 13,
		gradeLevels: '8th - 12th',
		line1: 'Insurance is a great way to financially protect yourself from some of life’s biggest hardships so that when something bad happens, you don’t need to worry about your personal finances.',
		line2: 'Be it your health, car, or home, insurance allows you to reduce your risk in life, by ensuring that you have financial security in case anything unexpected happens.',
		length: '2-4 Hours',
		graphic: `/images/ModuleCovers/M8.jpg`,
		guidingQuestions: [
			`What is insurance and what are the different types?`,
			`How does car insurance work?`,
			`How is insurance considered an asset and how can it be used as a means of diversification?`,
			`What is the difference between term life insurance & permanent life insurance?`,
			`What are employee-benefits plans are why are they important for retirement?`,
		],
		enduringUnderstandings: [
			`Insurance is a contract that provides an individual or entity with financial protection or reimbursement against losses on property, assets or themselves.`,
			`There are an array of insurance products available to individuals and entities.`,
			`Term life insurance only lasts for the term of the policy, whereas permanent life insurance accrues value.`,
			`Employee-benefits are a tax-efficient way to protect what you own and prepare for retirement.`,
		],
		vocabList: [
			{
				word: `Insruance`,
				definition: `Insurance is product which you can purchase to protect against unforeseen events such as a car accident, fire, or health issue. Typically, in order to maintain insurance, it requires paying monthly or annual premium payments to an insurance company.`,
			},
			{
				word: `Life Insurance`,
				definition: `
				A type of insurance in which the policyholder pays a fixed premium over time, and receives a predetermined amount of money upon death or proof of terminal illness.`,
			},
			{
				word: `Health Insurance`,
				definition: `A type of insurance that covers the costs of medical care for health-related expenses.`,
			},
			{
				word: `Disability Insurance`,
				definition: `A type of insurance that provides income to a person who cannot work due to illness or injury.`,
			},
			{
				word: `Property Insurance`,
				definition: `A type of insurance that compensates the insured for losses to their personal property from fire, theft, vandalism, water damage, and other perils.`,
			},
			{
				word: `Liability Insurance`,
				definition: `A type of insurance that protects individuals and businesses from claims arising out of injury or damage caused by their negligent activities or omissions.`,
			},
			{
				word: `Professional Liability (E&O) Insurance`,
				definition: `A type of liability insurance specifically designed to protect professionals such as attorneys, accountants, real estate agents, and others against claims made by clients alleging negligence or malpractice while performing their services or duties.`,
			},
			{
				word: `Workers' Compensation Insurance`,
				definition: `A mandatory form of business insurance required by law in most states that provides compensation to workers injured on the job regardless of fault in exchange for relinquishing their right to sue their employers for damages related to the injury caused on the job.`,
			},
			{
				word: `Auto Insurance`,
				definition: `An insurance policy designed to cover losses resulting from traffic accidents involving motor vehicles. It also covers legal liability resulting from those accidents as well as damages caused by uninsured motorists.`,
			},
			{
				word: `Long-Term Care Insurance`,
				definition: `A policy designed to cover costs associated with long-term care services such as nursing homes and assisted living facilities for individuals unable to provide this care for themselves due to physical disability or cognitive impairment such as Alzheimer’s disease or dementia.`,
			},
			{
				word: `Annuities`,
				definition: `A financial product typically sold by life insurers that pays out one lump sum up front or periodic payments over time based on an interest rate set at purchase while also providing certain tax benefits such as deferring gains until withdrawn at retirement age.`,
			},
			{
				word: `Retirement Planning`,
				definition: `This is the process of saving and investing to create financial security for retirement. It involves estimating your life expectancy and calculating the amount you will need to have saved up in order to fund your desired lifestyle into retirement.`,
			},
			{
				word: `401(k) Plan`,
				definition: `This is a type of employer-sponsored retirement plan that allows employees to save pre-tax dollars into a tax-advantaged account, usually with an employer match up to a certain percentage of contributions each year.`,
			},
			{
				word: `Roth IRA`,
				definition: `This is an individual retirement account (IRA) that offers tax-free growth on investments and allows tax-free withdrawals after age 59 1⁄2, as long as certain conditions are met. Contributions are made with after-tax income, but qualified distributions are not taxed at all and can be taken at any time without penalty.`,
			},
			{
				word: `Social Security Benefits`,
				definition: `This is the federal insurance program created by Congress in 1935 which provides monthly benefits to retired workers and their dependents, survivors, disabled workers, and more depending on their work history and earnings over time.`,
			},
			{
				word: `Defined Benefit Plans`,
				definition: `These plans are offered by employers where employers promise employees future pension benefits based on fixed formulas that include employee age, salary history and years worked at the company when they retire from service with the company rather than focusing on current contributions made by either the employee or employer towards funding.`,
			},
		],
	},
	{
		id: 9,
		slug: 'taxes-and-income',
		moduleTitle: 'Taxes & Income',
		numberOfSlides: 21,
		numberOfActivities: 5,
		numberOfArticles: 8,
		gradeLevels: '9th -12th',
		line1: 'Everyone tells you that saving is critical for the future and that investing is a way to build true wealth, but what about investing in yourself?',
		line2: 'Education is an asset because it helps increase your potential earnings throughout your life-time! This module explores how we can finance things like college to make them more accessible and elevate your future career earnings!',
		length: '2-4 Hours',
		graphic: `/images/ModuleCovers/M9.jpg`,
		guidingQuestions: [
			`What happens when I get my first job and receive my first paycheck?`,
			`Are there different types of income and do they have different tax rates?`,
			`What are different ways to lower my taxable income?`,
			`What happens if I don’t pay my taxes?`,
			`How does the economy impact your career & ability to switch jobs?`,
			`What is opportunity cost and how can it impact the way you make decisions?`,
			`Is college worth the investment to earn more?`,
		],
		enduringUnderstandings: [
			`Taxes are an inescapable part of earning income, and it’s important to pay them or else you can face fines and considerable jail time.`,
			`Capital gains taxes are considerably lower than income taxes.`,
			`Avoiding taxes is illegal and you can go to jail for tax evasion if you do not pay up!`,
			`Investing in yourself by developing new skills and obtaining higher education pays off.`,
			`Sometimes switching jobs can be the best choice for your career.`,
			`Donating to charity is just one way you can lower your taxable income.`,
		],
		vocabList: [
			{
				word: `Investing`,
				definition: `The act of allocating money or capital to an asset or endeavor with the expectation of generating a return on investment (ROI). Investing aims for long-term wealth accumulation.`,
			},
			{
				word: `Savings`,
				definition: `Money set aside for future use, often stored in a low-risk, easily accessible account like a savings account. Unlike investing, savings don't typically generate significant returns.`,
			},
			{
				word: `Income Tax`,
				definition: `Taxes levied by the government on the money earned by individuals or businesses. Income tax is a primary source of revenue for the government.`,
			},
			{
				word: `Capital Gains Tax`,
				definition: `Taxes on the profit made from selling an asset like stocks, real estate, or other investments. Capital gains tax rates are usually lower than income tax rates.`,
			},
			{
				word: `Gross Income`,
				definition: `The total amount of money someone earns before any taxes or deductions are applied. It includes wages, bonuses, dividends, and other sources of income.`,
			},
			{
				word: `Net Income`,
				definition: `The amount of money left after all taxes and expenses have been deducted from gross income. Net income reflects the true earnings that can be spent or saved.`,
			},
			{
				word: `Tax Evasion`,
				definition: `The illegal act of deliberately not paying taxes owed to the government. Tax evasion can result in hefty fines and imprisonment.`,
			},
			{
				word: `Tax Basis`,
				definition: `The initial value of an asset for tax purposes, usually the purchase price, which is used to calculate capital gains or losses.`,
			},
			{
				word: `Realized Gains`,
				definition: `Profits from investments that have been sold. Realized gains are subject to capital gains tax.`,
			},
			{
				word: `Unrealized Gains`,
				definition: `Potential profits from investments that are still owned. Unrealized gains are not taxed until the asset is sold.`,
			},
			{
				word: `Payroll Deductions`,
				definition: `Amounts subtracted from an employee's paycheck for various reasons, including taxes, insurance, and retirement contributions. Understanding payroll deductions helps in budgeting.`,
			},
			{
				word: `Chartiable Donation`,
				definition: `Giving money, goods, or time to a non-profit organization. Charitable donations may be tax-deductible, reducing one's taxable income.`,
			},
		],
	},
	{
		id: 10,
		slug: 'etfs-and-mutual-funds',
		moduleTitle: 'ETFs & Mutual Funds',
		numberOfSlides: 18,
		numberOfActivities: 3,
		numberOfArticles: 5,
		gradeLevels: '6th - 12th',
		line1: 'This module explores the importance of ETFs and Mutual Funds in achieving a diversified investment portfolio.',
		line2: 'We discuss examples of different ETFs, explore the advantageous of low-expense ratio mutual funds, and examine why investors may prefer a passive investment strategy.',
		length: '1-3 Hours',
		graphic: `/images/ModuleCovers/M10.jpg`,
		guidingQuestions: [
			`What are ETFs and Mutual Funds?`,
			`How can ETFs and Mutual Funds help build a more diversified portfolio with exposure to hundreds of companies?`,
			`Are there any special tax treatments for ETFs or Mutual Fund which we should appreciate before investing?`,
			`What are Capital Markets and Why are they important?`,
			`How do investment banks help launch an ETF or Mutual Fund?`,
		],
		enduringUnderstandings: [
			`ETFs & Mutual Funds provide a way for investors to invest in a basket of stocks or other assets at a much lower cost than purchasing all of the assets independently.`,
			`ETFs provide investors with the opportunity to own shares in an asset whose performance is designed to mirror a stock index or industry.`,
			`Mutual funds are more actively managed than ETFs, so they typically have higher fees.`,
			`Mutual funds also typically make distributions to investors which may provide an additional source of income for some investors.`,
		],
		vocabList: [
			{
				word: `Exchange Trade Funds (ETFs)`,
				definition: `These are professional funds that can be bought and sold on a stock exchange, similar to stocks. ETFs track an index, such as the S&P 500 or the Nasdaq 100, and their performance is based on the performance of those indexes. ETFs are ideal for investors who want diversification within their portfolio at a low cost.`,
			},
			{
				word: `Mutual Funds`,
				definition: `These types of funds pool money from many investors and invest it in a variety of different securities, such as stocks, bonds, options and commodities. Mutual funds spread out risk across numerous investments, providing investors with greater diversification than they could achieve by investing in only one security.`,
			},
			{
				word: `Risk/Return Ratio`,
				definition: `The risk-return ratio is used to measure an investment’s potential return relative to its associated risk level. Generally speaking, higher risk investments have the potential for higher returns but also carry more inherent risks than lower risk investments do; therefore it is important for investors to consider both elements when evaluating any given investment opportunity prior to making any decisions about which ones may be appropriate for them.`,
			},
			{
				word: `Expense Ratio`,
				definition: `This is an annual fee associated with managing a mutual fund or ETF; it covers administrative costs as well as other expenses related to managing the fund itself (such as research costs). Before investing in any fund it is important for investors to evaluate its expense ratio since this will affect how much total return they can expect from their investment over time.`,
			},
			{
				word: `Index Funds`,
				definition: `These funds attempt to track a specific stock market index by purchasing all or most of the stocks represented in that index. For example, a fund that tracks the S&P 500 will purchase all or most of the 500 stocks represented in that index in order to match its performance as closely as possible.`,
			},
			{
				word: `Active Management`,
				definition: `An approach where investment professionals make active decisions about the types and amounts of investments in order to outperform the market or beat certain benchmarks.`,
			},
			{
				word: `Passive Management`,
				definition: `Passive management is an investment strategy whereby asset managers do not actively pick individual securities but rather invest according to predetermined criteria such as benchmarks like indices or sectors rather than picking individual securities. In addition passive managers generally seek low costs through minimal trading activity leading to efficient portfolio construction avoiding excess trading costs as well as taxes.`,
			},
			{
				word: `Leverage`,
				definition: `Leverage is a way for investors to increase their return on investment by borrowing money from a lender or broker. This borrowed money is used to purchase securities with the hope that if the value of those securities increase, they can repay the loan with profits earned.`,
			},
		],
	},
	{
		id: 11,
		slug: 'esg-social-impact-investing',
		moduleTitle: 'ESG & Social Impact',
		numberOfSlides: 19,
		numberOfActivities: 2,
		numberOfArticles: 4,
		gradeLevels: '7th - 12th',
		line1: 'This module helps students digest ESG and impact investing, explores the different types of ESG investing and show students examples of successful ESG firms.',
		line2: 'We teach social investing through stories, and look at the history of ESG to gain historical perspective on why it has gained prominence for investors.',
		length: '~2 Hours',
		graphic: `/images/ModuleCovers/M11.jpg`,
		guidingQuestions: [
			`What is ESG?`,
			`How has ESG grown more popular in light of greater environmental and social awareness?`,
			`How does ESG differ from socially responsible investing norms?`,
			`What are the different ways to invest in ESG companies?`,
			`Are there drawbacks to the rise in ESG investing?`,
			`How can shareholder activism shape the direction of a company?`,
		],
		enduringUnderstandings: [
			`ESG represents a set of qualitative benchmarks to evaluate a company based on environmental impact, social impact, and corporate governance.`,
			`Specific company documents and third party ratings can help you evaluate ESG performance.`,
			`Shareholder activism is crucial and impactful.`,
			`Companies that adopt ESG policies are well positioned to pivot to changing environmental guidelines and consumer behavior trends.`,
		],
		vocabList: [
			{
				word: `Environmental, Social, and Governance (ESG)`,
				definition: `This term is used to describe standards of corporate responsibility. It refers to how a company manages the environmental, social and governance impacts of its operations and activities, with the aim of creating positive change.`,
			},
			{
				word: `Environmental Impact`,
				definition: `This refers to the effects of a company's operations on the environment and its sustainability practices. Examples of environmental impact include carbon emissions, water usage, waste management, and resource efficiency.`,
			},
			{
				word: `Social Responsibility`,
				definition: `This term refers to a company's commitment to providing goods or services for social benefit. It includes policies such as employee rights, health and safety regulations, diversity initiatives, and ethical labor practices.`,
			},
			{
				word: `Governance`,
				definition: `This is an umbrella term that encompasses corporate ethics and internal governance processes to ensure that leadership acts in the best interests of shareholders. It includes measures such as executive pay ratios, board independence, shareholder rights, financial transparency, risk management policies and code of conduct guidelines.`,
			},
			{
				word: `Sustainability`,
				definition: `This term is used to describe business practices that are focused on long-term environmental protection and economic growth. It involves developing strategies to reduce waste and maximize resource efficiency while promoting renewable energy sources and green technologies.`,
			},
			{
				word: `Human Rights`,
				definition: `Companies must adhere to basic human rights standards when conducting business activities in order to protect employees from exploitation or abuse. This includes ensuring fair wages, safe working conditions, freedom from discrimination or harassment, collective bargaining rights and access to adequate healthcare benefits.`,
			},
			{
				word: `Climate Change`,
				definition: `The effects of climate change have become more pronounced in recent years due to increased global temperatures. Companies must develop plans for reducing their emissions of greenhouse gasses and transitioning towards renewable energy sources in order to mitigate the impacts of climate change on our planet.`,
			},
			{
				word: `Carbon Footprint`,
				definition: `Carbon footprint is a measure of the amount of carbon dioxide that humans generate through their daily activities such as transportation and consumption. It takes into account emissions generated not only directly by an individual or company but also indirectly through suppliers and other sources.`,
			},
			{
				word: `Energy Efficiency`,
				definition: `Energy efficiency measures how effectively energy is used in different activities and processes. Companies can implement energy-efficient practices to reduce their environmental impact while achieving savings in energy costs.`,
			},
			{
				word: `Renewable Energy`,
				definition: `Renewable energy sources are those which are replenished naturally over time, including wind power, solar energy, hydroelectricity, geothermal energy and biomass fuels. These forms of energy generation help reduce dependence on fossil fuels and contribute to reducing global emissions of greenhouse gasses.`,
			},
			{
				word: `Sustainable Investment`,
				definition: `Sustainable investment takes into account environmental, social and governance factors when making decisions about where to invest capital – this helps ensure investments are made with consideration for long-term sustainability goals such as climate change mitigation and transitioning towards a low carbon economy.`,
			},
			{
				word: `Impact Investing`,
				definition: `Impact investing seeks positive outcomes from investments that have both financial returns and measurable social or environmental benefits in areas such as clean energy or climate action.`,
			},
			{
				word: `Corporate Social Responsibility (CSR)`,
				definition: `Corporate social responsibility is an ongoing commitment by companies to operate ethically, respect human rights and be mindful of the environment when conducting business activities around the world. CSR includes measures such as reducing waste, promoting diversity in workplaces or contributing to local communities by supporting initiatives that tackle poverty or educational programmes for children from disadvantaged backgrounds.`,
			},
			{
				word: `Responsible Supply Chain Management (RSCM)`,
				definition: `Responsible supply chain management means having due diligence in the sourcing process so that goods sourced from suppliers meet certain ethical standards set out by the company. This can include mapping out a supply chain’s links back to its original source (known as traceability) as well as examining labor practices employed by suppliers at each stage of production.`,
			},
			{
				word: `Community Engagement`,
				definition: `Community engagement involves actively building relationships between businesses and local stakeholders which could include customers, employees, non-governmental organizations or government bodies amongst others with the aim of understanding local needs better; responding effectively; addressing issues affecting local populations; developing trust; creating shared value; demonstrating transparency; providing access to jobs; and supporting education initiatives.`,
			},
		],
	},
	{
		id: 12,
		slug: 'careers-in-finance',
		moduleTitle: 'Careers In Finance',
		numberOfSlides: 25,
		numberOfActivities: 2,
		numberOfArticles: 4,
		gradeLevels: '9th -12th',
		line1: 'This module helps students examine various careers in financial services and appreciate their role within the industry.',
		line2: 'We discuss potential career paths and relevant skills to thrive in interviews, internships, and students’ future careers, contextualized with the foundational knowledge in previous modules.',
		length: '~2 Hourse',
		graphic: `/images/ModuleCovers/M12.jpg`,
		guidingQuestions: [
			`What are the career paths available within the financial services industry?`,
			`What are the key skills and educational qualifications required to enter and excel in different financial services careers?`,
			`What are the long-term growth prospects for various financial services careers?`,
			`How do specific certifications impact career prospects and earning potential within the financial services industry?`,
			`How have technological advancements transformed financial services careers in recent years?`,
		],
		enduringUnderstandings: [
			`How to leverage networking and mentorship opportunities to advance your career and navigate complex workplace dynamics.`,
			`There are key differences between financial services careers in the private and public sector.`,
			`The varying roles and responsibilities of financial services professionals across different industries and positions.`,
			`How to overcome common challenges and obstacles faced by individuals pursuing financial services careers.`,
		],
		vocabList: [
			{
				word: `Stock Market`,
				definition: `A place for investors to buy & sell publicly traded companies’ stock. This list includes the NASDAQ, S&P 500, & numerous indexes from around the world.`,
			},
			{
				word: `Financial Analyst`,
				definition: `A professional who evaluates and interprets financial data to guide decision-making, such as investment decisions and cost-cutting strategies.`,
			},
			{
				word: `Investment Banking`,
				definition: `A form of banking in which bankers offer strategic advice on mergers and acquisitions, capital markets transactions, debt financings and restructuring activities.`,
			},
			{
				word: `Portfolio Management`,
				definition: `The process of selecting investments in order to achieve a specific goal or set of goals. It involves diversification, asset allocation and rebalancing decisions.`,
			},
			{
				word: `Financial Modeling`,
				definition: `The process of creating logical representations of a company’s financial performance, often for use in forecasting future cash flow or evaluating capital structures or investment options.`,
			},
			{
				word: `Derivatives Trading`,
				definition: `An activity that involves speculating on the value of underlying assets such as stocks, bonds, commodities, currencies and derivatives themselves.`,
			},
			{
				word: `Data Analysis`,
				definition: `The process of collecting, organizing and interpreting data in order to draw conclusions about trends and patterns related to a particular industry or group of investments.`,
			},
			{
				word: `Equity Research`,
				definition: `An analytical research process used by investors to assess the prospects of individual securities within an equity portfolio through analysis of public information such as financial statements, economic indicators, news reports and analyst estimates.`,
			},
			{
				word: `Hedge Fund Manager`,
				definition: `Professionals who purchase assets with the intention of making large profits by taking advantage of market movements and leveraging their investments into larger returns than would be possible with normal trading activities or by taking long and short positions.`,
			},
			{
				word: `Risk Manager`,
				definition: `Professionals responsible for assessing risk exposure within an organization's operations and mitigating that risk through various strategies such as insurance policies, hedging, and diversification strategies.`,
			},
			{
				word: `Insurance Underwriter`,
				definition: `An individual or institution responsible for evaluating applications for insurance policies based on factors such as age, health history, lifestyle choices, credit score etc., in order to determine the amount of coverage offered by insurance companies at what price points.`,
			},
		],
	},
	{
		id: 13,
		slug: 'financial-algebra',
		moduleTitle: 'Financial Algebra',
		numberOfSlides: 20,
		numberOfActivities: 2,
		numberOfArticles: 0,
		gradeLevels: '6th - 10th',
		line1: 'This module provides a solid foundation in mathematical concepts and skills necessary for understanding and making financial decisions.',
		line2: 'Topics covered in this module include interest rates, loans, compound growth, stocks and bonds, and net present value. We explore linear and exponential functions and work through case studies that apply mathematical concepts to practical life scenarios.',
		length: '2-5 Hours',
		graphic: `/images/ModuleCovers/M13.jpg`,
		guidingQuestions: [
			`How do interest rates affect the cost of loans and investments?`,
			`How can we calculate the interest paid or earned on a financial product?`,
			`How can we calculate the net present value of different financial decisions, and how can we use this information to make informed choices?`,
			`How can we use algebraic equations and functions to model and solve financial problems in the real world?`,
			`How can we apply the principles of financial algebra to real-world scenarios?`,
		],
		enduringUnderstandings: [
			`Fractions and percentages are interchangeable, but incredibly useful when working with financial decisions.`,
			`Your income is subject to state and federal taxes, and these taxes can be calculated by multiplying your income with percentage tax rates.`,
			`Interest rates also play a crucial role in the cost of loans and investments.`,
			`Financial algebra can be applied to real-world scenarios, such as buying a house, starting a business, or planning for retirement.`,
		],
		vocabList: [
			{
				word: `Chart Axes`,
				definition: `Axes are the lines that create a grid for graphing functions or data. The horizontal axis is called the x-axis, the vertical axis is called the y-axis, and they intersect at a point called the origin.`,
			},
			{
				word: `Linear Equation`,
				definition: `A linear equation is an equation of a straight line in the form y = mx + b, where m is the slope of the line and b is the y-intercept (the point where the line crosses the y-axis). Linear equations can be graphed on a coordinate plane.`,
			},
			{
				word: `Amortization`,
				definition: `The process of paying off a debt over time, with regular payments that include both principal and interest.`,
			},
			{
				word: `Time Value of Money`,
				definition: `The concept that money today is worth more than the same amount of money in the future, due to the potential for investment returns.`,
			},
			{
				word: `Present Value`,
				definition: `The current value of a future sum of money, calculated by discounting it to its present value.`,
			},
			{
				word: `Future Value`,
				definition: `The value of an investment at a future point in time, calculated by compounding the original principal and any interest earned.`,
			},
			{
				word: `Net Present Value`,
				definition: `The difference between the present value of all future cash inflows and the present value of all future cash outflows, used to evaluate the profitability of an investment.`,
			},
			{
				word: `Internal Rate of Return (IRR)`,
				definition: `The interest rate at which the net present value of an investment equals zero, used to evaluate the potential return on an investment.`,
			},
			{
				word: `Interest`,
				definition: `The cost of borrowing money, usually expressed as a percentage of the amount borrowed.`,
			},
			{
				word: `Simple Interest`,
				definition: `Interest that is calculated only on the principal amount.`,
			},
			{
				word: `Compound Interest`,
				definition: `Interest that is calculated on both the principal and any accumulated interest.`,
			},
		],
	},
	{
		id: 14,
		slug: 'financial-equations',
		moduleTitle: 'Financial Equations',
		numberOfSlides: 19,
		numberOfActivities: 8,
		numberOfArticles: 0,
		gradeLevels: '7th - 12th',
		line1: 'This module dives deeper into financial algebra by illustrating how we can graphically represent financial functions. We explore linear equations, calculating growth rates with slope, and applying these concepts to real-world problems.',
		line2: 'We then examine the concepts of compound growth and the time value of money in more detail and determine how to calculate compound interest and the present value of future cash flows.',
		length: '2-5 Hours',
		graphic: `/images/ModuleCovers/M14.jpg`,
		guidingQuestions: [
			`What is the Cartesian plane and how can it help us model equations and functions?`,
			`How can functions be used to determine important financial lines?`,
			`Is there a standardized format for linear equations, and how can these be generalized to real-world problem.`,
			`What are key ways to use and solve linear equations?`,
			`What are piecewise functions and how do they relate to the real world like taxes?`,
			`What is the value of compound growth?`,
			`What is the time value of money and how does it relate to future value?`,
		],
		enduringUnderstandings: [
			`Understanding financial equations can help make informed decisions about finances, budgeting, saving, and investing.`,
			`Linear equations can be used to model financial situations where the relationship between two variables is proportional, such as income and expenses.`,
			`Piecewise functions model financial situations where the relationship between two variables changes at certain points, such as tax brackets or interest rates.`,
			`Compound growth can model financial situations where the value of an investment or loan changes over time exponentially.`,
		],
		vocabList: [
			{
				word: `Cartesian Plane`,
				definition: `A two-dimensional plane formed by the intersection of a horizontal x-axis and a vertical y-axis.`,
			},
			{
				word: `Coordinate Pair`,
				definition: `A pair of numbers that identifies the position of a point on the Cartesian plane, usually written as (x, y).`,
			},
			{
				word: `Domain`,
				definition: `The set of all possible input values of a function.`,
			},
			{
				word: `Range`,
				definition: `The set of all possible output values of a function.`,
			},
			{
				word: `Function`,
				definition: `A relationship between two sets of numbers, where each input value (domain) corresponds to a unique output value (range).`,
			},
			{
				word: `Y-Intercept Form`,
				definition: `The linear equation of a line written in the form y = mx + b, where m is the slope of the line and b is the y-intercept.`,
			},
			{
				word: `Slope Of A Line`,
				definition: `A measure of the steepness of a line, calculated as the change in y divided by the change in x between two points on the line.`,
			},
			{
				word: `Parallel`,
				definition: `Two lines on the Cartesian plane that have the same slope and never intersect.`,
			},
			{
				word: `Perpendicular`,
				definition: `Two lines on the Cartesian plane that intersect at a right angle and have slopes that are negative reciprocals of each other.`,
			},
			{
				word: `Continuous Function`,
				definition: `A function that is defined and has a value for all points in its domain, with no gaps or jumps.`,
			},
			{
				word: `Discontinuous Function`,
				definition: `A function that has one or more gaps in its domain, where it is undefined or has no value.`,
			},
			{
				word: `Piecewise Function`,
				definition: `A function that is defined differently on different intervals or "pieces" of its domain.`,
			},
			{
				word: `Net Present Value`,
				definition: `A financial calculation that measures the present value of future cash flows, taking into account the time value of money.`,
			},
			{
				word: `Time Value Of Money`,
				definition: `The concept that money today is worth more than the same amount of money in the future, due to the potential to earn interest or invest the money.`,
			},
		],
	},
	{
		id: 15,
		slug: 'paying-for-college',
		moduleTitle: 'Paying For College',
		numberOfSlides: 12,
		numberOfActivities: 1,
		numberOfArticles: 3,
		gradeLevels: '9th -12th',
		line1: 'In this module, we examine the hefty price tag associated with higher education and explore various strategies to manage and pay for college education.',
		line2: 'We dissect different types of scholarships, grants, student loans, and work-study programs. We also explore FAFSA and how to apply for financial aid, so that by the end of this module, students have a clear roadmap for investing in future education.',
		length: '2-3 Hours',
		graphic: `/images/ModuleCovers/M15.jpg`,
		guidingQuestions: [
			`What options are there out there to help pay for college or university?`,
			`How can you leverage grants and scholarships to help reduce the cost of college?`,
			`What is the opportunity cost of going to college and what other options are there?`,
			`What are factors you are looking for in employment? Do they require a college degree or does it make it easier?`,
			`In what ways are you able to get assistance from the government or lending agencies to help with college?`,
		],
		enduringUnderstandings: [
			`Determining the opportunity cost of college and various forms of education and how you can apply opportunity cost to many things in life.`,
			`Job satisfaction is important and determining the income and non-income factors that create satisfaction for you.`,
			`There are multiple helpful resources that can assist in paying for college or provide loans that can help cover the cost.`,
			`The key terms for any student loan document and terms to help understand other loan documents.`,
		],
		vocabList: [
			{
				word: `FAFSA (Free Application for Federal Student Aid)`,
				definition: `This is a form managed by the U.S. Department of Education that students must fill out to be eligible for federal financial aid for college. It serves as the gateway for various forms of financial support including grants, loans, and work-study programs.`,
			},
			{
				word: `Opportunity Cost`,
				definition: `A fundamental economic concept, opportunity cost refers to the loss of potential benefits when one alternative is chosen over another. This can relate to both monetary and non-monetary factors, such as time and satisfaction.`,
			},
			{
				word: `Scholarships`,
				definition: `These are financial awards provided to students based on various criteria, often academic merit or special talent, and do not have to be repaid.`,
			},
			{
				word: `Grants`,
				definition: `Unlike loans, grants are sums of money that are given to students for their education and generally do not have to be repaid. They can be from governmental or private organizations.`,
			},
			{
				word: `Work-Study Programs`,
				definition: `These are federally or institutionally funded programs that allow students to work part-time jobs to help pay for college expenses.`,
			},
			{
				word: `Student Loans`,
				definition: `A sum of money borrowed to pay for higher education, which will need to be paid back with interest over a specified period.`,
			},
			{
				word: `Fixed Interest Rate`,
				definition: `This refers to an interest rate on a loan that remains the same throughout the entire loan period, commonly associated with federal student loans.`,
			},
			{
				word: `Variable Interest Rate`,
				definition: `This is an interest rate that can fluctuate over the duration of a loan based on market conditions, often seen in private student loans.`,
			},
			{
				word: `Repayment Plans`,
				definition: `These are predetermined arrangements between the borrower and the lender detailing how the borrower will repay the loan, including the repayment period and monthly payment amount.`,
			},
			{
				word: `Gig Economy`,
				definition: `This term describes a labor market where short-term, freelance, or contractual work is prevalent, allowing for flexible schedules but often lacking traditional employee benefits.`,
			},
			{
				word: `Non-Income Factors`,
				definition: `These refer to elements other than salary that contribute to job satisfaction, such as work culture, job security, and work-life balance.`,
			},
			{
				word: `The Wage Gap`,
				definition: `The term is used to describe the pay discrepancies that exist between employees doing the same job, often affecting certain groups disproportionately based on gender, race, or other factors.`,
			},
		],
	},
	{
		id: 16,
		slug: 'understanding-shorting-stock',
		moduleTitle: 'Understanding Shorting A Stock',
		numberOfSlides: 14,
		numberOfActivities: 1,
		numberOfArticles: 5,
		gradeLevels: '6th - 12th',
		line1: 'In this module, we explore how shorting a stock allows investors to earn money when a stock’s price declines. Investors short a stock at a certain price with the expectation that the price will fall. This allows them to sell the stock by borrowing it from another investor.',
		line2: 'We cover the basics and also explore the infinite risk, highlighting why shorting is not a safe investing practice for most investors.',
		length: '1-3 Hours',
		graphic: `/images/ModuleCovers/M16.jpg`,
		guidingQuestions: [
			`How do investors choose what to invest in and what happens if the investor believes a stock price will decline?`,
			`What does it mean to short a stock and how can investors leverage shorting as a tool?`,
			`How do investors make money from shorting?`,
			`What are the risks associated with shorting?`,
			`Why is shorting a stock more risky than just owning a stock and hoping the price increases?`,
		],
		enduringUnderstandings: [
			`Shorting can be thought of as the opposite of investing, because an investor will earn a profit if the price per share decreases instead of increasing.`,
			`Shorting occurs on margin, which means investors who short a stock use debt to do so, because they must first borrow the stock.`,
			`For inexperienced investors, shorting can lead to significant losses.`,
			`When shorting a stock, your risk is unlimited because a stock’s price can increase infinitely.`,
		],
		vocabList: [
			{
				word: `Shorting A Stock`,
				definition: `Shorting a stock involves borrowing shares to sell them with the expectation that the price will drop, allowing repurchase at a lower rate to earn a profit.`,
			},
			{
				word: `Covering A Short`,
				definition: `The act of repurchasing the borrowed shares to return them to the lender, ideally at a lower price, is known as covering a short.`,
			},
			{
				word: `Margin Account`,
				definition: `This is a specialized brokerage account used for shorting stocks that provides assurance to the brokerage firm that any losses from shorting will be covered.`,
			},
			{
				word: `Dividend`,
				definition: `A payment made by a corporation to its shareholders. In the context of shorting, the short-seller is liable to pay dividends on the borrowed shares, not receive them.`,
			},
			{
				word: `Ex-Dividend Date`,
				definition: `The date by which an investor must own a stock to receive its next dividend. For a short seller, not closing their position by this date makes them liable to pay the dividend.`,
			},
			{
				word: `Margin Call`,
				definition: `A demand from a broker to deposit more money or securities into a margin account to cover potential losses.`,
			},
			{
				word: `Short Squeeze`,
				definition: `A situation where a lack of supply and an excess demand for a stock forces its price upwards, trapping short-sellers who may then need to cover their positions at a loss.`,
			},
			{
				word: `Unlimited Losses`,
				definition: `A potential risk in shorting stocks, as there's no limit to how much a stock price can increase, leading to potentially unlimited financial liability for the short seller.`,
			},
			{
				word: `Margin Interest`,
				definition: `The interest that accrues in a margin account for the period a short position is open, and it is deducted from any gains made from the short.`,
			},
			{
				word: `Stockbrokers`,
				definition: `Professionals or firms authorized to buy and sell stocks. In the context of shorting, stockbrokers lend shares to investors.`,
			},
			{
				word: `Financial Ineptitude`,
				definition: `Signs or indicators that a company is financially unstable or poorly managed, often considered a potential reason to short its stock.`,
			},
			{
				word: `Risk Management`,
				definition: `The practice of identifying potential risks in advance and taking steps to mitigate them. Critical for short-selling due to its inherently risky nature.`,
			},
		],
	},
	{
		id: 17,
		slug: 'reading-company-financials',
		moduleTitle: 'Reading Company Financials',
		numberOfSlides: 21,
		numberOfActivities: 1,
		numberOfArticles: 4,
		gradeLevels: '9th -12th',
		line1: `In the financial world, information is power, and understanding a company's financial health is crucial for informed investing.`,
		line2: `In this module, dive into the three fundamental financial statements: the Income Statement, Balance Sheet, and Cash Flow Statement in order to learn how to analyze a company's profitability, liquidity, and financial sustainability.`,
		length: '1-3 Hours',
		graphic: `/images/ModuleCovers/M17.jpg`,
		guidingQuestions: [
			`Where do companies report their financial progress and what information do these reports tell us?`,
			`How can investors track a company’s growth and future plans?`,
			`Why are investors able to trust company financials that they report?`,
			`What is the key information for investors to look out for in company documents?`,
			`What are some ways to assess if a company's stock is over or undervalued?`,
			`How do investors choose what to invest in?`,
		],
		enduringUnderstandings: [
			`The 3 major components of financial statements include the Income Statement, Balance Sheet, and Cash Flow Statement.`,
			`How to read and analyze company financial documents with basic accounting.`,
			`The type of information present in financial reports and its relevance.`,
			`Investors should look to the future to understand a company’s potential for growth.`,
			`There are multiple ways to value a company, however, none are perfect and must be analyzed with context.`
		],
		vocabList: [
			{
				word: `Financial Report`,
				definition: `A formal record of a company's financial activities, often released annually and quarterly. Financial reports are crucial for investors to evaluate a company's performance.`,
			},
			{
				word: `10-K`,
				definition: `An annual report required by the U.S. Securities and Exchange Commission (SEC) that provides a comprehensive overview of a company's financial health. It is the most detailed public record for investors.`,
			},
			{
				word: `Balance Sheet`,
				definition: `A financial statement that shows what a company owns (assets), owes (liabilities), and the shareholders' equity at a particular point in time. This statement adheres to the accounting equation.`,
			},
			{
				word: `Accounting Equation`,
				definition: `The fundamental equation of accounting that must hold true for financial statements to be valid. It is Assets = Liabilities + Shareholders' Equity.`,
			},
			{
				word: `Income Statement`,
				definition: `A financial document that shows a company's revenues, expenses, and net income over a specific period, often called the Profit and Loss Statement.`,
			},
			{
				word: `Cash Flow Statement`,
				definition: `A statement that summarizes the flow of cash and cash equivalents in and out of the company. It provides insights into a company’s ability to generate cash and meet financial obligations.`,
			},
			{
				word: `Book Value`,
				definition: `Calculated as Total Assets minus (Intangible Assets + Liabilities), it represents the value of a company’s tangible assets.`,
			},
			{
				word: `Market Value`,
				definition: `The current stock price multiplied by the total number of outstanding shares. Market value may not always represent the true value of a company.`,
			},
			{
				word: `Price Multiples`,
				definition: `Ratios calculated using key figures from financial statements to assess a company's valuation, such as P/E ratio, Enterprise Value, and EBITDA.`,
			},
			{
				word: `Discounted Cash Flow (DCF)`,
				definition: `A valuation method that estimates the value of an investment based on its expected future cash flows, discounted back to present value.`,
			},
			{
				word: `Stakeholder`,
				definition: `Any individual or organization that has an interest in the performance and activities of a company, including management, employees, investors, and government agencies.`,
			},
		],
	},
	{
		id: 18,
		slug: 'buying-your-first-home',
		moduleTitle: 'Buying Your First Home',
		numberOfSlides: 24,
		numberOfActivities: 3,
		numberOfArticles: 6,
		gradeLevels: '7th - 12th',
		line1: 'This module helps students discover the fundamental role of real estate in the economy and explore its significance in the housing market.',
		line2: 'We provide insights in the basics of real estate, its impact on the economy, and the key aspects of residential real estate to help equip students with the knowledge required for a path towards home ownership.',
		length: '2-3 Hours',
		graphic: `/images/ModuleCovers/M18.jpg`,
		guidingQuestions: [
			`What is real estate and what does it encompass?`,
			`How does real estate contribute to economic growth?`,
			`Why is real estate considered a crucial component of the economy?`,
			`What are the key steps involved in the residential real estate buying process?`,
			`How do the characteristics of residential real estate differ from commercial real estate?`,
		],
		enduringUnderstandings: [
			`Real estate is an essential asset class and serves as one of the bedrocks of our economy because everyone needs a place to live.`,
			`Although demand for real-estate is high, making money is tricky and not easy.`,
			`The relationship between supply, demand and market dynamics in determining property prices.`,
			`Understanding real estate can provide financial security and for long-term wealth.`,
		],
		vocabList: [
			{
				word: `Real Estate`,
				definition: `Refers to land, properties, and assets, including buildings and natural resources, that have tangible value and can be bought, sold, or leased for various purposes`,
			},
			{
				word: `Real Estate Agent`,
				definition: `Licensed professional representing buyers or sellers in transactions.`,
			},
			{
				word: `Mortgage`,
				definition: `A loan used to finance property purchases, with the property acting as collateral to ensure that the loan is repaid.`,
			},
			{
				word: `Amortization`,
				definition: `Gradual repayment of both principal and interest associated with debt, such as a mortgage, through regular, equal installments.`,
			},
			{
				word: `Appraisal`,
				definition: `Property value assessment by a licensed professional to determine its market worth for sale, purchase, or loan purposes.`,
			},
			{
				word: `Home Equity`,
				definition: `Caculated as the difference between a property's market value and the outstanding mortgage balance, which represents the ownership stake unencumbered by debt.`,
			},
			{
				word: `Title`,
				definition: `Legal ownership of a property, evidenced by a deed or title certificate.`,
			},
			{
				word: `Down Payment`,
				definition: `Initial payment made by the buyer towards the property's purchase price.`,
			},
			{
				word: `Lease`,
				definition: `Rental agreement between landlord and tenant.`,
			},
			{
				word: `Deed`,
				definition: `Legal document transferring property ownership from seller to buyer.`,
			},
			{
				word: `Condo`,
				definition: `Individual ownership within a shared property complex, with common areas and amenities.`,
			},
			{
				word: `Closing`,
				definition: `Final stage of real estate transaction where ownership transfers, and funds and documents exchange.`,
			},
			{
				word: `Homeowners Association (HOA)`,
				definition: `Governing body managing common areas and enforcing rules in a community, typically charging a monthly fee to homeowners in the community.`,
			},
			{
				word: `Escrow`,
				definition: `Neutral third-party holding funds and documents during real estate transactions until all conditions are met.`,
			},
			{
				word: `Multiple Listing Service (MLS)`,
				definition: `Databases used by real estate agents to list and access different properties for sale. Popular listing services include Zillow, Realtor and Trulia.`,
			},
			{
				word: `Capitalization Rate`,
				definition: `A rate which helps estimate an investment property's value and potential return by providing a rough estimate between the prices of rents and the cost of homes.`,
			},
			{
				word: `Foreclosure`,
				definition: `The process where the lender takes possession of a piece of property due to a borrower's failure to continue paying their mortgage, which causes the borrower to enter default.`,
			},
			{
				word: `Comparative Market Analysis (CMA)`,
				definition: `A process whereby an individual can evaluate a property's value based on recent sales of similar properties in the same community.`,
			},
			{
				word: `Assigned Value`,
				definition: `The value assigned to a property by a tax assessor, sent by the government, to determine the value of a piece of property for taxation purposes.`,
			},
			{
				word: `Buyer's Market`,
				definition: `This references conditions favoring real estate buyers due to high inventory and low demand. Low interest rates typically favor borrowers who require debt to make purchases.`,
			},
			{
				word: `Seller's Market`,
				definition: `This references conditions which favor real estate sellers due to low inventory and high demand. High interst rates can benefit sellers, however, it reduces demand because fewer potential buyers can afford loans.`,
			},
		],
	},
	{
		id: 19,
		slug: 'business-buildings-ownership',
		moduleTitle: 'Business Of Buildings & Ownership',
		numberOfSlides: 19,
		numberOfActivities: 1,
		numberOfArticles: 3,
		gradeLevels: '7th - 12th',
		line1: 'This module explores how commercial real estate is a dynamic and essential sector that creates a conducive environment for businesses to operate, and promotes urban growth & the well-being of communities.',
		line2: 'Commercial Real Estate plays a vital role in promoting economic activity, generating wealth, and contributing to the overall development of communities, so while it’s not typically covered in a financial literacy course, we have included it for interested students.',
		length: '2-4 Hours',
		graphic: `/images/ModuleCovers/M19.jpg`,
		guidingQuestions: [
			`What is Commercial Real Estate (CRE) and why is it important?`,
			`What is the purchase process for commercial real estate?`,
			`What risks should you look out for when purchasing a property?`,
			`What are appraisals and inspections? How do they play a role in CRE?`,
			`What are homeowners and renters insurance? How are they different?`,
			`Why would you become an investor in real estate?`,
		],
		enduringUnderstandings: [
			`Risks associated with CRE investments include vacancy, market, and tenant credit risks.`,
			`Appraisals and inspections are important for determining a property's value and identifying any risks.`,
			`Insurance is important to either a homeowner, or a renter, because anything can happen.`,
			`There are certain tax advantages to investing in real-estate.`,
		],
		vocabList: [
			{
				word: `Commercial Real Estate (CRE)`,
				definition: `Real estate property that is used for business or professional purposes, typically leased from a landlord who is able to generate a profit by charging more in rent than they pay in taxes and upkeep.`,
			},
			{
				word: `Lease Terms`,
				definition: `Lease terms are the rules and agreements between a property owner (lessor) and a renter (lessee). They cover rent, duration, responsibilities, restrictions, and other key aspects of the rental arrangement.`,
			},
			{
				word: `Due Diligence`,
				definition: `Due diligence is a careful investigation and research process done before making important decisions. It involves gathering information, assessing risks, and understanding the pros and cons to make informed choices.`,
			},
			{
				word: `Financing`,
				definition: `Financing involves acquiring the necessary funds to support activities or projects. This typically includes obtaining money from sources like loans, investments, or personal resources to cover expenses or pursue opportunities.`,
			},
			{
				word: `Closing`,
				definition: `In commercial real estate (CRE), closing is the concluding stage of a property sale, signifying the official transfer of ownership from the seller to the buyer. This phase involves signing legal papers, exchanging funds, settling expenses, and updating records. Following closure, the buyer assumes ownership and control of the property.`,
			},
			{
				word: `Neighborhood Analysis`,
				definition: `A specific type of report where real estate investors are able to calculate the expectations for a possible investment within a property.`,
			},
			{
				word: `Inspection`,
				definition: `A rigorous and close examination of a real estate property to ensure that there are no hidden issues or causes for concern with the property.`,
			},
			{
				word: `Appraisal`,
				definition: `This references the act in determining how much a piece of property is worth - typically conducted by a third-party that will help determine the value of a piece of property to provide assurances to both the buyer and seller.`,
			},
			{
				word: `Real Estate Investment Trusts (REITs)`,
				definition: `REITs (Real Estate Investment Trusts) let you invest in real estate without owning property. They pool money from investors to buy properties like offices, apartments, and malls. You get dividends, they're traded on stock markets, and pros manage the properties.`,
			},
			{
				word: `Market Cycles`,
				definition: `Market cycles are repeating patterns of economic growth and decline. They include expansion (growth), peak (high point), contraction (slowdown), and trough (low point). These cycles are influenced by factors like business activity, jobs, and consumer spending.`,
			},
			{
				word: `Economic Recession`,
				definition: `A persistent and lasting decline in overall economic activity. Here, major and especially minor companies begin to lay off workers, leaving unemployment rates to rise.`,
			},
			{
				word: `Reduced Demand`,
				definition: `As a result of weaker consumer confidence and a decline in purchasing, economic downturns cause demand for both residential and commercial buildings to decline.`,
			},
			{
				word: `Falling Property Values`,
				definition: `Property values tend to diminish as a result of falling demand and waning buyer interest, which has an effect on owners' equity and market valuations as a whole.`,
			},
			{
				word: `Increased Vacancy Rates`,
				definition: `In times of economic difficulty, businesses may downsize or close, increasing the vacancy rates in commercial properties like office buildings and retail stores.`,
			},
		],
	},
	{
		id: 20,
		slug: 'financial-exponents',
		moduleTitle: 'Financial Exponents',
		numberOfSlides: 19,
		numberOfActivities: 2,
		numberOfArticles: 4,
		gradeLevels: '6th - 12th',
		line1: 'This module delves into the concepts of exponents, square roots, and scientific notation.',
		line2: 'We provide a comprehensive exploration of these mathematical tools, illustrating their practical applications in financial contexts, by demystifying exponents and analyzing how they contribute to compound growth.',
		length: '2-5 Hours',
		graphic: `/images/ModuleCovers/M20.jpg`,
		guidingQuestions: [
			`How do exponents help in understanding compound interest and its impact on investments over time?`,
			`How can square roots be applied to assess financial risk and volatility in the stock market?`,
			`How is scientific notation used to simplify and understand large financial figures?`,
			`What is the relationship between compound growth and exponential functions?`,
			`How can understanding exponents and scientific notation aid in making more informed financial decisions?`,
			`What are practical examples where you can apply the concept of square roots in personal financial planning?`,
		],
		enduringUnderstandings: [
			`Exponents are fundamental in understanding compound interest, enabling students to appreciate how investments grow over time.`,
			`The use of square roots in finance helps in understanding the variability and risk of investment returns.`,
			`Scientific notation is a valuable tool for managing and interpreting very large or very small financial figures, enhancing clarity in financial analysis.`,
			`Understanding compound growth through exponential functions is crucial in planning for long-term financial goals, such as retirement or education funding.`,
		],
		vocabList: [
			{
				word: `Exponents`,
				definition: `Exponents are a mathematical notation indicating the power to which a number, known as the base, is raised. It represents how many times the base is multiplied by itself.`,
			},
			{
				word: `Compound Interest`,
				definition: `Compound interest refers to the calculation of interest on both the initial principal and the accumulated interest from previous periods. Unlike simple interest, which is calculated only on the principal amount, compound interest allows the interest amount to grow at a faster rate since it is calculated on the increasing total amount each period.`,
			},
			{
				word: `Square Roots`,
				definition: `The square root of a number is a value that, when multiplied by itself, gives the original number. In finance, square roots are used in various calculations, such as in the assessment of investment risk or in the computation of the standard deviation, which is a measure of volatility or dispersion in a set of data.`,
			},
			{
				word: `Scientific Notation`,
				definition: `Scientific notation is a method of expressing numbers that are too large or too small to be conveniently written in full decimal form. It represents numbers as a base of ten raised to an exponent. This notation is particularly useful in finance for handling very large numbers, such as national debts or GDP, or very small numbers, such as interest rates or financial ratios.`,
			},
			{
				word: `Exponential Functions`,
				definition: `An exponential function is a mathematical function in which a constant base is raised to a variable exponent. In finance, exponential functions are used to model growth or decay over time, such as in calculating compound interest`,
			},
		],
	},
	{
		id: 21,
		slug: 'financial-statistics',
		moduleTitle: 'Financial Statistics',
		numberOfSlides: 17,
		numberOfActivities: 4,
		numberOfArticles: 3,
		gradeLevels: '6th - 12th',
		line1: 'This module illuminates how statistics and probability are crucial tools in finance by teaching students how to analyze data from samples, make inferences, and apply these concepts in real-world decisions.',
		line2: 'This knowledge is fundamental in navigating the uncertainties of financial markets and in making well-informed savings and investment choices.',
		length: '2-5 Hours',
		graphic: `/images/ModuleCovers/M21.jpg`,
		guidingQuestions: [
			`How can statistics help us understand a larger population by examining just a sample of it?`,
			`What makes a sample representative of a population?`,
			`What limitations and risks can sampling introduce?`,
			`How do you use data from a random sample to make inferences about a population?`,
			`What is the difference between correlation and causation?`,
			`What are scatter plots and how do they help us visualize trends in data?`,
		],
		enduringUnderstandings: [
			`Statistics provide valuable insights into populations by analyzing representative samples, highlighting the importance of sample selection.`,
			`Random sampling is crucial in statistics as it tends to produce the most representative and unbiased samples, leading to valid generalizations.`,
			`Inferences about population characteristics can be drawn by examining and interpreting data from random samples.`,
			`Correlation is not causation, so be sure to think about underlying data when you’re evaluating relationships.`,
		],
		vocabList: [
			{
				word: `Statistics`,
				definition: `The science of collecting, analyzing, presenting, and interpreting data.`,
			},
			{
				word: `Population`,
				definition: `The entire group that is the subject of a statistical study.`,
			},
			{
				word: `Sample`,
				definition: `A subset of the population selected for observation and analysis.`,
			},
			{
				word: `Representative Sample`,
				definition: `A sample that accurately reflects the characteristics of the population from which it is drawn.`,
			},
			{
				word: `Random Sampling`,
				definition: `A method of selecting a sample from a population where each member has an equal chance of being chosen.`,
			},
			{
				word: `Bias`,
				definition: `A systematic error in data collection or analysis that leads to incorrect conclusions.`,
			},
			{
				word: `Inference`,
				definition: `The process of drawing conclusions about a population based on data collected from a sample.`,
			},
			{
				word: `Correlation`,
				definition: `A statistical measure that describes the extent to which two variables change together, but does not imply causation.`,
			},
			{
				word: `Causation`,
				definition: `A relationship between two variables where one variable causes a change in another.`,
			},
			{
				word: `Scatter Plot`,
				definition: `A type of graph used in statistics to visually display and compare two variables for a set of data.`,
			},
			{
				word: `Trend Line`,
				definition: `A line on a scatter plot, drawn to indicate the general course or tendency of the data points.`,
			},
			{
				word: `Sampling Error`,
				definition: `The error is caused by observing a sample instead of the whole population, leading to potential inaccuracies in a sample's results.`,
			},
			{
				word: `Confounding Variable`,
				definition: `An outside influence that changes the effect of a dependent and independent variable.`,
			},
			{
				word: `Generalization`,
				definition: `Extending the results from a sample to a larger population, making assumptions about a whole group based on a sample.`,
			},
		],
	},
	{
		id: 22,
		slug: 'financial-probabilities',
		moduleTitle: 'Financial Probabilities',
		numberOfSlides: 15,
		numberOfActivities: 2,
		numberOfArticles: 2,
		gradeLevels: '6th - 12th',
		line1: 'Dive into the fascinating world of financial probabilities and unlock tools that can help predict the next Bull Market or Recession!',
		line2: 'Learn how we calculate probabilities and explore the concept of expected value, which helps weigh risks and rewards, helping us make smart, informed decisions in a world full of financial uncertainties.',
		length: '2-5 Hours',
		graphic: `/images/ModuleCovers/M22.jpg`,
		guidingQuestions: [
			`What are probabilities and how do they help us make informed decisions about the future?`,
			`How can we create probabilities to help us model any scenario?`,
			`What is the probability of a recession and are there indicators that can help us change this probability?`,
			`How do compound probabilities work and how are they calculated?`,
			`What are conditional probabilities and how are they more complicated?`,
		],
		enduringUnderstandings: [
			`Probabilities are used to make informed predictions about future events, enabling better decision making under uncertainty.`,
			`We can construct probabilistic models to simulate real-world scenarios.`,
			`Probabilities can be applied in finance all the time - from predicting the likelihood of a recession to understanding the trillion dollar insurance industry.`,
			`How to calculate an understand compound probabilities.`,
		],
		vocabList: [
			{
				word: `Probability`,
				definition: `A measure of the likelihood that an event will occur, between 0 and 1.`,
			},
			{
				word: `Event`,
				definition: `An outcome or a set of outcomes of a random phenomenon or experiment.`,
			},
			{
				word: `Sample Space`,
				definition: `The set of all possible outcomes in a probability experiment.`,
			},
			{
				word: `Mutually Exclusive Events`,
				definition: `Two or more events that cannot occur at the same time.`,
			},
			{
				word: `Independent Events`,
				definition: `Events where the occurrence of one does not affect the probability of the other.`,
			},
			{
				word: `Dependent Events`,
				definition: `Events where the occurrence of one event affects the probability of the other.`,
			},
			{
				word: `Expected Value`,
				definition: `The average value of a random variable over a large number of experiments or trials.`,
			},
			{
				word: `Conditional Probability`,
				definition: `The probability of an event occurring given that another event has already occurred.`,
			},
			{
				word: `Compound Probability`,
				definition: `The likelihood of two or more independent events occurring together.`,
			},
			{
				word: `Probability Axiom`,
				definition: `A fundamental rule in probability stating that the probability of the entire sample space is 1.`,
			},
			{
				word: `Addition Rule`,
				definition: `A rule used to find the probability of the union of two events.`,
			},
			{
				word: `Multiplication Rule`,
				definition: `A rule used to find the probability of the intersection of two independent events.`,
			},
			{
				word: `Law of Total Probability`,
				definition: `A principle that breaks down the probability of an event into a sum of probabilities under different conditions or scenarios.`,
			},
			{
				word: `Random Variable`,
				definition: `A variable whose value is subject to variations due to chance.`,
			},
			{
				word: `Probability Distribution`,
				definition: `A mathematical function that provides the probabilities of occurrence of different possible outcomes.`,
			},
			{
				word: `Normal Distribution`,
				definition: `A probability distribution that is symmetric about the mean, showing that data near the mean are more frequent in occurrence than data far from the mean.`,
			},
		],
	},
	{
		id: 23,
		slug: 'basics-of-banking',
		moduleTitle: 'The Basics Of Banking',
		numberOfSlides: 21,
		numberOfActivities: 4,
		numberOfArticles: 5,
		gradeLevels: '6th - 12th',
		line1: 'This module demystifies the world of banking and its crucial role in both personal finance and the broader economy. We start by exploring the fundamental question, "What is a bank?" laying a solid foundation before journeying through history to uncover how banks originated.',
		line2: 'We then delve into the mechanics of how banks operate and generate revenue, before analyzing the benefits banks play in an individual’s financial lives.',
		length: '2-3 Hours',
		graphic: `/images/ModuleCovers/M23.jpg`,
		guidingQuestions: [
			`What is a bank?`,
			`How did banks get started?`,
			`How do banks operate and make money?`,
			`What value do banks have to you?`,
			`What is FDIC insurance?`,
			`Why banks are important to you and to the economy?`,
			`What is the future of banking?`,
		],
		enduringUnderstandings: [
			`Banks play an integral role in the global economy by fueling financial growth beyond the financial industry.`,
			`The differences between a commercial bank and an investment bank, and how they both make money.`,
			`How the government protects your money in banks to not create another Great Depression or Financial Crisis.`,
			`What is important when choosing a bank and what should individual's try to avoid.`,
		],
		vocabList: [
			{
				word: `Bank`,
				definition: `A financial institution licensed to receive deposits and make loans.`,
			},
			{
				word: `Commercial/Retail Bank`,
				definition: `A bank that offers services to the general public and to companies.`,
			},
			{
				word: `Investment Bank`,
				definition: `A bank that specializes in services for businesses and investors, like underwriting deals for large companies and advising on mergers and acquisitions.`,
			},
			{
				word: `Deposit`,
				definition: `Money placed in any type of bank account.`,
			},
			{
				word: `Interest`,
				definition: `The charge for the privilege of borrowing money, typically expressed as an annual percentage rate.`,
			},
			{
				word: `FDIC Insurance (Federal Deposit Insurance Corporation)`,
				definition: `A United States government corporation providing deposit insurance up to $250,000 to depositors in U.S. banks.`,
			},
			{
				word: `Bank Services`,
				definition: `Various offerings provided by banks to their customers, like checking accounts, savings accounts, mortgages, and loans.`,
			},
			{
				word: `Checking Account`,
				definition: `A deposit account at a bank that allows withdrawals and deposits, often accessible via checks, ATMs, and electronic debits.`,
			},
			{
				word: `Savings Account`,
				definition: `A deposit account that earns interest and is typically used for saving money over a period of time with a minimum account balance that you must maintain.`,
			},
			{
				word: `High-Yield Savings Account`,
				definition: `A type of savings account that offers a higher interest rate compared to standard savings accounts.`,
			},
			{
				word: `CD (Certificate of Deposit)`,
				definition: `A savings certificate with a fixed maturity date and specified fixed interest rate over that period.`,
			},
			{
				word: `Overdraft Protection`,
				definition: `A service that prevents checks, ATM transactions, or debit card purchases from overdrawing a bank account, which can create unexpected fees.`,
			},
			{
				word: `Wire Transfer Fees`,
				definition: `Charges applied for electronic transfer of funds from one person or entity to another.`,
			},
			{
				word: `Transaction Fees`,
				definition: `Fees charged for certain types of transactions, such as transferring money or making payments.`,
			},
		],
	},
	{
		id: 24,
		slug: 'reading-financial-charts',
		moduleTitle: 'Reading Financial Charts',
		numberOfSlides: 15,
		numberOfActivities: 2,
		numberOfArticles: 4,
		gradeLevels: '7th - 12th',
		line1: 'This module equips students with the fundamental skills required to interpret and analyze financial charts. We begin by introducing the core concepts of 3 chart types - line, candlestick, and OHLC - and explain how they are constructed from financial data.',
		line2: 'We then delve into the interpretation of these charts, teaching students to discern trends, patterns, and key indicators that are vital in financial decision-making.',
		length: '1-2 Hours',
		graphic: `/images/ModuleCovers/M24.jpg`,
		guidingQuestions: [
			`What are financial charts, and why are they essential in investment decision-making?`,
			`How do different types of charts (line, candlestick, OHLC) present financial data, and what unique insights do they offer?`,
			`What is the significance of the data used in financial charts, and how does the choice of time interval affect the information displayed?`,
			`How do popular chart overlays like moving averages and Bollinger Bands enhance the interpretation of financial charts?`,
			`In what ways can understanding and analyzing charts influence investment strategies and risk management?`,
		],
		enduringUnderstandings: [
			`Charts are critical in visualizing, interpreting and analyzing complex financial data.`,
			`Different chart types (line, candlestick, OHLC) serve varied purposes and offer unique insights.`,
			`The source of data and timeframes impacts the information a chart conveys, influencing investment decisions.`,
			`Overlays like moving averages and Bollinger Bands provide additional layers of information, helping to identify trends, volatility, and potential trading signals.`,
			`Reading and interpreting charts is integral to developing effective investment & risk strategies.`,
		],
		vocabList: [
			{
				word: `Chart`,
				definition: `A visual representation of financial data over a specific time period, used to analyze trends and patterns in markets.`,
			},
			{
				word: `Axes`,
				definition: `In financial charts, the axes refer to the two perpendicular lines that frame the chart, with the horizontal axis typically representing time intervals, and the vertical axis representing price levels or other numerical values.`,
			},
			{
				word: `Scale`,
				definition: `The scale on a financial chart refers to the graduated range of values used to measure the data being represented, such as price or volume; it can be linear, showing equal value intervals, or logarithmic, where each interval reflects a percentage change.`,
			},
			{
				word: `Line Chart`,
				definition: `A type of chart that displays information as a series of data points connected by straight line segments, typically used to show trends over time.`,
			},
			{
				word: `Candlestick Chart`,
				definition: `A chart that represents price movements within a certain time frame, showing the opening, closing, high, and low prices through a combination of a rectangular body and lines.`,
			},
			{
				word: `OHLC Chart`,
				definition: `Stands for Open, High, Low, Close chart, a type of financial chart used to depict price movement over time, displaying the opening, high, low, and closing prices for each time period.`,
			},
			{
				word: `Volume`,
				definition: `The amount of an asset or security that changes hands over a set period, an important indicator of the strength or weakness of a market move.`,
			},
			{
				word: `Trend`,
				definition: `The general direction in which a market or asset's price is moving, an essential concept in technical analysis to identify potential opportunities.`,
			},
			{
				word: `Moving Average (MA)`,
				definition: `A widely used indicator in technical analysis that helps smooth out price action by filtering out the “noise” from random price fluctuations that occur while a stock trades.`,
			},
			{
				word: `Bollinger Bands`,
				definition: `A technical analysis tool defined by a set of lines plotted two standard deviations (positively and negatively) away from a simple moving average (SMA) of a security's price.`,
			},
			{
				word: `Relative Strength Index (RSI)`,
				definition: `A momentum oscillator that measures the speed and change of price movements, typically used to identify overbought or oversold conditions.`,
			},
			{
				word: `Moving Average Convergence Divergence (MACD)`,
				definition: `A trend-following momentum indicator that shows the relationship between two moving averages of a security's price.`,
			},
			
		],
	},
	{
		id: 25,
		slug: 'economy-and-federal-reserve',
		moduleTitle: 'The Economy & Federal Reserve',
		numberOfSlides: 24,
		numberOfActivities: 5,
		numberOfArticles: 4,
		gradeLevels: '9th -12th',
		line1: 'This module outlines key economic concepts and the role of the Federal Reserve in the U.S. Economy.',
		line2: 'Explore the causes and effects of inflation, understand the structure and functions of the Federal Reserve, and learn about how we measure and grow the world’s largest economies.',
		length: '2-4 Hours',
		graphic: `/images/ModuleCovers/M25.jpg`,
		guidingQuestions: [
			`What factors contribute to the size and scope of the global economy?`,
			`How significant is the role of the United States in the global economy?`,
			`How do we measure the economic output of a country?`,
			`What are the advantages and disadvantages of having the US Dollar as a reserve currency?`,
			`How does the Federal Reserve influence the U.S. economy?`,
			`How do inflation and recessions impact individual financial goals and behaviors?`,
			`How have historical economic crises shaped today’s economic strategies and policies?`,
		],
		enduringUnderstandings: [
			`The global economy is vast and the United States plays a critical role with the 2nd largest economic output.`,
			`Gross Domestic Product (GDP) is a crucial measure of economic activity.`,
			`The U.S. dollar's status as a reserve currency brings significant benefits to global trade, investment, and economic stability.`,
			`The Federal Reserve serves as the central bank of the United States, regulating the monetary supply and maintaining financial stability through various economic cycles.`,
			`Historical economic events like the 2008 recession provide essential lessons that continue to influence policies today.`,
		],
		vocabList: [
			{
				word: `Global Economy`,
				definition: `The international exchange of goods and services that is expressed in monetary units of account.`,
			},
			{
				word: `Gross Domestic Product (GDP)`,
				definition: `The total monetary or market value of all the finished goods and services produced within a country's borders in a specific time period.`,
			},
			{
				word: `Reserve Currency`,
				definition: `A foreign currency held in significant quantities by governments and institutions as part of their foreign exchange reserves, often used in international transactions.`,
			},
			{
				word: `Nominal GDP`,
				definition: `Measures a country's total economic output (the value of its goods and services) using current prices, without adjusting for inflation.`,
			},
			{
				word: `Real GDP`,
				definition: `The economic output of a country, when adjusted for price changes (inflation or deflation), provides a more accurate figure of a country's economy size and growth rate over time.`,
			},
			{
				word: `Inflation`,
				definition: `The rate at which the general level of prices for goods and services is rising, subsequently eroding purchasing power.`,
			},
			{
				word: `Recession`,
				definition: `A significant decline in economic activity spread across the economy, lasting more than a few months, typically visible in real GDP, real income, employment, industrial production, and wholesale-retail sales.`,
			},
			{
				word: `Interest Rates`,
				definition: `The amount charged by a lender to a borrower for the use of assets, expressed as a percentage of the principal, usually noted on an annual basis known as APR.`,
			},
			{
				word: `Monetary Policy`,
				definition: `The macroeconomic policy laid down by the central bank, involving management of money supply and interest rate and aimed at achieving maximum employment, stable prices, and moderate long-term interest rates.`,
			},
			{
				word: `Federal Reserve (Fed)`,
				definition: `The central bank of the United States, which is responsible for setting monetary policy, regulating banks, maintaining the stability of the financial system, and providing financial services to depository institutions, the U.S. government, and foreign official institutions.`,
			},
			{
				word: `Open Market Operations (OMO)`,
				definition: `Activities by a central bank to buy or sell government bonds on the open market to expand or contract the amount of money in the banking system.`,
			},
			{
				word: `Quantitative Easing (QE)`,
				definition: `A monetary policy wherein a central bank buys predetermined amounts of government bonds or other financial assets in order to inject liquidity directly into the economy.`,
			},
		],
	},
];

export default Modules;