const FAQ = [
	{
		question: `How does Rapunzl work?`,
		answers: [
			`Rapunzl blends a real-time stock market simulator with a structured financial literacy curriculum that is housed in a web-accessible Teacher Dashboard.`,
			`Teachers can access 25 interactive personal finance modules and build a customized personal finance course that aligns with national and state standards, while students are able to access Rapunzl through the Rapunzl website or free mobile app for iOS and Android.`,
			`This provides students with a risk-free, real-world learning experience as they manage an investment portfolio throughout the school year.`
		]
	},
	{
		question: `What does Rapunzl look like in the classroom?`,
		answers: [
			`Rapunzl brings financial concepts to life with interactive presentations, vocabulary lists, articles, and hands-on activities designed to provide an instructor with everything they need to start a personal finance class.`,
			`Each module is supported by a pacing guide for teachers, providing slide-by-slide notes to highlight key concepts, student engagement tips, and ways to integrate and leverage the Rapunzl simulator.`,
			`This setup enables students to actively discuss, trade, and apply financial literacy concepts while teachers guide them through real-world applications.`
		]
	},
	{
		question: `What grade levels can use Rapunzl?`,
		answers: [
			`Rapunzl’s curriculum has had the most success working with middle and high school students in grades 6 through 12.`,
			`While some school districts  have adapted the investment simulator for younger students, the full curriculum benefits from students having a strong grasp of middle school math.`,
			`We typically recommend introducing the program at 7th grade in middle school, or 10th grade in high school, however, the curriculum is customizable to meet any grade level.`
		]
	},
	{
		question: `How long is Rapunzl’s curriculum?`,
		answers: [
			`Rapunzl’s flexible curriculum design supports both short-term and long-term use. We offer prebuilt courses suitable for as little as two weeks or as long as a full academic year.`,
			`This ensures that educators can implement financial literacy education in a way that fits their schedules and classroom needs, from foundational courses to an in-depth exploration of finance topics.`,
		]
	},
	{
		question: `Can Rapunzl be used as a supplementary tool?`,
		answers: [
			`Absolutely! Rapunzl is an excellent resource for supplementing personal finance, math, or economics courses.`,
			`Teachers can integrate Rapunzl’s simulator to provide students with a risk-free opportunity to engage with the world of finance and investing.`,
			`Our Educator Dashboard also allows instructors to leverage our curriculum resources a-la-carte such that Rapunzl can be paired with an existing business or personal finance curriculum.`
		]
	},
	{
		question: `Does Rapunzl offer teacher training?`,
		answers: [
			`Yes, Rapunzl provides comprehensive teacher training and professional development to ensure educators are confident using our platform.`,
			`Training typically involves three hours of PD, with an initial hour dedicated to understanding the technology. We then assess each teacher’s proficiency and use two additional hours to address specific knowledge areas.`,
			`This ensures that educators are well-prepared to lead meaningful financial literacy instruction.`
		]
	},
	{
		question: `Who is already using Rapunzl?`,
		answers: [
			`Over 50,000 students from more than 1,000 schools participate in Rapunzl’s scholarship competition each year. Rapunzl has its strong presence in Chicago, Los Angeles, and New York City, however there are programs in districts across the country.`,
			`Currently, students in all 50 states are using Rapunzl’s simulator and we have programs in over 20 states where schools are leveraging the curriculum to meet financial literacy graduation requirements.`
		]
	},
	{
		question: `Can I receive demo access to a sandbox account?`,
		answers: [
			`Yes! We offer a 30-day free trial of the Rapunzl simulator and Teacher Dashboard, giving you unlimited access to explore the platform.`,
			`During this trial, you’ll have access to complete lesson plans, engaging activities, and live support to help you make the most of our resources. This free trial allows you to see firsthand how Rapunzl can enhance your classroom experience.`,
			`Contact us to request a demo and learn more about how Rapunzl could fit at your school!`
		]
	}
];

export default FAQ;