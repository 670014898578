import React from "react";
import CryptoHeader from "../components/Crypto/CryptoHeader";
import SeperatePortfolios from "../components/Crypto/SeperatePortfolios";
import CryptoCoinCoverage from "../components/Crypto/CryptoCoinCoverage";
import CoinbaseSponsorship from "../components/Crypto/CoinbaseSponsorship";
import CryptoFeatures from "../components/Crypto/CryptoFeatures";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/CryptoScreen.css";
import { MetaCryptoScreen } from "../constants/metaDescriptions";
import GetInTouch from "../components/Educators/GetInTouch";

const CryptoScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Simulated Crypto Investing</title>
        <meta name="description" content={MetaCryptoScreen} />
        <meta property="og:title" content="Rapunzl | Simulated Crypto Investing" />
        <meta property="og:description" content={MetaCryptoScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/crypto" />
        <link rel="canonical" href="https://www.rapunzl.com/crypto" />
      </Helmet>
      <Header />
      <div className="route-subcontainer">
        <CryptoHeader />
        <SeperatePortfolios />
        <CryptoCoinCoverage />
        <CoinbaseSponsorship />
        <CryptoFeatures />
        <GetInTouch darkMode={true} />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default CryptoScreen;