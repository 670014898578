import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import HomeScreen from '../routes/HomeScreen';
import EducatorsScreen from "../routes/EducatorsScreen";
import CompetitionRulesScreen from "../routes/CompetitionRulesScreen";
import ContactScreen from "../routes/ContactScreen";
import CourseScreen from '../routes/CourseScreen';
import CoursesScreen from '../routes/CoursesScreen';
import CryptoScreen from "../routes/CryptoScreen";
import DemoScreen from "../routes/DemoScreen";
import LegalScreen from "../routes/LegalScreen";
import NotFoundPage from '../routes/NotFoundPage';
import ParentsScreen from '../routes/ParentsScreen';
import PartnersScreen from "../routes/PartnersScreen";
import PressScreen from "../routes/PressScreen";
import PrivacyPolicyScreen from "../routes/PrivacyPolicyScreen";
import Summer2024Screen from "../routes/Summer2024Screen";
import TermsOfUseScreen from "../routes/TermsOfUseScreen";
import DistrictLeaders from '../routes/DistrictLeaders';
import '../assets/styles/General.css';
import ReactGA from "react-ga4";
import ArticleScreen from '../routes/ArticleScreen';
import StandardsRequestScreen from '../routes/StandardsRequestScreen';
import DemoRequestScreen from '../routes/DemoRequestScreen';
import NationalStandardsScreen from '../routes/NationalStandardsScreen';
// import StateStandardsScreen from '../routes/StateStandardsScreen';
import ModulePreviewScreen from '../routes/ModulePreviewScreen';
import AboutScreen from '../routes/AboutScreen';

const ArticleScreenWrapper = (props) => {
  const params = useParams();
  return  <ArticleScreen  {...{...props, match: {params}} } />
}

const CourseScreenWrapper = (props) => {
  const params = useParams();
  return  <CourseScreen  {...{...props, match: {params}} } />
}

const DemoRequestScreenWrapper = (props) => {
  const params = useParams();
  return  <DemoRequestScreen  {...{...props, match: {params}} } />
}

const ModulePreviewScreenWrapper = (props) => {
  const params = useParams();
  return  <ModulePreviewScreen  {...{...props, match: {params}} } />
}

const StateStandardsScreenWrapper = (props) => {
  const params = useParams();
  return  <StateStandardsScreen  {...{...props, match: {params}} } />
}

const App = () => {
  ReactGA.initialize("G-CPKYDDS6CW");

  return (  
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/article/:articleURL" element={<ArticleScreenWrapper />}></Route>
        <Route path="/educators" element={<EducatorsScreen />}></Route>
        <Route path="/rules" element={<CompetitionRulesScreen />}></Route>
        <Route path="/contact" element={<ContactScreen />}></Route>
        <Route path="/course/:courseName" element={<CourseScreenWrapper />}></Route>
        <Route path="/courses" element={<CoursesScreen />}></Route>
        <Route path="/demo" element={<DemoScreen />}></Route>
        <Route path="/demoRequest/:stateName" element={<DemoRequestScreenWrapper />}></Route>
        <Route path="/districts" element={<DistrictLeaders />}></Route>
        <Route path="/crypto" element={<CryptoScreen />}></Route>
        <Route path="/legal" element={<LegalScreen />}></Route>
        <Route path="/module/:moduleSlug" element={<ModulePreviewScreenWrapper />}></Route>
        <Route path="/nationalStandards" element={<NationalStandardsScreen />}></Route>
        <Route path="/parents" element={<ParentsScreen />}></Route>
        <Route path="/partners" element={<PartnersScreen />}></Route>
        <Route path="/press" element={<PressScreen />}></Route>
        <Route path="/privacy" element={<PrivacyPolicyScreen />}></Route>
        <Route path="/requestStandards" element={<StandardsRequestScreen />}></Route>
        {/* <Route path="/stateStandards/:stateName" element={<StateStandardsScreenWrapper />}></Route> */}
        <Route path="/summer" element={<Summer2024Screen />}></Route>
        <Route path="/termsOfUse" element={<TermsOfUseScreen />}></Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
  );
};

export default App;
