import React from "react";
//import Scholarships from '../../assets/images/DFK/Scholarships.jpg';
//import Development from '../../assets/images/DFK/ProfessionalDevelopment.jpg';
//import Exposure from '../../assets/images/DFK/NationalExposure.jpg';

const ROWhy = () => {
  return (
    <div id="s6" className="dfk-section pitch-description">
      <h2 className="pitch-description-h1">
        R-O-Why?
      </h2>
      <div className="dfk-section-flex pitch-description-flex">
        <div className="pitch-description-item">
          <img loading="lazy" id="scholarships-img" draggable="false" className="pitch-description-img" alt="Rapunzl Social Impact Scholarships For Middle and High School Students" src="/images/DFK/Scholarships.jpg" />
          <div>
            <h3 className="pitch-description-h2">
              Scholarships
            </h3>
            <div className="pitch-description-h3">
              Provide top performing students with access to scholarships that will make their dreams reality.
              <br/>
              <br/>
              Scholarship prizes help enhance student engagement and provide an opportunity for students to earn while they learn.
            </div>
          </div>
        </div>
        <div className="pitch-description-item">
          <img loading="lazy" id="teacher-training-img" draggable="false" className="pitch-description-img" alt="Rapuznl World-Class Personal Finance Teacher Training" src="/images/DFK/ProfessionalDevelopment.jpg" />
          <div>
            <h3 className="pitch-description-h2">
              Teacher Training
            </h3>
            <div className="pitch-description-h3">
              Offer teachers free teacher training and provide financial educators across the country.
              <br/>
              <br/>
              Also includes access to engaging curriculum & real-time digital learning tools to continue building capacity.
            </div>
          </div>
        </div>
        <div className="pitch-description-item">
          <img loading="lazy" id="exposure-img" draggable="false" className="pitch-description-img" alt="Earn National Exposure With Rapunzl Simulated National Scholarship Competitions" src="/images/DFK/NationalExposure.jpg" />
          <div>
            <h3 className="pitch-description-h2">
              National Exposure
            </h3>
            <div className="pitch-description-h3">
              Empowering a broader pool of diverse talent to strive for financial services careers.
              <br/>
              <br/>
              This helps expose all students to the myraid of opportunities that are available to financially literate students.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROWhy;