import React from "react";

const UploadStudents = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '0px', paddingBottom: '140px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="easily upload students to Rapunzl to get them started investing" src="/images/Demo/XLSXFile.webp"/>
      <div>
        <h2 className="educator-header">
          Upload Your Students & Join Our Scholarship
        </h2>
        <div className="educator-text">
          Quickly and easily upload your students to Rapunzl and give them the chance to participate in our exciting scholarship program. All you need to do is upload a spreadsheet with student names and emails, and we’ll handle the rest!
          <br /><br />
          From there, each student will receive an incredible experience simulating a $10,000 portfolio and competing for scholarship prizes!
        </div>
      </div>
    </div>
  );
};

export default UploadStudents;