import React from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/EducatorsScreen.css";

const StandardsAligned = () => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '0px', paddingBottom: '170px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="Rapunzl Simulated Investing Competition With Scholarships" src="/images/Educators/StandardsAligned.webp"/>
      <div>
        <h2 className="educator-header">
          A Personal Finance Curriculum Aligned With All State Standards
        </h2>
        <div className="educator-text">
            Rapunzl's curriculum and prebuilt courses cover a wide range of essential life skills, while simultaneously fulfilling your state’s standards of learning.
          <br /><br />
            Get in touch with us to receive a roadmap for <Link className="article-link" to="/article/satisfyFinancialLiteracyRequirements">Rapunzl's state and national standards alignment</Link>, curriculum access, and login credentials for the simulator.
        </div> 
        <div className="get-in-touch-flex">
          <Link to="/contact">
            <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
              Request Personal Finance Standards
            </div>
          </Link>
        </div> 
      </div>
    </div>
  );
};

export default StandardsAligned;