import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaNationalStandards } from "../constants/metaDescriptions";
import EarningIncomeStandard from "../components/Standards/EarningIncomeStandard";
import SavingStandard from "../components/Standards/SavingStandard";
import SpendingStandard from "../components/Standards/SpendingStandard";
import CreditStandard from "../components/Standards/CreditStandard";
import ManagingRiskStandard from "../components/Standards/ManagingRiskStandard";
import InvestingStandard from "../components/Standards/InvestingStandard";
import StandardsHeader from "../components/Standards/StandardsHeader";
import SelectState from '../components/StandardsRequest/SelectState';
import "../assets/styles/NationalStandardsScreen.css";

const NationalStandardsScreen = () => {
  return (
    <div className="route-subcontainer gradient-route">
      <Helmet>
        <title>Rapunzl | Financial Literacy Standards</title>
        <meta name="description" content={MetaNationalStandards} />
        <meta property="og:title" content="Rapunzl | Financial Literacy Standards" />
        <meta property="og:description" content={MetaNationalStandards}/>
        <meta property="og:url" content="https://www.rapunzl.com/parents" />
        <link rel="canonical" href="https://www.rapunzl.com/parents" />
      </Helmet>
      <Header />
      <StandardsHeader />
      <EarningIncomeStandard />
      <SavingStandard />
      <SpendingStandard />
      <CreditStandard />
      <ManagingRiskStandard />
      <InvestingStandard />
      <SelectState demo={true} />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default NationalStandardsScreen;