import React from "react";
import { Link } from "react-router-dom";

const CourseHeader = (props) => {

  let lowerCaseCourseName = props.courseName.toLowerCase();

  return (
    <div className="course-flex-header">
      {lowerCaseCourseName === 'financialmath' && (
       <img
          alt={`rapunzl course badge` + ' ' + lowerCaseCourseName}
          className="course-image"
          src={'/images/Courses/FinancialMath621.webp'}
        />
      )}
      {lowerCaseCourseName === 'personalFinance' && (
       <img
          alt={`rapunzl course badge` + ' ' + lowerCaseCourseName}
          className="course-image"
          src={'/images/Courses/PersonalFinance621.webp'}
        />
      )}
      {lowerCaseCourseName === 'collegecareers' && (
       <img
          alt={`rapunzl course badge` + ' ' + lowerCaseCourseName}
          className="course-image"
          src={'/images/Courses/CollegeCareers621.webp'}
        />
      )}
      {lowerCaseCourseName === 'advancedInvesting' && (
       <img
          alt={`rapunzl course badge` + ' ' + lowerCaseCourseName}
          className="course-image"
          src={'/images/Courses/AdvancedInvesting621.webp'}
        />
      )}
      <div className="course-flex-right">
        {lowerCaseCourseName === 'financialmath' && (
          <div>
            <h1 className="course-title">
              Rapunzl Personal Finance Course:<br/>Financial Math
            </h1>
            <div className="single-course-description">
              We leverage the mathematical foundations of personal finance, including interest rates, budgeting, and financial planning, to help students develop essential skills for managing money effectively.
              <br/>
              <br/>
              Designed as a supplementary resource for 6-8th grade math classes or a remedial course for high school students.
            </div>
          </div>
        )}
        {lowerCaseCourseName === 'personalfinance' && (
          <div>
            <h1 className="course-title">
              Rapunzl Personal Finance Course:<br/>Personal Finance
            </h1>
            <div className="single-course-description">
              Learn fundamental principles of personal finance to build a strong financial foundation and satisy state graduation requirements.
              <br/>
              <br/>
              This course is aligned with middle and high school personal finance standards and includes curriculum for a full semester course.
            </div>
          </div>
        )}
        {lowerCaseCourseName === 'collegecareers' && (
          <div> 
            <h1 className="course-title">
              Rapunzl Personal Finance Course:<br/>College & Careers
            </h1>
            <div className="single-course-description">
              Learn about different career paths and how to apply to jobs by creating a resume and cover letter with this supplementary, half-semester course.
              <br/>
              <br/>
              Understand the basics of financial aid, FAFSA, and learn to assess the pros & cons with pursuing higher education.
            </div>
          </div>
        )}
        {lowerCaseCourseName === 'advancedInvesting' && (
          <div>
            <h1 className="course-title">
              Rapunzl Personal Finance Course:<br/>Advanced Investing
            </h1>
            <div className="single-course-description">
              Delve into sophisticated investment strategies, portfolio management, and risk assessment.
              <br/>
              <br/>
              Designed as a half-semester course or supplementary resource for 11-12th graders.
            </div>
          </div>
        )}
        <Link to="/demo">
          <div className="checkout-rapunzl">
            Get A Free Educator Demo
          </div>
        </Link>
      </div>  
    </div>        
  );
};
export default CourseHeader;