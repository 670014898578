// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-home-container {
	margin-bottom: 150px;
}
.faq-title {
	text-align: center;
	font-size: 32px;
	font-weight: 800;
	line-height: 1.2;
	padding-top: 55px;
	color: #007154;
}
.faq-item {
	width: 90%;
	margin: auto;
	max-width: 780px;
	border-bottom: 2px solid #007154;
}
.faq-question-flex {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	
	padding-top: 24px;
	padding-bottom: 24px;
	
	cursor: pointer;
}
.faq-question-text {
	font-size: 18px;
	font-weight: 600;
	color:#032a20
}
.faq-arrow {
	fill: #032a20;
	font-size: 30px !important;
}
.faq-answer-container {
	margin-top: -10px;
	padding-bottom: 18px;
}
.faq-answer {
	font-size: 16px;
	font-weight: 300;
	padding-bottom: 18px;
	line-height: 1.2;
	color: #064e3c;
}
@media only screen and (max-width: 475px) {
	.faq-home-container {
		margin-bottom: 90px;
	}
	.faq-title {
		padding-bottom: 20px;
		font-size: 28px;
	}
	.faq-item {
		width: 96%;
	}	
	.faq-question-text {
		font-size: 15px;
	}
	.faq-question-flex {
		padding-top: 18px;
		padding-bottom: 18px;
	}
	.faq-answer {
		font-size: 14px;
	}
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/FAQHome.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;AACrB;AACA;CACC,kBAAkB;CAClB,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,iBAAiB;CACjB,cAAc;AACf;AACA;CACC,UAAU;CACV,YAAY;CACZ,gBAAgB;CAChB,gCAAgC;AACjC;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,mBAAmB;;CAEnB,iBAAiB;CACjB,oBAAoB;;CAEpB,eAAe;AAChB;AACA;CACC,eAAe;CACf,gBAAgB;CAChB;AACD;AACA;CACC,aAAa;CACb,0BAA0B;AAC3B;AACA;CACC,iBAAiB;CACjB,oBAAoB;AACrB;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,oBAAoB;CACpB,gBAAgB;CAChB,cAAc;AACf;AACA;CACC;EACC,mBAAmB;CACpB;CACA;EACC,oBAAoB;EACpB,eAAe;CAChB;CACA;EACC,UAAU;CACX;CACA;EACC,eAAe;CAChB;CACA;EACC,iBAAiB;EACjB,oBAAoB;CACrB;CACA;EACC,eAAe;CAChB;AACD","sourcesContent":[".faq-home-container {\n\tmargin-bottom: 150px;\n}\n.faq-title {\n\ttext-align: center;\n\tfont-size: 32px;\n\tfont-weight: 800;\n\tline-height: 1.2;\n\tpadding-top: 55px;\n\tcolor: #007154;\n}\n.faq-item {\n\twidth: 90%;\n\tmargin: auto;\n\tmax-width: 780px;\n\tborder-bottom: 2px solid #007154;\n}\n.faq-question-flex {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n\talign-items: center;\n\t\n\tpadding-top: 24px;\n\tpadding-bottom: 24px;\n\t\n\tcursor: pointer;\n}\n.faq-question-text {\n\tfont-size: 18px;\n\tfont-weight: 600;\n\tcolor:#032a20\n}\n.faq-arrow {\n\tfill: #032a20;\n\tfont-size: 30px !important;\n}\n.faq-answer-container {\n\tmargin-top: -10px;\n\tpadding-bottom: 18px;\n}\n.faq-answer {\n\tfont-size: 16px;\n\tfont-weight: 300;\n\tpadding-bottom: 18px;\n\tline-height: 1.2;\n\tcolor: #064e3c;\n}\n@media only screen and (max-width: 475px) {\n\t.faq-home-container {\n\t\tmargin-bottom: 90px;\n\t}\n\t.faq-title {\n\t\tpadding-bottom: 20px;\n\t\tfont-size: 28px;\n\t}\n\t.faq-item {\n\t\twidth: 96%;\n\t}\t\n\t.faq-question-text {\n\t\tfont-size: 15px;\n\t}\n\t.faq-question-flex {\n\t\tpadding-top: 18px;\n\t\tpadding-bottom: 18px;\n\t}\n\t.faq-answer {\n\t\tfont-size: 14px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
