import React from "react";
//import TeacherBG from '../../assets/images/DFK/AuyanaTeaching.jpg';
//import CartoonPhone from '../../assets/images/DFK/CartoonChartPhone.jpg';

const AboutWithVideo = () => {
  return (
    <div>
      <div className="dfk-section-flex dfk-section introduction">
        <div className="introduction-left">
          <h2 className="introduction-h1">
            Rapunzl empowers the next generation of investors.
          </h2>
          <div className="introduction-h2" style={{ paddingBottom: "10px" }}>
            Rapunzl partners with Dreams For Kids, a 501(c)3 organization, to provide high school and college students of color with high-quality financial literacy tools to help them thrive in financial services careers. 
          </div>
          <div className="introduction-h2" style={{ paddingBottom: "10px" }}>
            We provide equitable access through teacher training programs, free curriculum & our real-world simulator. This combination helps demystify financial concepts & spark a curiosity in students about building generational wealth.
          </div>
          <div className="introduction-h2">
            Students apply key investing concepts while managing a simulated stock portfolio and develop professional skills through internships, mentorships & field experiences.
          </div>
        </div>
        <div loading="lazy" className="introduction-right">
          <img id="cartoon-phone" draggable="false" className="introduction-img" alt="Cartoon Phone with Rapunzl real-time simulator and scholarship competitions" src="/images/DFK/CartoonChartPhone.jpg" />
        </div>
      </div>
      <div className="dfk-section header equitable-access" style={{ backgroundImage: `url(/images/DFK/AuyanaTeaching.jpg)` }}>
        <div className="equitable-access-quote">
          Simulated Competitions Provide Real-World Learning Which Allows Students To See Themselves Thriving In Financial Services
        </div>
        <a href="https://www.youtube.com/watch?v=R9EyNeI0DuM&feature=youtu.be">
          <div className="play-button">
            Play CNN Video
          </div>
        </a>
      </div>
    </div>
  );
};

export default AboutWithVideo;