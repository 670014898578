import React from "react";
//import HSBC from '../../assets/images/DFK/Partners/Presenting/HSBC.png';
//import KPMG from '../../assets/images/DFK/Partners/Presenting/KPMG.png';
//import Mesirow from '../../assets/images/DFK/Partners/Presenting/Mesirow.png';
//import Ariel from '../../assets/images/DFK/Partners/Presenting/Ariel.png';
//import CIBC from '../../assets/images/DFK/Partners/Presenting/CIBC.png';
//import GCM from '../../assets/images/DFK/Partners/Presenting/GCM.png';
//import Horizon from '../../assets/images/DFK/Partners/Presenting/Horizon.png';
//import Magellan from '../../assets/images/DFK/Partners/Presenting/Magellan.png';
//import Mastercard from '../../assets/images/DFK/Partners/Presenting/Mastercard.png';
//import NW from '../../assets/images/DFK/Partners/Presenting/NW.png';
//import Summit from '../../assets/images/DFK/Partners/Presenting/Summit.png';
//import UCMedicine from '../../assets/images/DFK/Partners/Presenting/UCMedicine.png';
//import Wintrust from '../../assets/images/DFK/Partners/Presenting/Wintrust.png';
//import AB from '../../assets/images/DFK/Partners/Volunteer/AB.png';
//import FifthThird from '../../assets/images/DFK/Partners/Volunteer/FifthThird.png';
//import Guggenheim from '../../assets/images/DFK/Partners/Volunteer/Guggenheim.png';
//import JPMChase from '../../assets/images/DFK/Partners/Volunteer/JPMChase.png';
//import Loop from '../../assets/images/DFK/Partners/Volunteer/Loop.png';
//import Morningstar from '../../assets/images/DFK/Partners/Volunteer/Morningstar.png';
//import StateFarm from '../../assets/images/DFK/Partners/Volunteer/StateFarm.png';
//import Wedbush from '../../assets/images/DFK/Partners/Volunteer/Wedbush.png';
//import BM from '../../assets/images/DFK/Partners/Community/100BM.png';
//import CTO from '../../assets/images/DFK/Partners/Community/CTO.png';
//import AG from '../../assets/images/DFK/Partners/Community/AG.png';
//import ASM from '../../assets/images/DFK/Partners/Community/ASM.png';
//import CEE from '../../assets/images/DFK/Partners/Community/CEE.png';
//import CIS from '../../assets/images/DFK/Partners/Community/CIS.png';
//import GCE from '../../assets/images/DFK/Partners/Community/GCE.png';
//import JA from '../../assets/images/DFK/Partners/Community/JA.png';
//import JAF from '../../assets/images/DFK/Partners/Community/JAF.png';
//import OF from '../../assets/images/DFK/Partners/Community/OF.png';

const PartnersGrid = () => {
  return (
    <div id="s7" className="dfk-section partnerships">
      <div className="partnerships-h1">
        We are grateful for the support of those who believe in providing equitable opportunities for our nation's youth
      </div>
      <div className="partnerships-h2">
        PAST & EXISTING PARTNERS
      </div>
      <div className="partnerships-flex"> 
        <div className="partnerships-item">
          <img loading="lazy" id="hsbc-logo" draggable="false" className="partnerships-img" alt="HSBC Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/HSBC.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="kpmg-logo" draggable="false" className="partnerships-img" alt="KPMG Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Mesirow.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="mesirow-logo" draggable="false" className="partnerships-img" alt="Mesirow Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Mesirow.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="ariel-logo" draggable="false" className="partnerships-img" alt="Ariel Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Ariel.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="cibc-logo" draggable="false" className="partnerships-img" alt="CIBC Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/CIBC.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="gcm-logo" draggable="false" className="partnerships-img" alt="GCM Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/GCM.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="horizon-logo" draggable="false" className="partnerships-img" alt="Horizon Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Horizon.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="magellan-logo" draggable="false" className="partnerships-img" alt="Magellan Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Magellan.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="mastercard-logo" draggable="false" className="partnerships-img" alt="Mastercard Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Mastercard.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="northwestern-mutual-logo" draggable="false" className="partnerships-img" alt="NW Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/NW.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="summit-logo" draggable="false" className="partnerships-img" alt="Summit Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/UCMedicine.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="uc-medicine-logo" draggable="false" className="partnerships-img" alt="UC Medicine Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Wintrust.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="wintrust-logo" draggable="false" className="partnerships-img" alt="Wintrust Logo for financial literacy summer camp" src="/images/DFK/Partners/Presenting/Wintrust.png" />
        </div>
      </div>
      <div className="partnerships-h2">
        VOLUNTEER ORGANIZATIONS
      </div>
      <div className="partnerships-flex"> 
        <div className="partnerships-item">
          <img loading="lazy" id="ab-logo" draggable="false" className="partnerships-img" alt="AB Logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/AB.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="fifth-third-logo" draggable="false" className="partnerships-img" alt="Fifth Third Logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/FifthThird.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="guggenheim-logo" draggable="false" className="partnerships-img" alt="Guggenheim Logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/Guggenheim.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="jpm-logo" draggable="false" className="partnerships-img" alt="JPM Chase Logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/JPMChase.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="loop-logo" draggable="false" className="partnerships-img" alt="Loop logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/Loop.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="morningstar-logo" draggable="false" className="partnerships-img" alt="Morningstar logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/Morningstar.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="state-farm-logo" draggable="false" className="partnerships-img" alt="State Farm logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/Wedbush.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="wedbush-logo" draggable="false" className="partnerships-img" alt="Wedbush logo for financial literacy summer camp" src="/images/DFK/Partners/Volunteer/Wedbush.png" />
        </div>
      </div>
      <div className="partnerships-h2">
        COMMUNITY COLLABORATORS
      </div>
      <div className="partnerships-flex"> 
        <div className="partnerships-item">
          <img loading="lazy" id="100bm-logo" draggable="false" className="partnerships-img" alt="100BM logo for financial literacy summer camp" src="/images/DFK/Partners/Community/100BM.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="cto-logo" draggable="false" className="partnerships-img" alt="CTO logo for financial literacy summer camp" src="/images/DFK/Partners/Community/CTO.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="ag-logo" draggable="false" className="partnerships-img" alt="AG logo for financial literacy summer camp" src="/images/DFK/Partners/Community/AG.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="asm-logo" draggable="false" className="partnerships-img" alt="ASM logo for financial literacy summer camp" src="/images/DFK/Partners/Community/ASM.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="cee-logo" draggable="false" className="partnerships-img" alt="CEE Logo for financial literacy summer camp" src="/images/DFK/Partners/Community/CEE.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="cis-logo" draggable="false" className="partnerships-img" alt="CIS logo for financial literacy summer camp" src="/images/DFK/Partners/Community/CIS.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="gce-logo" draggable="false" className="partnerships-img" alt="GCE logo for financial literacy summer camp" src="/images/DFK/Partners/Community/GCE.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="ja-logo" draggable="false" className="partnerships-img" alt="JA logo for financial literacy summer camp" src="/images/DFK/Partners/Community/JA.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="jaf-logo" style={{ width: "90%" }} draggable="false" className="partnerships-img" alt="JAF logo for financial literacy summer camp" src="/images/DFK/Partners/Community/JAF.png" />
        </div>
        <div className="partnerships-item">
          <img loading="lazy" id="of-logo" draggable="false" className="partnerships-img" alt="Obama Foundation logo for financial literacy summer camp" src="/images/DFK/Partners/Community/OF.png" />
        </div>
      </div>
    </div>
  );
};

export default PartnersGrid;