import React from 'react';
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaAboutScreen } from '../constants/metaDescriptions';
import MeetTheMindsHeader from '../components/About/MeetTheMindsHeader';
import TeacherPriority from '../components/About/TeacherPriority';
import DestinyStory from '../components/About/DestinyStory';
import WhyRapunzl from '../components/Home/WhyRapunzl';
import "../assets/styles/AboutScreen.css";
import GoldStandard from '../components/About/AboutDemo';

const AboutScreen = () => {
  return (
    <div style={{ background: 'radial-gradient(#aefbe7, #fafffe)' }}>
      <Helmet>
        <title>About Rapunzl | Why Financial Literacy Matters</title>
        <meta name="description" content={MetaAboutScreen} />
        <meta property="og:title" content="About Rapunzl | Why Financial Literacy Matters" />
        <meta property="og:description" content={MetaAboutScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/about" />
        <link rel="canonical" href="https://www.rapunzl.com/about" />
      </Helmet>
      <Header />
      <MeetTheMindsHeader />
      <DestinyStory />
      <TeacherPriority />
      <GoldStandard />
      <WhyRapunzl />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default AboutScreen;