import React from "react";

const SkillDevelopment = (props) => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: props.isParents ? '100px' : '10px', paddingBottom: props.isParents ? '160px' : '80px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="Simulated Investing Competition wiht middle and high school Scholarships" src="/images/Educators/SkillDevelopment.webp"/>
      <div>
        <h2 className="educator-header">
          {props.isParents ? '' : 'Immersive, '}Hard & Soft Skill Development{props.isParents ? ' For Your Kids' : ''}
        </h2>
        <div className="educator-text">
            {props.isParents ?
            'Parents leveraging Rapunzl can allow their students to learn about more than investing. Simulating a portfolio helps students develop hard skills such as financial analysis, investment strategy, and risk management with real-world decision-making.' 
            :
            'Simulating a portfolio helps students develop hard skills such as financial analysis, investment strategy, and risk management by engaging them in real-world financial decision-making.'}
          <br/>
          <br/>
            Gamified simulators also help cultivate soft skills like critical thinking, problem-solving, and emotional intelligence. {props.isParents ? 'Parents teaching their students finance can also ensure they are prepared to navigate the stock market under pressure while managing their emotions.' : 'As students learn to navigate market fluctuations and make informed decisions under pressure, our program helps build resiliency and confidence.'}
        </div> 
      </div>
    </div>
  );
};

export default SkillDevelopment;