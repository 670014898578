import React from "react";
import "../../assets/styles/EducatorsScreen.css";
import { Link } from "react-router-dom";

const StartingInvestmentClub = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '0px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="Rapunzl offers plug and play project based financial literacy" src="/images/Educators/InvestmentClub.webp"/>
      <div>
        <h2 className="educator-header">
          Let Rapunzl Bring An Investment Club To Your School
        </h2>
        <div className="educator-text">
        Rapunzl's financial literacy curriculum is perfect for establishing an investment club at your school. 
          <br /><br />
          Our personal finance curriculum aligns with our <Link className="article-link" to="/article/leveragingTheRapunzlSimulator">real-time investment simulator</Link> to help students dive into real-world market conditions in a risk-free, simulated environment.
          <br /><br />
          This allows interested students to dive deeper than core requirements and leverage Rapunzl’s mobile app to extend learning beyond the classroom.
          </div>
        <div className="get-in-touch-flex">
          <Link to="/educators">
            <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
              View Our Plug-And-Play Investment Club
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StartingInvestmentClub;