import React from 'react';
import { Link } from 'react-router-dom';

const ModularCurriculum = () => {
  return (
    <div className='modular-curriculum-container'>
      <img loading="lazy" alt='electric plug with brain for rapunzl industry leading curriculum' className='modular-curriculum-image' src='/images/Courses/plugIcon.webp'/>
      <h2 className='courses-header' style={{ color: '#ffb200', paddingTop: '15px' }}>
          Plug & Play
          <br/>
          Modular Curriculum
        </h2>
        <div className='courses-subheader' style={{ maxWidth: '500px', color: 'white' }}>
          Check out any of Rapunzl's 25 modules below, organized into 4 learning tracks that can provide a full-semester course or supplement an existing Math, Econ, Business or CTE course.
        </div>
        <div className='curriculum-column-flex'>
          <div className='curriculum-column'>
            <h3 className='curriculum-column-title'>
              Core Personal Finance
            </h3>
            <Link to="/module/basics-of-banking">
              <div className='curriculum-column-item'>
                Basics Of Banking
              </div>
            </Link>
            <Link to="/module/saving-versus-investing">
              <div className='curriculum-column-item'>
                Saving Versus Investing
              </div>
            </Link>
            <Link to="/module/power-risk-of-credit">
              <div className='curriculum-column-item'>
                Understanding Credit Cards
              </div>
            </Link>
            <Link to="/module/make-loans-work-for-you">
              <div className='curriculum-column-item'>
                Bonds & Debt
              </div>
            </Link>
            <Link to="/module/insurance-and-retirement">
              <div className='curriculum-column-item'>
                Insurance & Retirement
              </div>
            </Link>
            <Link to="/module/diversification-and-risk">
              <div className='curriculum-column-item'>
                Diversification & Risk
              </div>
            </Link>
            <Link to="/module/economy-and-federal-reserve">
              <div className='curriculum-column-item'>
                The Economy & Federal Reserve
              </div>
            </Link>
          </div>
          <div className='curriculum-column'>
            <h3 className='curriculum-column-title'>
              College, Career & Life
            </h3>
            <Link to="/module/taxes-and-income">
              <div className='curriculum-column-item'>
                Income & Taxes
              </div>
            </Link>
            <Link to="/module/careers-in-finance">
              <div className='curriculum-column-item'>
                Financial Services Careers
              </div>
            </Link>
            <Link to="/module/paying-for-college">
              <div className='curriculum-column-item'>
                Paying For College
              </div>
            </Link>
            <Link to="/module/buying-your-first-home">
              <div className='curriculum-column-item'>
                Buying Your First Home
              </div>
            </Link>
            <Link to="/module/business-buildings-ownership">
              <div className='curriculum-column-item'>
                Business of Buidings
              </div>
            </Link>
          </div>
          <div className='curriculum-column'>
            <h3 className='curriculum-column-title'>
              Financial Math
            </h3>
            <Link to="/module/financial-statistics">
              <div className='curriculum-column-item'>
                Statistics
              </div>
            </Link>
            <Link to="/module/financial-probabilities">
              <div className='curriculum-column-item'>
                Probabilities
              </div>
            </Link>
            <Link to="/module/financial-exponents">
              <div className='curriculum-column-item'>
                Exponents
              </div>
            </Link>
            <Link to="/module/financial-algebra">
              <div className='curriculum-column-item'>
                Financial Algebra
              </div>
            </Link>
            <Link to="/module/financial-equations">
              <div className='curriculum-column-item'>
                Financial Equations
              </div>
            </Link>
          </div>
          <div className='curriculum-column'>
            <h3 className='curriculum-column-title'>
              Stocks & Investing
            </h3>
            <Link to="/module/welcome-to-the-stock-market">
              <div className='curriculum-column-item'>
                The Stock Market
              </div>
            </Link>
            <Link to="/module/what-makes-good-stock">
              <div className='curriculum-column-item'>
                What Makes A Good Stock?
              </div>
            </Link>
            <Link to="/module/top-investor-strategies">
              <div className='curriculum-column-item'>
                Top Investor Strategies
              </div>
            </Link>
            <Link to="/module/reading-financial-charts">
              <div className='curriculum-column-item'>
                Reading Financial Charts
              </div>
            </Link>
            <Link to="/module/etfs-and-mutual-funds">
              <div className='curriculum-column-item'>
                ETFs & Mutual Funds
              </div>
            </Link>
            <Link to="/module/understanding-shorting-stock">
              <div className='curriculum-column-item'>
                Understanding Shorting
              </div>
            </Link>
            <Link to="/module/reading-company-financials">
              <div className='curriculum-column-item'>
                Reading Financials & 10-Ks
              </div>
            </Link>
            <Link to="/module/esg-social-impact-investing">
              <div className='curriculum-column-item'>
                ESG & Social Impact Investing
              </div>
            </Link>
          </div>
        </div>
        <div className='courses-subheader' style={{ maxWidth: '500px', color: 'white', borderTop: '8px solid #ffb202', marginTop: 40, paddingTop: 15 }}>
          All modules are aligned with the Council of Economic Education’s National Financial Education Standards, as well as state standards.
        </div>
        <Link to='/requestStandards'>
          <div className='portal-demo-button' style={{ margin: 'auto', marginTop: '45px' }}>
            See How We Align With Your State Standards
          </div>
        </Link>
    </div>
  );
};

export default ModularCurriculum;