import React from 'react';
import { Link } from 'react-router-dom';

const StandardsRequestScreen = (props) => {
  if (props.status === 'success') {
    return (
      <div className='standards-request-container' style={{ paddingBottom: 260 }}>
        <div className='standards-request-progress-bar'>
          <div className='standards-request-progress-bar-inner' />
          <div className='standards-request-progress-bar-inner' />
          <div className='standards-request-progress-bar-inner' />
          <div className='standards-request-progress-bar-inner' />
          <div className='standards-request-progress-bar-inner' />
        </div>
        <h2 className='request-h2' style={{ fontSize: 36 }}>
          You're All Set!
        </h2>
        <div className='request-p'>
          Thanks for sharing that information {props.firstName}! We're excited to explore bringing Rapunzl to {props.school} and share demo access.
          <br/><br/>
          The Rapunzl team supporting educators in {!!props.state ? props.state : 'your area'} will be reaching out in the next few days with a state-specific implementation.
          <br/><br/>
          There's no need to do anything else and we do our best to respond promptly, but feel free to book a call below if you have any specific timing concerns.
        </div>
        <a href="https://www.link.rapunzl.com/book-call">
          <div className='book-call-request-button'>
            Book A Call
          </div>
        </a>
        <Link to="/">
          <div className='alt-book-call-button'>
            Back To Home
          </div>
        </Link>
      </div>
    )
  } else {
    return (
      <div className='standards-request-container' style={{ paddingBottom: 260 }}>
        <h2 className='request-h2' style={{ fontSize: 36, color: '#feaa07' }}>
          Something Went Wrong...
        </h2>
        <div className='request-p' style={{ maxWidth: 420 }}>
          We hate making a bad first impression, but unfortunately we had an issue submitting your information to the Rapunzl team that helps support educators.
          <br/><br/>
          We'd love to help expore bringing Rapunzl to {props.school} and provide demo access, but you may need to contact us through our main support channel or email us directly.
        </div>
        <Link to="/contact">
          <div className='book-call-request-button'>
            Contact Us
          </div>
        </Link>
        <a href="mailTo:hello@rapunzl.com">
          <div className='alt-book-call-button'>
            Email Directly
          </div>
        </a>
      </div>
    )
  }
};
export default StandardsRequestScreen;