import React from "react";

const SeperatePortfolios = () => {
  return (
    <div className="crypto-s2">
      <div className="s2-flex">
        <div className="s2-left">
          <h2 className="s2-header">
            Seperate Simulated Portfolios.
            <br/>
            <span style={{ color: '#ff8800' }}>
            Integrated Learning.
            </span>
          </h2>
          <div className="s2-text">
            Students can simulate $10,000 crypto portfolios alongside their Rapunzl stock portfolio to learn about Web3, blockchain technology & the future of crypto.
            <br /><br />
            Leveraging real-time prices directly from Coinbase, students experience an immersive, risk-free crypto trading experience.
          </div>
          <div className="s2-button-flex">
            <a to="https://www.rapunzl.app">
              <div className="button aboutButton">
                Join Rapunzl Crypto Now
              </div>
            </a>
          </div>
        </div>
        <img loading="lazy" className="s2-image" id="2CryptoPhones" alt="Rapunzl real-time Crypto simulator Phone App" src="/images/Crypto/Crypto2Phones.webp" />
      </div>
    </div>
  );
};

export default SeperatePortfolios;