import React from "react";
import { useState } from "react";
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import FAQ from "../../constants/FAQ";
import '../../assets/styles/FAQHome.css';

const FAQHome = () => {
  // State to keep track of which answer is visible
  const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(null);

  // Toggle function to show/hide answers
  const toggleAnswer = (index) => {
    setVisibleAnswerIndex(visibleAnswerIndex === index ? null : index);
  };

  return (
    <div className="faq-home-container">
      <div className='flex-courses-dot-header' style={{ paddingTop: 0 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
      <div className="faq-title">
        Frequently Asked<br/>Questions
      </div>
      {FAQ.map((item, index) => {
        return (
          <div key={'a' + index} className="faq-item" onClick={() => toggleAnswer(index)}>
            <div className="faq-question-flex" style={{ borderBottomWidth: visibleAnswerIndex === index ? 0 : 2 }}>
              <div className="faq-question-text">
                {item.question}
              </div>
              {visibleAnswerIndex === index ? (
                <ArrowDropDownOutlinedIcon className="faq-arrow" />
              ) : (
                <ArrowRightOutlinedIcon className="faq-arrow" />
              )}
            </div>
            {visibleAnswerIndex === index && (
              <div className="faq-answer-container">
                {item.answers.map((answer, index2) => {
                  return (
                    <div key={'b' + index2} className="faq-answer">
                      {answer}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
};

export default FAQHome;