const MetaAboutScreen = `Why the name Rapunzl? We’re bringing personal finance to life for students and ensuring every high schooler graduates financially literate and prepared.`;
const MetaArticleScreen = `Explore Rapunzl's financial literacy resources. Leverage our platform to access tools to teach & empower students and enhance personal finance education.`;
const MetaBlogScreen = `Rapunzl’s blog provides the latest insights on teaching personal finance, bringing financial literacy to a classroom, and personal investing trends.`;
const MetaCompetitionRulesScreen = `Understand the rules of Rapunzl's simulated investing competitions. Learn personal finance and investing through our exciting, educational challenges.`;
const MetaContactScreen = `Have questions about Rapunzl's financial literacy curriculum? Contact us to learn more about how we can help you teach personal finance and investing.`;
const MetaCoursesScreen = `Explore Rapunzl's personal finance curriculum and prebuilt courses that leverage our investment simulator to drive engagement and solidify learning.`
const MetaCryptoScreen = `Simulated crypto investing, free for all students! Join with our real-time simulator, enter the competition & earn while you learn to invest in crypto.`;
const MetaDemoScreen = `Sign up for a free demo of Rapunzl's personal finance curriculum and real-time investment simulator to see how Rapunzl can bring financial literacy to life.`;
const MetaDistrictsScreen = `Learn how Rapunzl empowers district leaders to improve math and personal finance outcomes in their districts with real-world learning tools.`;
const MetaDFKScreen = `Discover how DFK supports Rapunzl to bring financial literacy to life and grow adoption of our personal finance curriculum in low-income communities.`;
const MetaEducatorsScreen = `Learn how Rapunzl's personal finance curriculum and simulator can increase engagement while satisfying graduation requirements in your state.`;
const MetaFreeTrial = `Try Rapunzl's Educator Dashboard for free! Sign up to access Rapunzl's curriculum and explore how our personal finance courses can fit in your class.`;
const MetaHomeScreen = `Join & bring financial literacy to life with Rapunzl's gamified investment simulator and personal finance curriculum for middle and high school students!`;
const MetaLegalScreen = `Review Rapunzl's legal policies and guidelines to ensure you understand our commitment to providing a safe and collaborative learning experience.`;
const MetaNationalStandards = `Learn how Rapunzl's real-time investment simulator and curriculum aligns with the Counicl of Econoimc Education's 6 Core Pillars of Financial Literacy.`;
const MetaNotFoundPage = `Oops! You must have drifted off into cyberspace. Check out Rapunzl’s main site to learn more about our personal finance program for your school.`;
const MetaParentsPage = `We help parents teach their kids essential financial life skills through our real-time simulator, personal finance curriculum & real-world learning.`;
const MetaPartnersScreen = `Evaluating your firm’s impact? Partner with Rapunzl to support our personal finance curriculum and bring financial literacy to more schools.`;
const MetaPortalScreen = `Discover how Rapunzl’s Educator Portal blends our personal finance curriculum with tools to monitor your classroom’s progress & investing experience.`;
const MetaPressScreen = `Rapunzl's latest news and press releases. Learn how we are revolutionizing personal finance education with our gamified investment simulator.`;
const MetaPrivacyPolicyScreen = `See how Rapunzl’s platform tools are SOPPA, COPPA and GDPR compliant and adhere to industry best-practices as it pertains to protecting student data.`;
const MetaRequestStandards = `Find out how Rapunzl's personal finance curriculum meets state graduation requirements and aligns with national financial literacy standards.`;
const MetaStateStandards = `Learn how different states have implemented financial literacy graduation requirements and how Rapunzl's comprehensive curriculum aligns with standards.`
const MetaSummer2024Screen = `Join our Financial Literacy Summer Camp and dive into our curriculum while meeting with experts from some of the largest financial firms in the country.`;
const MetaTermsOfUseScreen = `Read Rapunzl's Terms of Use to understand the guidelines for using our personal finance curriculum and simulated investing tools with your students.`;

export {
	MetaAboutScreen,
	MetaArticleScreen,
	MetaBlogScreen,
	MetaCompetitionRulesScreen,
	MetaContactScreen,
	MetaCoursesScreen,
	MetaCryptoScreen,
	MetaDemoScreen,
	MetaDistrictsScreen,
	MetaDFKScreen,
	MetaEducatorsScreen,
	MetaFreeTrial,
	MetaHomeScreen,
	MetaLegalScreen,
	MetaNationalStandards,
	MetaNotFoundPage,
	MetaParentsPage,
	MetaPartnersScreen,
	MetaPortalScreen,
	MetaPressScreen,
	MetaPrivacyPolicyScreen,
	MetaRequestStandards,
	MetaStateStandards,
	MetaSummer2024Screen,
	MetaTermsOfUseScreen
}