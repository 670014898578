import React from "react";

const AllAccessPass = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '10px', paddingBottom: '50px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="all-access pass to Rapunzl financial literacy demo for educators and parents" src="/images/Demo/VIPPass.webp"/>
      <div>
        <h2 className="educator-header">
          Your All-Access Pass To Rapunzl For Educators
        </h2>
        <div className="educator-text">
          Unlock a world of resources and support with Rapunzl's all-access pass for educators. 
          <br /><br />
          From comprehensive lesson plans to engaging activities, you’ll have access to a wealth of curriculum resources and live support to help you take advantage of the platform.
        </div>
      </div>
    </div>
  );
};

export default AllAccessPass;