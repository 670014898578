import React from "react";
import "../../assets/styles/HomeScreen.css";

const GoogleCloud = () => {
  return (
    <div className="home-school-banner-container" style={{ background: 'none' }}>
      <img loading="lazy" alt="google cloud logo showing compatability with Rapunzl curriculum" className="google-image" src="/images/Educators/GoogleCloudLogo.webp" style={{ marginTop: '20px' }} />
      <h2 className="educator-text" style={{ color: '#054030', paddingTop: 20, width: '90%', margin: 'auto' }}>
        Rapunzl's curriculum is hosted through Google Cloud
      </h2>
      <div className="educator-text" style={{ color: '#054030', width: '90%', margin: 'auto', marginBottom: 35 }}>
        This ensures that Rapunzl's personal finance curriculum is accessible for every educator and student by leveraging Google's suite of accessibility features.
      </div>
    </div>
  );
};

export default GoogleCloud;