import React from "react";

const CoinbaseSponsorship = () => {
  return (
    <div className="crypto-s5">
      <img loading="lazy" className="s5-image" id="cryptoPizza" alt="Rapunzl real-time crypto data speed" src="/images/Crypto/CryptoPizzaFast.webp" />
      <h2 className="s5-header">
        Rapunzl Crypto Prices Are Fast...
      </h2>
      <div className="s5-text">
        All pricing data is streamed directly from the Coinbase exchange in real-time, providing unparalleled access to crypto markets.
        <br /><br />
        Prices update with data feeds that stream directly to your phone and there are no transaction fees for every trade you place. Everything is free and designed to help you experience the most realistic trading experience possible.
      </div>
      <div className="s5-subtext">
        POWERED BY
      </div>
      <img loading="lazy" className="s5-image-2" id="coinbase" alt="Coinbase partnered with Rapunzl to provide crypto data" src="/images/Crypto/Coinbase.webp" />
    </div>
  );
};

export default CoinbaseSponsorship;