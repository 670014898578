import React from "react";
import { Link } from "react-router-dom";

const WhatToExpect = () => {
  return (
    <div className="what-to-expect">
      <h2 className="section-header" style={{ paddingBottom: "0px" }}>
        Rapunzl Helps Learn How To Build Generational Wealth...
      </h2>
      <div className="section-header-sub">
        Together, We Can Change The Future Face of Finance
      </div>
      <div className="section-subtext">
        Dreams For Kids is hosting a Financial Literacy Summer Bootcamp which will help underserved students grasp key financial concepts & spark curiosities on how to thrive in financial services careers & pursue family-sustaining careers.
      </div>
      <div className="section-subtext">
        Our real-world, experiential approach exposes students to a <Link className="article-link" to="/article/leveragingTheRapunzlSimulator">real-time investing simulator</Link> which allows students to earn prizes while they learn about investing. We also offer interactions with industry experts that can help with future mentor & internship opportunities.
      </div>
      <div className="section-subtext">
        Participating students will receive a Financial Literacy Certification from Dreams For Kids, along with a funded custodial brokerage account, which means every participating student will leave the Bootcamp a shareholder.
      </div>    
    </div>
  );
};

export default WhatToExpect;