// URLs for WebSocket 
// Production Websockets URL
//const WS_URL = "https://rapunzl.org:8030";
// Staging Websockets URL
//const WS_URL = "https://rapunzled.org";
// URLs For GraphQL
// Production GrapQL URL
//const GRAPHQL_URL = "https://rapunzl.org:8443/graphql/";
// Staging GrapQL URL
const GRAPHQL_URL = "https://rapunzl.com/graphql/";
const APP_ENV = "Development";
export { GRAPHQL_URL, APP_ENV }