import React from 'react';

const ArticleTextSection = (props) => {
  return (
    <div className='article-text-section'>
      <h2 className='article-text-subheader'>
        {props.subtitle}
      </h2>
      {props.text.map((item, index) => {
        return (
          <div key={index} className='article-text-content'>
            {item}<br/><br/>
          </div>
      )})}
    </div>
  );
};
export default ArticleTextSection;