import React from "react";
import "../../assets/styles/HomeScreen.css";

const EarnWhileYouLearn = () => {
  return (
    <div className="section first-section">
      <div className="left">
        <img src="/images/Home/Phones/MobilePhone.webp" className="mobile-S1-phone" alt="rapunzl mobile app phone screenshot iOS & Android" />
        <h2 className="user-line-1"><span className="bolder">
          Earn while you learn</span><br />with simulated investing competitions
        </h2>
        <div className="educator-text">
          Rapunzl empowers students to learn-by-doing with $10,000 simulated portfolios & integrated personal finance curriculum.
          <br/><br/>
          Students buy and sell ETFs and publicy-traded stocks in real-time, investing their portfolio throughout the course.
        </div>
        <a href="https://www.rapunzl.app">
          <div className="s1-join-web-button">
            Sign-Up For Free Today
          </div>
        </a>
      </div>
      <div className="right">
        <img src="/images/Home/Phones/Phone1.webp" className="phone-one" alt="rapunzl mobile app phone screenshot iOS" />
      </div>
    </div>
  );
};

export default EarnWhileYouLearn;