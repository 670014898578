import React from "react";

const DestinyStory = () => {
  return (
    <div>
      <div className="standards-header-flex about-screen-header-flex" style={{ marginBottom: 45, alignItems: 'center' }}>
        <div className="destiny-meet-minds-block-text">
          <span style={{ color: '#007154' }}>Our inaugural scholarship winner in 2018, Destiny Davis, attended an alternative high school on Chicago’s Westside, and was on track to attend the military after graduation.</span>
          <br/><br/>
          <span  style={{ fontWeight: '300', color: '#007154', fontSize: 20 }}>
          Rapunzl’s scholarship allowed Destiny to continue her studies at Northeastern Illinois University and graduate in 2022.
          <br/><br/>
          She has since interned at some of the most prestigious financial firms in the country, rang the opening bell at Nasdaq in Times Square, and enrolled in a Master’s program at Michigan State.
          </span>
        </div>
        <img className="about-destiny-image" alt="Brian Curcio Myles Gage Forbes Under 30" src="/images/About/DestinyCollage.png"/>
      </div>
      <iframe 
        src="https://www.youtube.com/embed/R9EyNeI0DuM" 
        width="1920" 
        height="1080" 
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
        title="Bring Financial Literacy To Life"
        className="iframe-video"
        style={{ marginBottom: 95 }}
        >
        </iframe>
    </div>
  );
};

export default DestinyStory;