import React from 'react';
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import "../assets/styles/DemoScreen.css";
import { MetaDemoScreen } from '../constants/metaDescriptions';
import AllAccessPass from '../components/Demo/AllAccessPass';
import BuildCourse from '../components/Demo/BuildCourse';
import ExploreLibrary from '../components/Demo/ExploreLibrary';
import ThirtyDayTrial from '../components/Demo/ThirtyDayTrial';
import UploadStudents from '../components/Demo/UploadStudents';
import DataPrivacy from '../components/Home/DataPrivacy';
import DemoInputContainer from '../components/Demo/DemoInputContainer';
import SelectState from '../components/StandardsRequest/SelectState';

const DemoScreen = () => {
  return (
    <div style={{ background: 'radial-gradient(#aefbe7, #fafffe)' }}>
      <Helmet>
        <title>Rapunzl | Free Teacher Demo</title>
        <meta name="description" content={MetaDemoScreen} />
        <meta property="og:title" content="Rapunzl | Free Teacher Demo" />
        <meta property="og:description" content={MetaDemoScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/demo" />
        <link rel="canonical" href="https://www.rapunzl.com/demo" />
      </Helmet>
      <Header />
      <SelectState demo={true} />
      <ThirtyDayTrial />
      <AllAccessPass />
      <ExploreLibrary />
      <BuildCourse />
      <UploadStudents />
      <DataPrivacy darkMode={true} />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default DemoScreen;