import React from 'react';

const ArticleFAQSection = (props) => {
  return (
    <div className='article-faq-section'>
      <h2 className='article-text-subheader'>
        Frequently Asked Questions
      </h2>
      {props.content.map((item, index) => {
        return (
          <div key={index} className='article-faq-item'>
            <div className='article-faq-item-question'>
              {item.question}
            </div>
            <div className='article-faq-item-answer'>
              {item.answer}
            </div>
          </div>
      )})}
    </div>
  );
};
export default ArticleFAQSection;