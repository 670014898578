import React from "react";
import { Link } from "react-router-dom";

const ThirtyDayTrial = () => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '60px', paddingBottom: '70px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="30-day-trial to access Rapunzl curriculum for free" src="/images/Demo/30DayBadge.webp"/>
      <div>
        <h2 className="educator-header">
          Free 30-Day Trial For Rapunzl In Your Classroom
        </h2>
        <div className="educator-text">
          Experience the power of Rapunzl in your classroom with a free 30-day trial and engage your students with interactive financial simulations and real-world investing challenges designed to enhance their financial literacy. 
          <br/><br/>
          Leverage Rapunzl as a supplementary learning tool and <Link className="article-link" to="/article/buildingTheRightPersonalFinanceCourse">build your own personal finance course</Link> or explore integrating our comprehensive, full-semester personal finance course.
        </div>  
      </div>
    </div>
  );
};

export default ThirtyDayTrial;