import React from "react";

const ManagingRiskStandard = () => {
  return (
    <div className="educator-section reverse-educator-section national-standard-container" style={{ margin: 'auto' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="30-day-trial to access Rapunzl curriculum for free" src="/images/Standards/ManagingRisk.webp"/>
      <div  className="standard-margin-shift-1">
        <h2 className="national-standard-subtext">
          Financial Literacy Strand 5:
          <br/>
          <span className="national-standard-bold">Managing Risk</span>
        </h2>
        <div className="educator-text" style={{ width: '100%' }}>
          This financial literacy standard helps students recognize and mitigate potential financial risks. 
          <br/><br/>
          Managing risk includes understanding insurance, diversification, and emergency funds, empowering students to protect their assets and minimize losses in unpredictable situations. 
          <br/><br/>
          By learning these skills, students are better prepared to navigate financial uncertainties and make decisions that safeguard their financial future, promoting resilience and financial stability.
        </div>  
      </div>
    </div>
  );
};

export default ManagingRiskStandard;