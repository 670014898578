import React from "react";
import { Link } from "react-router-dom";

const ThinkBiggerMap = () => {
  return (
    <div className="dfk-section pitch">
      <div className="section-flex pitch-overview-section">
        <div className="pitch-overview-left">
          <img loading="lazy" id="bull-img" draggable="false" className="pitch-overview-img" alt="Rapunzl Fianncial Markets Bull Image" src="/images/DFK/Bull.jpg" />
        </div>
        <div className="pitch-overview-right">
          <h2 className="pitch-overview-title">
            RAPUNZL'S IMPACT SINCE 2018
          </h2>
          <div className="pitch-overview-h1">
            We've hosted a simulated investing competition with scholarships for middle & high school students.
          </div>
          <div className="pitch-overview-p">
            Students simulate portfolios, learn about the stock market, and apply what they're learning as they compete for scholarships.
          </div>
          <Link to="/contact">
            <div className="pitch-document-button">
              <div className="pitch-document-button-text">
                Talk To Rapunzl About Scholarships
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThinkBiggerMap;