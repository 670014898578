import React from "react";
import { Link } from "react-router-dom";

const TeacherPriority = () => {
  return (
    <div className="standards-header-container">
      <div className="standards-header-flex" style={{ flexDirection: 'column', marginBottom: '-60px' }}>
        <h1 className="standards-h1 teacher-prioirty-h1">
          We've Taught Hundreds Of Classrooms & Understand The Need For<span style={{ color: '#ffb200' }}> High-Quality, Plug & Play Resources.</span>
        </h1>
        <div className="teacher-priority-subtext-alignment">
          <div className="teacher-priority-text">
            <span style={{ color: '#FAFAFA', fontSize: 34, fontWeight: '900' }}>
            That's why we created Rapunzl in 2017.
            </span>
            <br/><br/>
            <span style={{ fontSize: 20, fontWeight: '300' }}>
            Our simulator inspires student engagement and our standards-aligned curriculum to empower students with lifelong financial skills.
            <br/><br/>
            We also provide ongoing support, comprehensive professional development, and minute-by-minute teaching guides so that any teacher can bring Rapunzl into a classroom.
            </span>
          </div>
        </div>
        <Link to="/demo">
          <div className="request-demo-teacher-priority-button">
            Request Free Rapunzl Demo
          </div>
        </Link>
      </div>
    </div>
  );
};

export default TeacherPriority;