import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import SkillDevelopment from "../components/Educators/SkillDevelopment";
import { Helmet } from 'react-helmet-async';
import { MetaCoursesScreen } from "../constants/metaDescriptions";
import "../assets/styles/CoursesScreen.css";
import ModularCurriculum from "../components/Courses/ModularCurriculum";
import DataPrivacy from "../components/Home/DataPrivacy";
import CourseCatalog from "../components/Courses/CourseCatalog";
import GoogleCloud from "../components/Home/GoogleCloud";
import PresentationGuides from "../components/Educators/PresentationGuides";
import InAModule from "../components/Educators/InAModule";
import Accessibility from "../components/Educators/Accessibility";

const CoursesScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Personal Finance Courses</title>
        <meta name="description" content={MetaCoursesScreen} />
        <meta property="og:title" content="Rapunzl | Personal Finance Courses" />
        <meta property="og:description" content={MetaCoursesScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/courses" />
        <link rel="canonical" href="https://www.rapunzl.com/courses" />
      </Helmet>
      <Header />
      <div className="route-subcontainer gradient-route">
        <img alt="money lifting weights for rapunzl personal finance curriculum" className="course-route-image-header" src="/images/Courses/FinancialFitness.webp" />
        <CourseCatalog alt={true} />
        <SkillDevelopment />
        <PresentationGuides isParents={true} />
        <GoogleCloud />
        <ModularCurriculum />
        <InAModule />
        <Accessibility />
        <DataPrivacy />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default CoursesScreen;