import React from "react";

const HowRapunzlWorksVideo = () => {
  return (
    <div className="how-rapunzl-works-container">
      <h2 className="iframe-video-text">
        See How To Bring<br/>
        <span className="iframe-video-subtext">
          Financial Literacy To Life
          </span>
      </h2>
      <iframe 
        src="https://player.vimeo.com/video/882657140?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
        width="1920" 
        height="1080" 
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
        title="Bring Financial Literacy To Life"
        className="iframe-video"
        >
        </iframe>
    </div>
  );
};

export default HowRapunzlWorksVideo;