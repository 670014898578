import React, { useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "../../assets/styles/Admin/Header.css";

const Header = () => {
  // Tracks If Mobile Menu Is Visible
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);

  return (
    <header className="header-container">
      <div className="header-width-restriction">
        <Link to="/">
          <img alt="rapunzl logo green investing castle" src="/images/Admin/CompanyLogos/GreenLogo.png" className="left-button-header" id="castle"/>
        </Link>
        <div className="options-flex">
          <Link to="/demo">
            <div className="text-button-header join-header-button">
              Request Demo
            </div>
          </Link>
          <div className="login-dropdown">
            <div className="text-button-header dropdown-carat-flex">
              <div className="header-text-option">
                Login
              </div>
              <ArrowDropDownIcon className="dropdown-carat-icon" />
            </div>
            <div className="login-dropdown-content">
              <a href="https://www.rapunzl.app">
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dashboard menu item simulator item" className="header-dropdown-image2" src="/images/Courses/LearningConcepts/PersonalFinance/Budgeting.png" style={{ backgroundColor: '#024030', height: 28, width: 28, padding: '5px', borderRadius: '10px' }} />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Simulator
                    </div>
                    <div className="header-dropdown-text">
                      Access Rapunzl's real-time stock & crypto investment simulator.
                    </div>
                  </div>
                </div>
              </a>
              <a href="https://www.rapunzlteacher.com">
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dashboard menu item dashboard item" className="header-dropdown-image2" src="/images/Courses/LearningConcepts/CollegeCareers/FinancialAid.png" style={{ backgroundColor: '#024030', height: 28, width: 28, padding: '5px', borderRadius: '10px' }} />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Dashboard
                    </div>
                    <div className="header-dropdown-text">
                      Allows educator to access curriculum, collect grades & manage their classroom.
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="solutions-dropdown" >
            <Link to="/educators">
              <div className="text-button-header dropdown-carat-flex">
                <div className="header-text-option">
                  Solutions
                </div>
                <ArrowDropDownIcon className="dropdown-carat-icon" />
              </div>
            </Link>
            <div className="solutions-dropdown-content">
              <Link to="/educators">
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dropdown menu item educators item" className="header-dropdown-image2" src="/images/Admin/HeaderIcons/Header_Educators.png" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Educators
                    </div>
                    <div className="header-dropdown-text">
                      See why educators across the country leverage Rapunzl in the classroom
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/districts">
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dropdown menu item districts item" className="header-dropdown-image2" src="/images/Admin/HeaderIcons/Header_Districts.png" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Districts
                    </div>
                    <div className="header-dropdown-text">
                      Discover how Rapunzl can bring financial literacy to an entire district.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to='/parents'>
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dropdown menu item parents item" className="header-dropdown-image2" src="/images/Admin/HeaderIcons/Header_Families.png" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Parents
                    </div>
                    <div className="header-dropdown-text">
                      Explore bringing Rapunzl to your homeschool for your kid.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/partners">
                <div className="header-dropdown-item" style={{ borderBottomWidth: 0 }}>
                <img loading="lazy" alt="Rapunzl dropdown menu item banks credit unions item" className="header-dropdown-image2" src="/images/Admin/HeaderIcons/Header_Banks.png" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Banks & Credit Unions
                    </div>
                    <div className="header-dropdown-text">
                      Learn how Rapunzl helps serve communities sponsored by financial institutions.
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="courses-dropdown">
            <Link to="/courses">
              <div className="text-button-header dropdown-carat-flex" style={{ marginRight: 9 }}>
                <div className="header-text-option">
                  Courses
                </div>
                <ArrowDropDownIcon className="dropdown-carat-icon" />
              </div>
            </Link>
            <div className="courses-dropdown-content">
              <Link to="/course/financialMath">
                <div className="header-dropdown-item">
                  <img loading="lazy" alt="Rapunzl dropdown menu item educators financial math badge" className="header-dropdown-image" src="/images/Courses/FinancialMath621.webp" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Financial Math
                    </div>
                    <div className="header-dropdown-text">
                      Integrate personal finance into any 6-8th grade math class.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/course/personalFinance">
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dropdown menu item educators personal finance badge" className="header-dropdown-image" src="/images/Courses/PersonalFinance621.webp" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Personal Finance
                    </div>
                    <div className="header-dropdown-text">
                      Build financial literacy & satisfy graduation requirements with 6-12th graders.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/course/collegeCareers">
                <div className="header-dropdown-item">
                <img loading="lazy" alt="Rapunzl dropdown menu item educators college careers badge" className="header-dropdown-image" src="/images/Courses/CollegeCareers621.webp" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      College & Careers
                    </div>
                    <div className="header-dropdown-text">
                      Learn about careers, taxes, and paying for college. For 9-12th graders.
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/course/advancedInvesting">
                <div className="header-dropdown-item" style={{ borderBottomWidth: 0 }}>
                <img loading="lazy" alt="Rapunzl dropdown menu advanced investing" className="header-dropdown-image" src="/images/Courses/AdvancedInvesting621.webp" />
                  <div className="header-dropdown-text-container">
                    <div className="header-dropdown-title">
                      Advanced Investing
                    </div>
                    <div className="header-dropdown-text">
                      For 11-12th graders interested in understanding fundamental & technical analysis.
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          
        </div>
        <img
          loading="lazy"
          className="hamburger-icon"
          src="/images/Admin/Hamburgers/Dark.png"
          alt="rapunzl mobile hamburger icon"
          onClick={() => setMobileMenuVisibility(true)}
        />
      </div>
      <div className="sliding-menu" style={{ display: isMobileMenuVisible ? 'block' : 'none' }}>
        <div className="sliding-menu-flex">
          <div className="sliding-menu-icon" />
          <div className="sliding-menu-title">
            Rapunzl
          </div>
          <CloseIcon className="sliding-menu-icon" onClick={() => setMobileMenuVisibility(false)} />
        </div>
        <Link to="/educators">
          <div className="sliding-menu-item">
            Educators
          </div>
        </Link>
        <Link to="/districts">
          <div className="sliding-menu-item">
            Districts
          </div>
        </Link>
        <Link to="/parents">
          <div className="sliding-menu-item">
            Parents
          </div>
        </Link>
        <Link to="/courses">
          <div className="sliding-menu-item">
            Courses
          </div>
        </Link>
        <Link to="/partners">
          <div className="sliding-menu-item">
            Partners
          </div>
        </Link>
        <Link to="/about">
          <div className="sliding-menu-item">
            About Us
          </div>
        </Link>
        <Link to="/contact">
          <div className="sliding-menu-item">
            Contact
          </div>
        </Link>
        <a href="https://www.rapunzl.app">
          <div className="sliding-menu-item">
            Simulator Login
          </div>
        </a>
        <a href="https://www.rapunzlteacher.com">
          <div className="sliding-menu-item">
            Educator Login
          </div>
        </a>
        <Link to="/demo">
          <div className="sliding-menu-item">
            Request Demo
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;