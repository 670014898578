import React from "react";
import "../../assets/styles/EducatorsScreen.css";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { Link } from "react-router-dom";

const ElevateMath = () => {
  return (
    <div className="educator-section" style={{ paddingTop: '50px', marginTop: '0px', paddingBottom: '50px' }}>
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="rapunzl curriculum helps improve math proficiency with students" src="/images/Educators/ElevateMath.webp"/>
      <div>
        <h2 className="educator-header">
          Improve Math Proficiency While Learning About Money With Rapunzl
        </h2>
        <div className="educator-text">
          Elevate math skills while building a strong understanding of essential financial life skills. 
          <br /><br />
          Digital and print activities help <Link className="article-link" to="/article/financialLiteracyInContext">contextualize learning</Link> objects with real-world scenarios, tying students’ simulated portfolios to math subjects including:
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <h4 className="educator-bullet-text">
            Probabilities
          </h4>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <h4 className="educator-bullet-text">
            Statistics
          </h4>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <h4 className="educator-bullet-text">
            Exponents
          </h4>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <h4 className="educator-bullet-text">
            Equations
          </h4>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <h4 className="educator-bullet-text">
            Pre-Algebra
          </h4>
        </div>
        <div className="get-in-touch-flex">
          <Link to="/courses">
            <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
              View Rapunzl Course Catalog
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ElevateMath;