import React from "react";

const RealTimeData = () => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '0px', paddingBottom: '60px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="High School & Financial Literacy Real-Time Data With Scholarships" src="/images/Educators/RealTimeData.webp"/>
      <div>
        <h2 className="educator-header">
          Real-Time Data Updates
        </h2>
        <div className="educator-text">
          Rapunzl pricing data streams directly from the Nasdaq exchange, ensuring that students can experience market changes as they happen, making financial concepts more tangible and relatable.
          <br/>
          <br/>
          This realistic approach inceases students engagement, contexualizes learning, and fosters critical thinking to equip students for the real-world.
        </div> 
      </div>
    </div>
  );
};

export default RealTimeData;