import React from "react";

const CryptoCoinCoverage = () => {
  return (
    <div className="crypto-s4" style={{ backgroundImage: `url(/images/Crypto/CryptoCoinsBG.jpg)` }}>
      <div className="s4-container">
        <h2 className="s4-header">
          We’ve Got Crypto Coins
        </h2>
        <div className="s4-text">
          Whether you are a new or expert crypto trader, Rapunzl is simple and intuitive to start trading.
          <br /><br />
          With 31 available cryptocurrencies and price updates streaming in real-time, we provide an unmatched trading simulation for even the most sophisticated of investors.
        </div>
      </div>
    </div>
  );
};

export default CryptoCoinCoverage;