import React from "react";
import { Link } from "react-router-dom";

const IntroductionTop = () => {
  return (
    <div className="intro-section">
      <img alt="Rapunzl Platform multi device accessibility for financial literacy in classrooms " src="/images/Home/MultiDevice621.webp" className="intro-image" />
      <h1 className="intro-text-h1">
        Bring <span>Financial Literacy</span> To <span style={{ fontWeight: "900", color: "#ff8800" }}>Life</span>
      </h1>
      <div className="intro-text-p">
        Teach personal finance with Rapunzl’s real-time simulator,<br/>standards-aligned curriculum & national scholarship competitions.
      </div>
      <div className="intro-text-h1-mobile">
        Bring <span>Financial Literacy</span>
        <br/>
        To <span style={{ fontWeight: "900", color: "#ff8800" }}>Life</span>
      </div>
      <div className="intro-text-p-mobile">
        Rapunzl’s real-time simulator & integrated teacher platform inspire real-world learning and engage students with standards-aligned curriculum.
      </div>
      <div className="introduction-button-flex-container">
        <Link to="/educators">
          <div className="intro-sign-up-button" style={{ marginRight: '15px' }}>
            View Tools For Educators
          </div>
        </Link>
        <a href="https://www.link.rapunzl.com/book-call">
          <div className="intro-sign-up-button">
            Book A Call
          </div>
        </a>
      </div>
    </div>
  );
};

export default IntroductionTop;