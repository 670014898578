import React from "react";
import { Link } from "react-router-dom";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import '../assets/styles/LegalScreen.css';
import { MetaLegalScreen } from "../constants/metaDescriptions";

const LegalScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Legal & Privacy Policy</title>
        <meta name="description" content={MetaLegalScreen} />
        <meta property="og:title" content="Rapunzl | Legal & Privacy Policy" />
        <meta property="og:description" content={MetaLegalScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/legal" />
        <link rel="canonical" href="https://www.rapunzl.com/legal" />
      </Helmet>
      <Header />
      <div className="route-subcontainer legal-route-subcontainer" style={{ marginTop: "-200px" }}>
        <div className="terms-of-use">
          <h1 className="legal-h1">
            Rapunzl Legal & Terms Of Use
          </h1>
          <div className="legal-h4">
            Last Updated January 1st, 2024
          </div>
          <div className="legal-p">
            Below you can find links to Rapunzl's major, public legal documents. If you are looking for an additional document and would like to request a digital copy from our team, please contact us and we'll try to help as best we can.
          </div>
          <div className="legal-options-flex">
            <Link to="/termsOfUse">
              <div className="legal-option-button">
                <img alt="Rapunzl Terms of Use" src="/images/Legal/Terms.webp" className="legal-button-image" />
                <button className="legal-button">
                  Terms Of Use & Service
                </button>
              </div>
            </Link>
            <Link to="/privacy">
              <div className="legal-option-button" style={{ marginLeft: "25px", marginRight: "25px" }}>
                <img alt="Rapunzl Privacy Policy" src="/images/Legal/Privacy.webp" className="legal-button-image" />
                <button className="legal-button">
                  Privacy Policy
                </button>
              </div>
            </Link>
            <Link to="/rules">
              <div className="legal-option-button">
                <img alt="Rapunzl Contest Rules" src="/images/Legal/Rules.webp" className="legal-button-image" />
                <button className="legal-button">
                  Contest Of Skill Rules
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default LegalScreen;
