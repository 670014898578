import React from "react";
import { Link } from "react-router-dom";

const GamificationBenefits = (props) => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '0px', paddingBottom: props.isCourse ? '20px' : '80px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="Gamified graduation hat to illustrate Rapunzl learning outcomes" src="/images/Courses/Gamification.webp"/>
      <div>
        <h2 className="educator-header">
          Gamified Learning To Increase Personal Finance Engagement
        </h2>
        <div className="educator-text">
          Gamification enhances student engagement by transforming classrooms into interactive learning experiences, and provides an opportunity for building <Link className="article-link" to="/article/promotingSelfDirectedFinancialLiteracy">financial financial through self-directed learning</Link>.
          <br/>
          <br/>
          This increases student motivation by fostering a deeper understanding of subjects with hands-on activities and a <Link className="article-link" to="/article/leveragingTheRapunzlSimulator">real-time investment simulator</Link>, aiding in the development of critical thinking, problem-solving, and collaboration skills.
        </div> 
        {props.isEducators && (
          <div className="get-in-touch-flex">
            <Link to="/courses">
              <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
                View Gamified Course Options
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default GamificationBenefits;