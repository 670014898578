import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import '../assets/styles/LegalScreen.css';
import { MetaTermsOfUseScreen } from "../constants/metaDescriptions";

const TermsOfUseScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Terms of Use & Service</title>
        <meta name="description" content={MetaTermsOfUseScreen} />
        <meta property="og:title" content="Rapunzl | Terms of Use & Service" />
        <meta property="og:description" content={MetaTermsOfUseScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/termsOfUse" />
        <link rel="canonical" href="https://www.rapunzl.com/termsOfUse" />
      </Helmet>
      <Header />
      <div className="route-subcontainer" style={{ paddingTop: "90px", marginTop: '0px', paddingBottom: '150px' }}>
        <h1 className="legal-h1">
          Rapunzl's Terms of Use & Service
        </h1>
        <div className="legal-h4">
          Last Updated January 1st, 2022
        </div>
        <div className="legal-h2">
          Section 1. Terms of Use & Service Agreement
        </div>
        <div className="legal-p">
          Rapunzl is a free and innovative platform to safely earn while You learn, allowing users to build stock portfolios without risking a single penny. We believe in democratizing access to financial education tools and providing market access to everyone, without any need to risk capital or pay high fees.
        </div>
        <div className="legal-p">
          This Terms of Use Agreement (“Agreement”) constitutes a legally binding agreement made between you, whether personally or on behalf of an entity, which You warrant that You have the legal authority to bind that entity, (interchangeably, “User” or “You”) and Rapunzl Investments LLC and its affiliated companies (collectively, “Company” or “We” or “Us” or “Our”), concerning your access to and use of the www.rapunzlinvestments.com website as well as any other media form, media channel, mobile website or mobile application related or connected thereto (collectively, the “Website”) and governs the way You access any content, functionality, products and services, information about our products and services, your Accounts, and other tools offered (collectively, “Company Services”).
        </div>
        <div className="legal-p">
          Supplemental terms and conditions or documents that may be posted on the Website from time to time, are hereby expressly incorporated into this Agreement by reference. This includes but is not limited to any and all future modifications thereof, and procedures that may be published from time to time on the Website.
        </div>
        <div className="legal-p">
          Company makes no representation that the Website is appropriate or available in other locations other than where it is operated by Company. The information provided on the Website is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject Company to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Website from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent that local laws are applicable.
        </div>
        <div className="legal-p">
          All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by their parent or guardian to use the Website. If You are a minor, You must have your parent or guardian read and agree to this Agreement prior to You using the Website. Persons under the age of 13 are not permitted to register for the Website or use the Company Services. You warrant that You are a guardian, and are at least 18 years of age. In jurisdictions, territories, and locations where the minimum age for permissible use of any part of the Website is greater than 18 years age, You or your guardian represent and warrant that You met the age requirement for the minimum age for permissible use of the Website.
        </div>
        <div className="legal-p">
          YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE DURING THE REGISTRATION PROCESS (IF APPLICABLE) AND ALSO BY CONTINUING TO USE THE WEBSITE. IF YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT, OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE COMPANY SERVICES OR THE WEBSITE.
        </div>
        <div className="legal-h2">
          Section 2. GDPR Compliance
        </div>
        <div className="legal-p">
          Pursuant to the terms of the European Union’s General Data Protection Regulation, adopted as Regulation (EU) 2016/679 of the European Parliament on April 14, 2016 to take effect on May 25, 2018, (“GDPR”), where the Company, either alone or jointly, determines the purposes and means of the processing of Personal Data, controls the data, and is responsible for it, and done so with respect to Data Subjects who are physically located in the European Union. The Company is a Controller. As a Controller, the Company is bound by the provisions of the GDPR and agree to comply with those provisions when in possession of such Personal Data.
        </div>
        <div className="legal-h2">
          Section 3. Not A Broker Deal Or Registered Investment Advisor
        </div>
        <div className="legal-p">
          You acknowledge and agree that we are not a broker-dealer, as such term is used in the United States financial services regulations, and that we do not trade securities on our or another party’s behalf as part of the Website, nor do we directly offer any financial advice of our own as part of the Website. 
        </div>
        <div className="legal-p">
          All Content created and shared by the Company through the Website is for informational and educational purposes only. You should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on our Website constitutes a solicitation, recommendation, endorsement, or offer by the Company or any Third Party Provider to buy or sell any securities or other financial instruments in this or in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction. There are risks associated with investing in securities. Investing in stocks, bonds, exchange traded funds, mutual funds, and money market funds involve risk of loss.  Loss of principal is possible. Some high risk investments may use leverage, which will accentuate gains & losses. Foreign investing involves special risks, including a greater volatility and political, economic and currency risks and differences in accounting methods.  A security’s or a firm’s past investment performance is not a guarantee or predictor of future investment performance.
        </div>
        <div className="legal-p">
          You acknowledge and agree that we are not liable for any losses or gains that may arise from your reliance upon information through the Website including your interactions with other Users.
        </div>
        <div className="legal-h2">
          Section 4. Reliance On Information Shown On Our Website & Services
        </div>
        <div className="legal-p">
          This information presented on or through the Company Website, related Services, User Interactions, and any links to third-parties provided for convenience, may include financial and market information. Such information is made available solely for education, entertainment, and general information purposes. We do not warrant the accuracy, completeness, timeliness, or usefulness of such information and clearly state that such information has not been verified. Any reliance You place on such information is strictly at your own risk. We do not invite that any action be taken upon the information we provide. We disclaim all liability and responsibility arising from any reliance placed on such materials by You or any other visitor to our Website or Services, or by anyone who may be informed of any of its contents.
        </div>
        <div className="legal-p">
          The information presented has been prepared without regard to the individual investment objectives, financial situations, or means. Such information is not intended to constitute investment advice and is not designed to meet your personal financial situation. We recommend that You consult with your financial advisers about investment options and whether any investment may be appropriate for your specific needs prior to making any investments. You acknowledge and agree that You have sole responsibility for your investment decisions and that You should not rely solely on any information provided through the Website or related Services with regard to any investment decisions You make.
        </div>
        <div className="legal-p">
          We use reasonable efforts to maintain the Website and related Services, but we are not responsible for any defects or failures associated with the Website and related Services, any content posted on the Website or related Services, the use of any widgets or similar applications, or any other damages (including lost profits or other consequential damages, even if have been informed of the same) that may result from any such defects or failures. The Website or related Services may be inaccessible or inoperable for any reason, including, without limitation:
        </div>
        <div className="legal-li">
          <b>A: </b>equipment malfunctions;
        </div>
        <div className="legal-li">
          <b>B: </b>periodic maintenance procedures or repairs which we may undertake from time to time; or causes beyond the control of the Company or which are not foreseeable by the Company.
        </div>
        <div className="legal-p">
          ALL CONTENT PRESENT ON THE WEBSITE AND THROUGH RELATED SERVICES IS PROVIDED “AS IS” WITHOUT ANY WARRANTIES CONCERNING THE CONTENT’S NATURE OR ACCURACY, EITHER WHEN POSTED OR AS A RESULT OF THE PASSAGE OF TIME, AND WITHOUT ANY REPRESENTATIONS OR GUARANTEES.
        </div>
        <div className="legal-h2">
          Section 5. Sponsorship & Third Parties
        </div>
        <div className="legal-p">
          The Website may be sponsored by third parties from time to time and display advertisements and promotions. You understand and acknowledge that the Company may place such advertising and promotions on, about, or in conjunction with portions of the Website, related Services, or User Content. 
        </div>
        <div className="legal-p">
          The manner, mode, and extent of such advertising and promotions are subject to change without specific notice to you. You acknowledge that we may always identify paid services, sponsored content, or commercial communications as such.
        </div>
        <div className="legal-p">
          The Company has no financial interest in the outcome of any trades mentioned on the Website or related Services. However, third party licensors of User Data, affiliates of Rapunzel, and/or principles of any affiliates of the Company may consider User Data in making investment decisions.
        </div>
        <div className="legal-h2">
          Section 6. Fair Play & Representation
        </div>
        <div className="legal-p">
          The Company believes in fair play and friendly competition to improve financial education opportunities, however, we recognize that there are occasional difficulties creating a world-class, near real-time simulator. We aim to provide a place where Users of the Company Website can learn good trading habits and all of us can foster a friendly and open environment for Users to hone their skills and be rewarded for their successes. Individuals demonstrating a pattern of dishonesty or disregard for the spirit of fair play negatively affect the Website as a whole.
        </div>
        <div className="legal-p">
          As such, You agree to abide by the Prohibited Activities and other terms included in this Agreement. Additionally, You represent and warrant that You have not been convicted of a felony involving dishonesty and are not subject to a “Statutory Disqualification” as defined in the Securities Exchange Act of 1934. If You have been convicted of a felony involving dishonesty or are subject to a Statutory Disqualification, You are not eligible to use the Website. This obligation is continuing throughout the use of the Website. You also recognize that the Company acts entirely in good faith when trying to present the Website with the most accurate information possible, however any misrepresentations or omissions are accepted by You by participating in the Website.
        </div>
        <div className="legal-p">
          If the Company determines at any point in time that any facts or circumstances which may cause You to be eligible pursuant to this Section have occurred or have been misrepresented, we reserve the right to terminate your use of the Website at any time and You will forfeit any balance of coins, scholarships, or cash prizes and You waive any and all rights to pursue any additional legal action or to slander the Company’s name.
        </div>
        <div className="legal-h2">
          Section 7. Purchases, Payment and Other Financial Information
        </div>
        <div className="legal-p">
          The Company may allow Users to make purchases through the Website and related Services. The Company or its third-party affiliates may utilize the services of certain third-party payment processors to process payments of credit cards and other accepted methods of payment. Your purchase is subject to any additional terms and conditions imposed by such third-party payment processors. The purchase price and any applicable fees or taxes shall be applied to your chosen method of payment upon submission of your Order.
        </div>
        <div className="legal-p">
          Upon the purchase of an Order, You acknowledge that the Company will provide availability and access to the particular service requested. Your participation in, or failure to use, such purchased service does not entitle You to a refund.
        </div>
        <div className="legal-p">
          Prices and availability of products are subject to change without notice. Errors will be corrected where discovered, and the Company reserves the right to revoke any stated offer and to correct any errors, inaccuracies, or omissions including after an Order has been submitted and whether or not the Order has been confirmed and your payment method accepted and charged. In the event your payment method has been accepted and charged, the Company will issue You the appropriate credit within a reasonable time after your Order has been revoked.
        </div>
        <div className="legal-p">
          The Company may accept payments via credit and debit card through our Sites or Services. Certain Credit Card Information and Debit Card Information will be encrypted and received via a secure page. You represent and warrant that You are an authorized signatory of the credit or debit card or other method of payment that You provide to the Company or its third-party payment processor to pay any fees or taxes related to your purchase of products or services through our Sites or Services.
        </div>
        <div className="legal-p">
          The Company may utilize the services of certain third-party payment processors to process payments of credit cards and other accepted methods of payment. We use industry standard technology and commercially reasonable measures to protect Credit Card Information and Debit Card Information from misuse.
        </div>
        <div className="legal-p">
          If You are directed to a third-party website to make purchases, your purchase will be governed by the terms of such a third party website. If You desire to supply Credit Card Information, Debit Card Information, Personal Data, or financial information to any third party service provider or to any Linked Web Sites, You do so at your own risk and discretion. We strongly recommend You review the terms and conditions and privacy policy of any third party before providing such information. The Company is not responsible should You provide such information to any third party and is not responsible for the use of such information by any third party.
        </div>
        <div className="legal-h2">
          Section 8. Users Representations
        </div>
        <div className="legal-h3">
          1. Regarding Your Registration
        </div>
        <div className="legal-p">
          By using the Company Services, You represent, warrant, and agree that:
        </div>
        <div className="legal-li">
          <b>A: </b>all registration information You submit is truthful, accurate, current and complete information about yourself as prompted by the Website’s registration form;
        </div>
        <div className="legal-li">
          <b>B: </b>in the event registration information changes, You will maintain the accuracy of such information and promptly update your registration data.
        </div>
        <div className="legal-li">
          <b>C: </b>you will keep your password confidential and will be responsible for all use of your password and account;
        </div>
        <div className="legal-li">
          <b>D: </b>you are not a minor in the jurisdiction which reside,or if a minor, You have received parental or a legal guardian’s permission to use this Website;
        </div>
        <div className="legal-li">
          <b>E: </b>your use of the Company Services does not violate any applicable law or regulation;
        </div>
        <div className="legal-li">
          <b>F: </b>all sales and other transactions or purchases are final and no refunds shall be issued.
        </div>
        <div className="legal-p">
          If You provide any information that is untrue, inaccurate, not current or incomplete, or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Company has the right to suspend or terminate your account and refuse any and all current or future use of the Website (or any portion thereof).
        </div>
        <div className="legal-p">
          We reserve the right to remove or reclaim or change a user name You select if we determine appropriate in our discretion, such as when the user name is obscene related to the Company or the Company’s likeness, or otherwise objectionable, in violation of Prohibited Activities incorporated herein, or when a trademark owner complains about a username that does not closely relate to a user's actual name.
        </div>
        <div className="legal-h3">
          2. Regarding Your Termination
        </div>
        <div className="legal-p">
          Users may terminate receipt of any free service publications at any time by sending Rapunzl a request for removal from the relevant distribution at the Address included at the end of this Agreement. All free service publication emails will include a link allowing a User to unsubscribe unless the information is specifically related to your account. If You wish to terminate receipt of all email communication, contact the Company to terminate your account or modify your notification settings on the Website.
        </div>
        <div className="legal-p">
          The Company reserves the right to refuse or terminate your access to any of the Company’s Website or related Services at any time in its sole discretion. Such termination may or may not result from a violation of this Agreement or other referenced terms, unauthorized use or reproduction of any publication or information, or any reason or no reason as determined in the Company’s sole discretion.
        </div>
        <div className="legal-h3">
          3. Regarding Content You Provide
        </div>
        <div className="legal-p">
          The Website may invite You to chat or participate in blogs, message boards, online forums and other functionality and may provide You with the opportunity to create, submit, post, display, transmit, perform, publish, distribute or broadcast content and materials to Company and/or to or via the Website, including, without limitation, text, writings, video, audio, photographs, graphics, comments, suggestions or personally identifiable information or other material (collectively "Contributions"). Any Contributions You transmit to Company will be treated as non-confidential and non-proprietary. When You create or make available a Contribution, You hereby represent and warrant that:
        </div>
        <div className="legal-li">
          <b>A: </b>the creation, distribution, transmission,public display and performance, accessing, downloading and copying of your Contribution does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret or moral rights of any third party;
        </div>
        <div className="legal-li">
          <b>B: </b>You are the creator and owner of or have the necessary licenses, rights, consents, releases and permissions to use and to authorize Company and the Website users to use your Contributions as necessary to exercise the licenses granted by You under this Agreement;
        </div>
        <div className="legal-li">
          <b>C: </b>you have the written consent,release,and/or permission of each and every identifiable individual person in the Contribution to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the Contribution in the manner contemplated by this Website;
        </div>
        <div className="legal-li">
          <b>D: </b>​​your Contribution is not obscene, lewd, lascivious, filthy, violent, harassing or otherwise objectionable (as determined by Company), libelous or slanderous, does not ridicule, mock, disparage, intimidate or abuse anyone, does not advocate the violent overthrow of any government, does not incite, encourage or threaten physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;
        </div>
        <div className="legal-li">
          <b>E: </b>your Contribution does not contain material that solicits personal information from anyone under 18 or exploit people under the age of 18 in a sexual or violent manner, and does not violate any federal or state law concerning child pornography or otherwise intended to protect the health or well-being of minors;
        </div>
        <div className="legal-li">
          <b>F: </b>your Contribution does not include any offensive comments that are connected to race, national origin, gender, sexual preference or physical handicap;
        </div>
        <div className="legal-li">
          <b>G: </b>your Contribution does not aim to manipulate any securities markets; and
        </div>
        <div className="legal-li">
          <b>H: </b>your Contribution does not otherwise violate, or link to material that violates any provision of this Agreement or any applicable law or regulation.
        </div>
        <div className="legal-h2">
          Section 9. Contribution License
        </div>
        <div className="legal-p">
          By posting Contributions to any part of the Website, or making them accessible to the Website by linking your account to any of your social network accounts, You automatically grant, and You represent and warrant that You have the right to grant, to Company an unrestricted, unconditional, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part) and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, such Contributions, and to grant and authorize sublicenses of the foregoing.
        </div>
        <div className="legal-p">
          The use and distribution may occur in any media formats and through any media channels. Such use and distribution license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names and logos, personal and commercial images You provide. Company does not assert any ownership over your Contributions; rather, as between us and you, subject to the rights granted to us in this Agreement, You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions.
        </div>
        <div className="legal-p">
          Company has the right, in our sole and absolute discretion, to:
        </div>
        <div className="legal-li">
          <b>A: </b>edit, redact or otherwise change any Contributions;
        </div>
        <div className="legal-li">
          <b>B: </b>re-categorize any Contributions to place them in more appropriate locations; or
        </div>
        <div className="legal-li">
          <b>C: </b>pre-screen or delete any Contributions that are determined to be inappropriate or otherwise in violation of this Agreement.
        </div>
        <div className="legal-p">
          By uploading your Contributions to the Website, You hereby authorize Company to grant to each end user a personal, limited, non-transferable, perpetual, non-exclusive, royalty-free, fully-paid license to access, download, print and otherwise use your Contributions for their internal purposes and not for distribution, transfer, sale or commercial exploitation of any kind.
        </div>
        <div className="legal-h2">
          Section 10. Guidelines For Reviews
        </div>
        <div className="legal-p">
          Company may accept, reject or remove reviews in its sole discretion. Company has absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Those persons posting reviews should comply with the following criteria: 
        </div>
        <div className="legal-li">
          <b>A: </b>reviewers should have firsthand experience with the person/entity being reviewed;
        </div>
        <div className="legal-li">
          <b>B: </b>reviews should not contain: offensive language, profanity, or abusive, racist, or hate language; discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation or disability; or references to illegal activity;
        </div>
        <div className="legal-li">
          <b>C: </b>reviewers should not be affiliated with competitors if posting negative reviews;
        </div>
        <div className="legal-li">
          <b>D: </b>reviewers should not make any conclusions as to the legality of conduct; and
        </div>
        <div className="legal-li">
          <b>E: </b>reviewers may not post any false statements or organize a campaign encouraging others to post reviews, whether positive or negative. Reviews are not endorsed by Company, and do not represent the views of Company or of any affiliate or partner of Company.
        </div>
        <div className="legal-p">
          Company does not assume liability for any review or for any claims, liabilities or losses resulting from any review. By posting a review, the reviewer hereby grants to Company a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable and sublicensable license to Company to reproduce, modify, translate, transmit by any means, display, perform and/or distribute all content relating to reviews.
        </div>
        <div className="legal-h2">
          Section 11. Mobile Application Licensee
        </div>
        <div className="legal-h3">
          1. Use License
        </div>
        <div className="legal-p">
          If You are accessing the Company Services via a mobile application, then Company grants You a revocable, non-exclusive, non-transferable, limited right to install and use the application on wireless handsets owned and controlled by you, and to access and use the application on such devices strictly in accordance with the terms and conditions of this license. You shall use the application strictly in accordance with the terms of this license and shall not, under penalty of a $1,000,000 fine and additional potential civil action:
        </div>
        <div className="legal-li">
          <b>A: </b>decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;
        </div>
        <div className="legal-li">
          <b>B: </b>make any modification, adaptation, improvement, enhancement, translation or derivative work from the application;
        </div>
        <div className="legal-li">
          <b>C: </b>violate any applicable laws, rules or regulations in connection with your access or use of the application; 
        </div>
        <div className="legal-li">
          <b>D: </b>remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Company or its affiliates, partners, suppliers or the licensors of the application; 
        </div>
        <div className="legal-li">
          <b>E: </b>use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended; 
        </div>
        <div className="legal-li">
          <b>F: </b>make the application available over a network or other environmental permitting access or use by multiple devices or users at the same time; 
        </div>
        <div className="legal-li">
          <b>G: </b>use the application for creating a product, service or software that is, directly or indirectly, competitive with or in any way a substitute for the application; 
        </div>
        <div className="legal-li">
          <b>H: </b>use the application to send automated queries to any website or to send any unsolicited commercial e-mail; or 
        </div>
        <div className="legal-li">
          <b>I: </b>use any proprietary information or interfaces of Company or other intellectual property of Company in the design, development, manufacture, licensing or distribution of any applications, accessories or devices for use with the application.
        </div>
        <div className="legal-h3">
          2. Terms Applicable to Apple and Android Devices
        </div>
        <div className="legal-p">
          The following terms apply when You use a mobile application obtained from either the Apple Store or Google Play to access the Company Services. You acknowledge that this Agreement is concluded between You and Company only, and not with Apple Inc. or Google, Inc. (each an “App Distributor”), and Company, not an App Distributor, is solely responsible for the Company application and the content thereof.
        </div>
        <div className="legal-li">
          <b>A. SCOPE OF LICENSE:</b>
          <br/>The license granted to You for the Company Application is limited to a non-transferable license to use the Company application on a device that utilizes the Apple iOS or Android operating system, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor terms of service.
        </div>
        <div className="legal-li">
          <b>B. MAINTENANCE AND SUPPORT:</b>
          <br/>Company is solely responsible for providing any maintenance and support services with respect to the Company application, as specified in this Agreement, or as required under applicable law. You acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the Company application.
        </div>
        <div className="legal-li">
          <b>C. WARRANTY:</b>
          <br/>Company is solely responsible for any product warranties,whether expressed or implied by law, to the extent not effectively disclaimed. In the event of any failure of the Company application to conform to any applicable warranty, You may notify an App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the Company application, and to the maximum extent permitted by applicable law, an App Distributor will have no other warranty obligation whatsoever with respect to the Company application, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be Company’s sole responsibility.
        </div>
        <div className="legal-li">
          <b>D. PRODUCT CLAIMS:</b>
          <br/>You acknowledge that Company, not an App Distributor, is responsible for addressing any claims of yours or any third party relating to the Company application or your possession and/or use of the Company application, including, but not limited to: (i) product liability claims; (ii) any claim that the Company application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
        </div>
        <div className="legal-li">
          <b>E. INTELLECTUAL PROPERTY RIGHTS:</b>
          <br/>You acknowledge that, in the event of any third party claim that the Company application or your possession and use of the Company application infringes a third party’s intellectual property rights, the App Distributor will not be responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.
        </div>
        <div className="legal-li">
          <b>F. LEGAL COMPLIANCE:</b>
          <br/>You represent and warrant that (i) You are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist supporting” country; and (ii) You are not listed on any U.S. government list of prohibited or restricted parties.
        </div>
        <div className="legal-li">
          <b>G. THIRD PARTY TERMS OF AGREEMENT:</b>
          <br/>You must comply with applicable third party terms of agreement when using the Company application, e.g., if You have a VoIP application, then You must not be in violation of their wireless data service agreement when using the Company application.
        </div>
        <div className="legal-li">
          <b>H. THIRD PARTY BENEFICIARY:</b>
          <br/>Company and You acknowledge and agree that the App Distributors, and their subsidiaries, are third party beneficiaries of this Agreement, and that, upon your acceptance of the terms and conditions of this Agreement, each App Distributor will have the right (and will be deemed to have accepted the right) to enforce this Agreement against You as a third party beneficiary thereof.
        </div>
        <div className="legal-h2">
          Section 12. Social Media
        </div>
        <div className="legal-p">
          As part of the functionality of the Website, You may link your account with online accounts You may have with third party service providers (each such account, a “Third Party Account”) by either:
        </div>
        <div className="legal-li">
          <b>A. </b>providing your Third Party Account login information through the Website; or
        </div>
        <div className="legal-li">
          <b>B. </b>allowing Company to access your Third Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third Party Account.
        </div>
        <div className="legal-p">
          You represent that You are entitled to disclose your Third Party Account login information to Company and/or grant Company access to your Third Party Account (including, but not limited to, for use for the purposes described herein), without breach by You of any of the terms and conditions that govern your use of the applicable Third Party Account and without obligating Company to pay any fees or making Company subject to any usage limitations imposed by such third party service providers. 
        </div>
        <div className="legal-p">
          By granting Company access to any Third Party Accounts, You understand that: 
        </div>
        <div className="legal-li">
          <b>C. </b>Company may access, make available and store (if applicable) any content that You have provided to and stored in your Third Party Account (the “Social Network Content”) so that it is available on and through the Website via your account, including without limitation any friend lists, and
        </div>
        <div className="legal-li">
          <b>D. </b>Company may submit and receive additional information to your Third Party Account to the extent You are notified when You link your account with the Third Party Account.
        </div>
        <div className="legal-p">
          Depending on the Third Party Accounts You choose and subject to the privacy settings that You have set in such Third Party Accounts, personally identifiable information that You post to your Third Party Accounts may be available on and through your account on the Website.
        </div>
        <div className="legal-p">
          Please note that if a Third Party Account or associated service becomes unavailable or Company’s access to such Third Party Account is terminated by the third party service provider, the Social Network Content may no longer be available on and through the Website.
        </div>
        <div className="legal-p">
          You will have the ability to disable the connection between your account on the Website and your Third Party Accounts at any time.
        </div>
        <div className="legal-p">
          PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD PARTY SERVICE PROVIDERS.
        </div>
        <div className="legal-p">
          Company makes no effort to review any Social Network Content for any purpose, including but not limited to, for accuracy, legality or non-infringement, and the Company is not responsible for any Social Network Content. You acknowledge and agree that Company may access your e-mail address book associated with a Third Party Account and your contacts list stored on your mobile device or tablet computer solely for the purposes of identifying and informing You of those contacts who have also registered to use the Website.
        </div>
        <div className="legal-p">
          At your request made via email to our email address listed below, or through your account settings (if applicable), Company will deactivate the connection between the Website and your Third Party Account and delete any information stored on Company’s servers that was obtained through such Third Party Account, except the username and profile picture that become associated with your account.
        </div>
        <div className="legal-h2">
          Section 13. Submissions
        </div>
        <div className="legal-p">
          You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Website or the Company Services ("Submissions") provided by You to Company are non-confidential and Company (as well as any designee of Company) shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
        </div>
        <div className="legal-h2">
          Section 14. Prohibited Activities
        </div>
        <div className="legal-p">
          You may not access or use the Website for any other purpose other than that for which the Company makes it available. The Website may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by the Company. Prohibited activity includes, but is not limited to:
        </div>
        <div className="legal-li">
          <b>A. </b>attempting to bypass any measures of the Website designed prevent or restrict access to the Website, or any portion of the Website;
        </div>
        <div className="legal-li">
          <b>B. </b>attempting to impersonate another user or person or using the username of another user;
        </div>
        <div className="legal-li">
          <b>C. </b>criminal or tortious activity;
        </div>
        <div className="legal-li">
          <b>D. </b>deciphering, decompiling, disassembling or reverse engineering any of the software comprising or in any way making up a part of the Website;
        </div>
        <div className="legal-li">
          <b>E. </b>deleting the copyright or other proprietary rights notice from any Website content;
        </div>
        <div className="legal-li">
          <b>F. </b>engaging in any automated use of the system, such as using any data mining, robots or similar data gathering and extraction tools;
        </div>
        <div className="legal-li">
          <b>G. </b>except as may be the result of standard search engine or Internet browser usage, using or launching, developing or distributing any automated system, including, without limitation, any spider, robot (or "bot"), cheat utility, scraper or offline reader that accesses the Website, or using or launching any unauthorized script or other software;
        </div>
        <div className="legal-li">
          <b>H. </b>harassing, annoying, intimidating or threatening any Company employees or agents engaged in providing any portion of the Company Services to you;
        </div>
        <div className="legal-li">
          <b>I. </b>interfering with, disrupting, or creating an undue burden on the Website or the networks or services connected to the Website;
        </div>
        <div className="legal-li">
          <b>J. </b>making any unauthorized use of the Company Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses;
        </div>
        <div className="legal-li">
          <b>K. </b>selling or otherwise transferring your profile;
        </div>
        <div className="legal-li">
          <b>L. </b>systematic retrieval of data or other content from the Website to create or compile, directly or indirectly, a collection, compilation, database or directory without written permission from Company;
        </div>
        <div className="legal-li">
          <b>M. </b>tricking, defrauding or misleading Company and other users, especially in any attempt to learn sensitive account information such as passwords;
        </div>
        <div className="legal-li">
          <b>N. </b>using any information obtained from the Website in order to harass,abuse,or harm another person;
        </div>
        <div className="legal-li">
          <b>O. </b>using the Company Services as part of any effort to compete with the Company or to provide services as a service bureau; 
        </div>
        <div className="legal-li">
          <b>P. </b>using the Website in a manner inconsistent with any and all applicable laws and regulations;
        </div>
        <div className="legal-li">
          <b>Q. </b>attempting to manipulate one’s Portfolio Performance or competition ranking to gain an unfair advantage or manipulate the Rapunzl system; and
        </div>
        <div className="legal-li">
          <b>R. </b>abuse a bug or system error to gain an unfair advantage or manipulate the Rapunzl system.
        </div>
        <div className="legal-h2">
          Section 15. Intellectual Property Rights
        </div>
        <div className="legal-p">
          The content on the Website (“Company Content”) and the trademarks, service marks and logos contained therein (“Marks”) are owned by or licensed to Company, and are subject to copyright and other intellectual property rights under United States and foreign laws and international conventions. Company Content, includes, without limitation, all source code, databases, functionality, software, website designs, audio, video, text, photographs and graphics. All Company graphics, logos, designs, page headers, button icons, scripts and service names are registered trademarks, common law trademarks or trade dress of Company in the United States and/or other countries. Company's trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the Company.
        </div>
        <div className="legal-p">
          Company Content on the Website is provided to You “AS IS” for your information and personal use only and may not be used, copied, reproduced, aggregated, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. Provided that You are eligible to use the Website, You are granted a limited license to access and use the Website and the Company Content and to download or print a copy of any portion of the Company Content to which You have properly gained access solely for your personal, non-commercial use. Company reserves all rights not expressly granted to You in and to the Website and Company Content and Marks.
        </div>
        <div className="legal-h2">
          Section 16. Third-Party Websites And Content
        </div>
        <div className="legal-p">
          The Website contains (or You may be sent through the Website or the Company Services) links to other websites ("Third Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software and other content or items belonging to or originating from third parties (the "Third Party Content"). 
        </div>
        <div className="legal-p">
          Such Third Party Websites and Third Party Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Websites accessed through the Website or any Third Party Content posted on, available through or installed from the Website, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other policies of or contained in the Third Party Websites or the Third Party Content. Inclusion of, linking to or permitting the use or installation of any Third Party Website or any Third Party Content does not imply approval or endorsement thereof by us. If You decide to leave the Website and access the Third Party Websites or to use or install any Third Party Content, You do so at your own risk and You should be aware that our terms and policies no longer govern. 
        </div>
        <div className="legal-p">
          You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which You navigate from the Website or relating to any applications You use or install from the Website. Any purchases You make through Third Party Websites will be through other websites and from other companies, and the Company takes no responsibility whatsoever in relation to such purchases which are exclusively between You and the applicable third party.
        </div>
        <div className="legal-h2">
          Section 17. Site Management
        </div>
        <div className="legal-p">
          Company reserves the right but does not have the obligation to:
        </div>
        <div className="legal-li">
          <b>A. </b>monitor the Website for violations of this Agreement;
        </div>
        <div className="legal-li">
          <b>B. </b>take appropriate legal action against anyone who, in Company’s sole discretion, violates this Agreement, including without limitation, reporting such user to law enforcement authorities;
        </div>
        <div className="legal-li">
          <b>C. </b>in Company’s sole discretion and without limitation, refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any user’s contribution or any portion thereof that may violate this Agreement or any Company policy;
        </div>
        <div className="legal-li">
          <b>D. </b>in Company’s sole discretion, reset a User’s Account if any activities violate any portion of this agreement;
        </div>
        <div className="legal-li">
          <b>E. </b>in Company’s sole discretion and without limitation, notice or liability to remove from the Website or otherwise disable all files and content that are excessive in size or are in any way burdensome to Company’s systems; and
        </div>
        <div className="legal-li">
          <b>F. </b>otherwise manage the Website in a manner designed to protect the rights and property of Company and others and to facilitate the proper functioning of the Website.
        </div>
        <div className="legal-h2">
          Section 18. Contests Of Skill
        </div>
        <div className="legal-p">
          The Contests offered through the Website and related Services are contests of skill, and the Company’s Competition Rules are incorporated herein and define these Contests for clarity. While using the Company’s Website, You will have the opportunity to enter the Company’s free Contests. Winners for each Contest will be selected based on their portfolio’s percentage performance over the duration of the competition. 
        </div>
        <div className="legal-p">
          The Company’s decisions and interpretations on all matters relating to the Contests and related Official Rules are final and binding in all respects. Rapunzl reserves the right to disqualify any entrant or winner and may refuse to award a prize to a person who is not eligible or has violated a rule, gained unfair advantage in the Contest by not replicating real-market conditions, or obtained winner status using fraudulent means. If there is a dispute about the identity of an entrant, or any other dispute related to the Contest and the Contest’s winners, the Company’s decision will be final. If the winner is found to be ineligible or not in compliance with the Contest rules, the winner will be disqualified and an alternate winner will be awarded the prize in a separate random drawing. 
        </div>
        <div className="legal-p">
          Winners will be notified within the Website and via email. Prize may only be redeemed within the app.
        </div>
        <div className="legal-p">
          ​By entering a Contest, you agree to abide by the Official Rules and decisions of the Company, which will be final and binding in all respects. Rapunzl reserves the right, at its sole discretion, to refuse, disqualify or withdraw any entry at any time. Rapunzl will not be responsible for any injury, damage or loss of any kind arising out of your participation in the Sweepstakes and at no point should any information on the Website or related Contests be seen as investment advice.
        </div>
        <div className="legal-p">
          Contests are open to all Rapunzl users, however, only legal US residents who are at least 18 years old at time of entry are eligible for Prizes, with the exception of Scholarship Prizes which are awarded directly to participating students and are available to specific Rapunzl users as defined by the specific Contest rules and determined through by the Company in their sole discretion. 
        </div>
        <div className="legal-p">
          All Competitions are subject to all applicable federal, state, and local laws and regulations and are void where prohibited by law. Prizes will be awarded within 24 hours of the Competition’s end date, with the exception of Scholarship Prizes, which will be awarded in the standard course of the Contest, as determined by the Company. Prizes for cash Contests are determined through the Company’s proprietary prize algorithm. Entrants of Contests with cash prizes under the age of 18 will not be eligible for any allocated cash prizes and these prizes will be returned to the Company. Prizes are non-transferable and no substitution or cash equivalent is permitted. Winner is responsible for federal, state, and local taxes, if applicable. Each prize is awarded “as is'' with no warranty or guarantee, either express or implied, by the Company. Prizes must be redeemed through the Rapunzl App within 90 days of receiving the prize, or prizes will be forfeited.
        </div>
        <div className="legal-p">
          There is a limit of one entry per person, determined by email address, social security number, and bank account. No third party entry or entry through any sweepstakes service is permitted. Any person who attempts or otherwise encourages the entry of multiple or false contact information under multiple identities or uses any device or artifice to enter or encourage multiple or false entries, as determined by the Company, will be disqualified. Use of robotic entry devices is strictly prohibited.
        </div>
        <div className="legal-p">
          ANY ATTEMPT BY ANY PERSON, WHETHER OR NOT AN ENTRANT, TO DELIBERATELY DAMAGE, DESTROY, TAMPER WITH OR VANDALIZE ANY RAPUNZL-OWNED WEBSITE OR MOBILE APPLICATION OR RELATED SOCIAL NETWORKING SITE, THE ENTRY PROCESS, OR OTHERWISE INTERFERE WITH OR UNDERMINE THE LEGITIMATE OPERATION OF THE SWEEPSTAKES, MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS AND RAPUNZL RESERVES THE RIGHT TO SEEK DAMAGES AND DILIGENTLY PURSUE ALL REMEDIES AGAINST ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.
        </div>
        <div className="legal-p">
          ​YOU AGREE TO RELEASE, DISCHARGE, AND HOLD HARMLESS RAPUNZL, ITS AFFILIATES, SUBSIDIARIES, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS AND ASSIGNS FROM AND AGAINST ANY OR ALL CLAIMS, LOSSES, INJURIES, OR DAMAGES RESULTING FROM PARTICIPATION IN THE SWEEPSTAKES.
        </div>
        <div className="legal-p">
          ​Except where prohibited by law, by accepting prize, winner grants the Company a perpetual, worldwide, royalty-free license to use winner’s name, photograph, voice, and/or likeness without further authorization, compensation, or remuneration of any kind for advertising, promotion and other publicity purposes in any and all media now or hereafter known throughout the world.
        </div>
        <div className="legal-p">
          The Website or Services may not be used for any form of illicit gambling or violate any rulings from the Securities Exchange Commission.
        </div>
        <div className="legal-h2">
          Section 19. Privacy Policy
        </div>
        <div className="legal-p">
          We care about the privacy of our users. Please review the Company Privacy Policy available on the Company Website.
        </div>
        <div className="legal-p">
          By using the Website or Company Services, You are consenting to the terms of our Privacy Policy, including but not limited to, having your personal data transferred to and processed in the United States. In the event of a conflict between this Agreement and Rapunzl’s Privacy Policy, this Agreement will supersede any conflicting content in Rapunzl’s Privacy Policy.
        </div>
        <div className="legal-h2">
          Section 20. Term And Termination
        </div>
        <div className="legal-p">
          This Agreement shall remain in full force and effect while You use the Website or are otherwise a user or member of the Website, as applicable. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us using the contact information below.
        </div>
        <div className="legal-p">
          WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, THE COMPANY RESERVES THE RIGHT TO, IN COMPANY’S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE WEBSITE AND THE COMPANY SERVICES, TO ANY PERSON FOR ANY REASON OR FOR NO REASON AT ALL, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION, AND COMPANY MAY TERMINATE YOUR USE OR PARTICIPATION IN THE WEBSITE AND THE COMPANY SERVICES, DELETE YOUR PROFILE AND ANY CONTENT OR INFORMATION THAT YOU HAVE POSTED AT ANY TIME, WITHOUT WARNING, IN COMPANY’S SOLE DISCRETION.
        </div>
        <div className="legal-p">
          In order to protect the integrity of the Website and Company Services, Company reserves the right at any time in its sole discretion to block certain IP addresses from accessing the Website and Company Services. The Company also reserves the right to permanently store the email address associated with Your account in order to enforce other rules associated with the Website and Contests hosted by the Company.
        </div>
        <div className="legal-p">
          Any provisions of this Agreement that, in order to fulfill the purposes of such provisions, need to survive the termination or expiration of this Agreement, shall be deemed to survive for as long as necessary to fulfill such purposes.
        </div>
        <div className="legal-p">
          YOU UNDERSTAND THAT CERTAIN STATES ALLOW YOU TO CANCEL THIS AGREEMENT, WITHOUT ANY PENALTY OR OBLIGATION, AT ANY TIME PRIOR TO MIDNIGHT OF COMPANY’S THIRD BUSINESS DAY FOLLOWING THE DATE OF THIS AGREEMENT, EXCLUDING SUNDAYS AND HOLIDAYS. TO CANCEL, CALL A COMPANY CUSTOMER CARE REPRESENTATIVE DURING NORMAL BUSINESS HOURS USING THE CONTACT INFORMATION LISTING BELOW IN THIS AGREEMENT OR BY ACCESSING YOUR ACCOUNT SETTINGS. THIS SECTION APPLIES ONLY TO INDIVIDUALS RESIDING IN STATES WITH SUCH LAWS.
        </div>
        <div className="legal-p">
          If Company terminates or suspends your account for any reason, You are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if You may be acting on behalf of the third party. You are further prohibited from registering and creating a new account with the email address which You used in any previous Website account. In addition to terminating or suspending your account, the Company reserves the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
        </div>
        <div className="legal-h2">
          Section 21. Modifications
        </div>
        <div className="legal-h3">
          1. To Agreement
        </div>
        <div className="legal-p">
          The Company may modify this Agreement from time to time. Any and all changes to this Agreement will be posted on the Website and revisions will be indicated by date. You agree to be bound to any changes to this Agreement when You use the Company Services after any such modification becomes effective and acknowledge that the Company is not obligated to alert all users with whom it maintains email information of such modifications. It is therefore important that You regularly review this Agreement. 
        </div>
        <div className="legal-p">
          You agree that You will periodically check the Website for updates to this Agreement and You will read the messages we send You to inform You of any changes. Modifications to this Agreement shall be effective immediately after posting on the Company’s Website.
        </div>
        <div className="legal-h3">
          2. To Services
        </div>
        <div className="legal-p">
          Company reserves the right at any time to modify or discontinue, temporarily or permanently, the Company Services (or any part thereof) with or without notice. You agree that the Company shall not be liable to You or to any third party for any modification, suspension or discontinuance of the Company Services.
        </div>
        <div className="legal-h2">
          Section 22. Breach of Agreement
        </div>
        <div className="legal-p">
          You agree that breach of the provisions of this Agreement would cause irreparable harm and significant injury to the Company which would be both difficult to ascertain and which would not be compensable by damages alone.
        </div>
        <div className="legal-p">
          As such, You agree that the Company has the right to enforce the provisions of these terms by injunction (without necessity of posting bond), specific performance, or other equitable relief without prejudice to any other rights and remedies the Company has for your breach of these Terms.
        </div>
        <div className="legal-h2">
          Section 23. Disputes
        </div>
        <div className="legal-h3">
          1. Between Users
        </div>
        <div className="legal-p">
          If there is a dispute between users of the Website, or between users and any third party, You understand and agree that the Company is under no obligation to become involved. 
        </div>
        <div className="legal-p">
          In the event that You have a dispute with one or more other users, You hereby release Company, its officers, employees, agents and successors in rights from claims, demands and damages (actual and consequential) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or the Company Services.
        </div>
        <div className="legal-h3">
          2. With Company
        </div>
        <div className="legal-p">
          All questions of law, rights, and remedies regarding any act, event or occurrence undertaken pursuant or relating to this Website or the Company Services shall be governed and construed by the laws of the State of Illinois, excluding such state’s conflicts of law rules. Any legal action of whatever nature by or against Company arising out of or related in any respect to this Website and the Company Services shall be brought solely in either the applicable federal or state courts located in or with jurisdiction over Cook County County, State of Illinois; subject, however, to the right of Company, at the Company's sole discretion, to bring an action to seek injunctive relief to enforce this Agreement or to stop or prevent an infringement of proprietary or other third party rights (or any similar cause of action) in any applicable court in any jurisdiction where jurisdiction exists with regard to a user.
        </div>
        <div className="legal-p">
          You hereby consent to (and waive any challenge or objection to) personal jurisdiction and venue in the above-referenced courts. Application of the United Nations Convention on Contracts for the International Sale of Goods is excluded from this Agreement. Additionally, application of the Uniform Computer Information Transaction Act (UCITA) is excluded from this Agreement.
        </div>
        <div className="legal-p">
          In no event shall any claim, action or proceeding by You related in any way to the Website and/or the Company Services (including your visit to or use of the Website and/or the Company Services) be instituted more than two (2) years after the cause of action arose. You acknowledge and accept that You will be liable for any attorneys' fees and costs if we have to take any legal action to enforce this Agreement.
        </div>
        <div className="legal-h2">
          Section 24. Corrections
        </div>
        <div className="legal-p">
          Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to service descriptions, pricing, availability, and various other information.
        </div>
        <div className="legal-p">
          Company reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any time, without prior notice and bears no obligation to inform You about errors, inaccuracies, omissions or other service issues which disrupt the Website or Company Services.
        </div>
        <div className="legal-h2">
          Section 25. Disclaimer Of Warranties
        </div>
        <div className="legal-p">
          Company cannot control the nature of all of the content available on the Website. By operating the Website, Company does not represent or imply that Company endorses any blogs, contributions or other content available on or linked to by the Website, including without limitation content hosted on third party websites or provided by third party applications, or that Company believes contributions, blogs or other content to be accurate, useful or non-harmful.
        </div>
        <div className="legal-p">
          We do not control and are not responsible for unlawful or otherwise objectionable content You may encounter on the Website or in connection with any contributions. The Company is not responsible for the conduct, whether online or offline, of any user of the Website or Company Services. 
        </div>
        <div className="legal-p">
          The Company expressly disclaims any and all possible warranties, express or implied, with respect to the Website and related Services, including that the Company will be uninterrupted or error-free, that defects will be corrected, or that the Company’s Website and any related Services, its servers, or any email sent from the Company are free of viruses or other harmful components.
        </div>
        <div className="legal-p">
          YOU AGREE THAT YOUR USE OF THE WEBSITE AND COMPANY SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE WEBSITE AND THE COMPANY SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE WEBSITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THIS WEBSITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE OR COMPANY SERVICES, (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE. COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE WEBSITE OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND THE COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </div>
        <div className="legal-h2">
          Section 26. Limitations of Liability
        </div>
        <div className="legal-p">
          IN NO EVENT SHALL THE COMPANY OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST REVENUE, LOSS OF DATA OR OTHER DAMAGES ARISING FROM YOUR USE OF THE WEBSITE OR COMPANY SERVICES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO COMPANY FOR THE COMPANY SERVICES DURING THE PERIOD OF THREE (3) MONTHS PRIOR TO ANY CAUSE OF ACTION ARISING.
        </div>
        <div className="legal-p">
          CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </div>
        <div className="legal-p">
          IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
        </div>
        <div className="legal-h2">
          Section 27. Force Majeure
        </div>
        <div className="legal-p">
          We will not reliable or responsible for any failure to perform, or delay in performance of any of our obligations under this Agreement that is caused by a Force Majeure Event, defined as any act or event beyond our reasonable control, including but not limited to strikes, lock-outs, or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack, or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic, or other natural disaster, or failure of public or private telecommunications networks or impossibility of the use of railways, shipping, aircraft, motor transport, or other means of public or private transport.
        </div>
        <div className="legal-p">
          If a Force Majeure Event takes place that affects the performance of our obligations under this Agreement, we will contact You as soon as reasonably possible to notify you. Our obligations under this Agreement will be suspended and the time for performance of our obligations will be extended for the duration of a Force Majeure Event.
        </div>
        <div className="legal-h2">
          Section 28. Indemnity
        </div>
        <div className="legal-p">
          You agree to defend, indemnify and hold Company, its subsidiaries, and affiliates, and their respective officers, agents, partners and employees, harmless from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of your contributed content, use of the Company Services, and/or arising from a breach of this Agreement and/or any breach of your representations and warranties set forth above. 
        </div>
        <div className="legal-p">
          Notwithstanding the foregoing, the Company reserves the right, at your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify Company, and You agree to cooperate, at your expense, with Company’s defense of such claims. Company will use reasonable efforts to notify You of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
        </div>
        <div className="legal-h2">
          Section 29. Notices
        </div>
        <div className="legal-p">
          Except as explicitly stated otherwise, any notices given to Company shall be given by email to the address listed in the contact information below. Any notices given to You shall be given to the email address You provided during the registration process, or such other address as each party may specify. Notice shall be deemed to be given twenty-four (24) hours after the email is sent, unless the sending party is notified that the email address is invalid. We may also choose to send notices by regular mail.
        </div>
        <div className="legal-h2">
          Section 30. User Data
        </div>
        <div className="legal-p">
          Our Website will maintain certain data that You transfer to the Website for the purpose of the performance of the Company Services, as well as data relating to your use of the Company Services (“User Data”). Although we perform regular routine backups of User Data, You are primarily responsible for all data that You have transferred or that relates to any activity You have undertaken using the Company Services.
        </div>
        <div className="legal-p">
          You agree that the Company shall have no liability to You for any loss or corruption of any User Data, and You hereby waive any right of action against the Company arising from any such loss or corruption of such User Data. The Company may utilize certain non-personally identifying User Data in licensing agreements to third-parties in accordance with state and federal laws. How we use the User Data in the future may vary.
        </div>
        <div className="legal-p">
          In some cases, we believe providing Users access to aggregated User Data may better equip them in their trading education. Any User (including You) granted access to such aggregated User Data is granted a non-exclusive, non-transferable, limited and revocable right to access and use all User Data collected by us and made available solely for such User’s personal, non-commercial and non-public use. In connection with this limited use, User Data received from other Users may stream in the platform and be available to You.
        </div>
        <div className="legal-p">
          You shall not reproduce, distribute, modify, create derivative works of, public display, publicly perform, republish, download, store, or transmit and User Data obtained through your use of the App, except for your personal, non-commercial and non-public use.
        </div>
        <div className="legal-h2">
          Section 31. Electronic Contracting
        </div>
        <div className="legal-p">
          Your use of the Company Services includes the ability to enter into agreements and/or to make transactions electronically.
        </div>
        <div className="legal-p">
          YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND BY AND TO PAY FOR SUCH AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU ENTER INTO RELATING TO THE COMPANY SERVICES, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS.
        </div>
        <div className="legal-p">
          In order to access and retain your electronic records, You may be required to have certain hardware and software, which are your sole responsibility.
        </div>
        <div className="legal-h2">
          Section 32. Miscellaneous
        </div>
        <div className="legal-p">
          This Agreement constitutes the entire agreement between You and Company regarding the use of the Company Services. The failure of the Company to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. This Agreement operates to the fullest extent permissible by law. This Agreement and your account may not be assigned by You without our express written consent.
        </div>
        <div className="legal-p">
          Company may assign any or all of its rights and obligations to others at any time. Company shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond the Company's reasonable control. If any provision or part of a provision of this Agreement is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from this Agreement and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between You and Company as a result of this Agreement or use of the Website and Company Services.
        </div>
        <div className="legal-p">
          Upon Company’s request, You will furnish Company any documentation, substantiation or releases necessary to verify your compliance with this Agreement. You agree that this Agreement will not be construed against the Company by virtue of having drafted them. You hereby waive any and all defenses You may have based on the electronic form of this Agreement and the lack of signing by the parties hereto to execute this Agreement.
        </div>
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default TermsOfUseScreen;
