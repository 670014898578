import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Link } from 'react-router-dom';

const CourseCatalog = (props) => {
  // Allows us to track which of the 3 courses is selected
  // Names of 3 courses are: 'financialMath', 'personalFinance', 'collegeCareers', 'advancedInvesting'
  const [selectedCourse, setSelectedCourse] = useState('personalFinance');

  // Updates Selected Course
  function changeSelectedCourse(value) {
    setSelectedCourse(value);
  }

  function getCourseTitle() {
    if (selectedCourse === 'financialMath') {
      return 'Financial Math';
    } else if (selectedCourse === 'personalFinance') {
      return 'Personal Finance';
    } else if (selectedCourse === 'collegeCareers') {
      return 'College & Careers';
    } else if (selectedCourse === 'advancedInvesting') {
      return 'Advanced Investing';
    }
  }

  function getCourseGradeLevelsTimes() {
    if (selectedCourse === 'financialMath') {
      return (
        <div className='courses-subtitle-flex'>
          <div className='courses-subtitle'>
            Grades 6-8th
          </div>
          <div className='courses-subtitle' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            |
          </div>
          <div className='courses-subtitle'>
            ~25 Hours of Content
          </div>
        </div>
      );
    } else if (selectedCourse === 'personalFinance') {
      return (
        <div className='courses-subtitle-flex'>
          <div className='courses-subtitle'>
            Grades 6-12th
          </div>
          <div className='courses-subtitle' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            |
          </div>
          <div className='courses-subtitle'>
            ~90 Hours of Content
          </div>
        </div>
      );
    } else if (selectedCourse === 'collegeCareers') {
      return (
        <div className='courses-subtitle-flex'>
          <div className='courses-subtitle'>
            Grades 9-12th
          </div>
          <div className='courses-subtitle' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            |
          </div>
          <div className='courses-subtitle'>
            ~20 Hours of Content
          </div>
        </div>
      );
    } else if (selectedCourse === 'advancedInvesting') {
      return (
        <div className='courses-subtitle-flex'>
          <div className='courses-subtitle'>
            Grades 11-12th
          </div>
          <div className='courses-subtitle' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            |
          </div>
          <div className='courses-subtitle'>
            ~35 Hours of Content
          </div>
        </div>
      );
    }
  }

  function getCourseDescription() {
    if (selectedCourse === 'financialMath') {
      return `Explore the mathematical foundations of personal finance, including interest rates, budgeting, and financial planning, to develop essential skills for managing money effectively.`;
    } else if (selectedCourse === 'personalFinance') {
      return 'Learn fundamental principles of personal finance, including saving, investing, credit management, and financial decision-making, to build a strong financial foundation.';
    } else if (selectedCourse === 'collegeCareers') {
      return 'Gain insights into educational and career planning, understanding the financial aspects of higher education, career choices, and long-term financial goals.';
    } else if (selectedCourse === 'advancedInvesting') {
      return `Delve into sophisticated investment strategies, portfolio management, and risk assessment to enhance your understanding of the financial markets and maximize investment returns.`;
    }
  }

  function getCourseTopics() {
    if (selectedCourse === 'financialMath') {
      return [['Probabilities', 'Statistics', 'Exponents'], ['Linear Equations', 'Pre Algebra', 'Financial Algebra']];
    } else if (selectedCourse === 'personalFinance') {
      return [['Budgeting', 'Saving', 'Taxes', 'Banking', 'Credit'],['Loans', 'Investing', 'Managing Risk', 'Insurance', 'Retirement']];
    } else if (selectedCourse === 'collegeCareers') {
      return [['Financial Aid', 'Job Income', 'Paying For College'],['Buying Your First Home', 'Career Preferences', 'Applying To Jobs']];
    } else if (selectedCourse === 'advancedInvesting') {
      return [['Fundamental Analysis', 'Technical Analysis', 'Shorting A Stock'],['ETFs & Mutual Funds', 'Reading Financials', 'ESG & Social Impact Investing']];
    }
  }

  function getImagePath() {
    if (selectedCourse === 'financialMath') {
      return '/images/Courses/FinancialMath621.webp';
    } else if (selectedCourse === 'personalFinance') {
      return '/images/Courses/PersonalFinance621.webp';
    } else if (selectedCourse === 'collegeCareers') {
      return '/images/Courses/CollegeCareers621.webp';
    } else if (selectedCourse === 'advancedInvesting') {
      return '/images/Courses/AdvancedInvesting621.webp';
    } else {
      return ''
    }
  }

  return (
    <div className='courses-top-section'>
      {!props.alt && (
        <div>
          <div className='flex-courses-dot-header'>
            <div className='flex-courses-dot' />
            <div className='flex-courses-dot' />
            <div className='flex-courses-dot' />
            <div className='flex-courses-dot' />
            <div className='flex-courses-dot' />
          </div>
          <h1 className='courses-header' style={{ paddingTop: 0, color: '#0d5241' }}>
            Personal Finance Courses<br/>For Any Classroom...
          </h1>
        </div>
      )}
      {props.alt && !props.isParents && (
        <h1 className='courses-header portal-h1' style={{ paddingTop: 20 }}>
          Pre-built, Fully Customizable
          <br/>
          Personal Finance Courses
        </h1>
      )}
      {props.alt && !props.isParents && (
        <div className='courses-subheader'>
          Equip students with essential financial life skills while <Link className="article-link" to="/requestStandards">satisfying state standards</Link> across Math, Econ, Business & CTE.
        </div>
      )}
      {props.alt && props.isParents && (
        <h1 className='courses-header portal-h1' style={{ paddingTop: 20 }}>
          Fully Customizable
          <br/>
          Personal Finance Courses For Parents
        </h1>
      )}
      {props.alt && props.isParents && (
        <div className='courses-subheader'>
          We allow parents to leverage our prebuilt personal finance courses and investing courses as a starting point to create the perfect personal finance course for your kid, all while aligning with state learning and graduation requirements.
        </div>
      )}
      <div className='courses-toggle-container'>
        <div className='courses-toggle-header-flex'>
          <div className={`courses-toggle-header-item ${selectedCourse === 'financialMath' ? 'courses-toggle-header-selected' : 'courses-toggle-header-unselected'}`} onClick={() => changeSelectedCourse('financialMath')}>
            Financial Math
          </div>
          <div className={`courses-toggle-header-item ${selectedCourse === 'personalFinance' ? 'courses-toggle-header-selected' : 'courses-toggle-header-unselected'}`} onClick={() => changeSelectedCourse('personalFinance')}>
            Personal Finance
          </div>
          <div className={`courses-toggle-header-item ${selectedCourse === 'collegeCareers' ? 'courses-toggle-header-selected' : 'courses-toggle-header-unselected'} extra-header-option2`} onClick={() => changeSelectedCourse('collegeCareers')}>
            College & Careers
          </div>
          <div className={`courses-toggle-header-item ${selectedCourse === 'advancedInvesting' ? 'courses-toggle-header-selected' : 'courses-toggle-header-unselected'} extra-header-option`} onClick={() => changeSelectedCourse('advancedInvesting')}>
            Advanced Investing
          </div>
        </div>
        <div className='courses-toggle-main-card' style={{ marginBottom: !props.alt ? '20px' : '130px' }}>
          <div className='course-catalog-image-flex'>
            <img className='course-catalog-image' src={getImagePath()} alt={`${selectedCourse} financial literacy Rapunzl Course Icon`} />
            <div>
              <h2 className='courses-title'>
                {getCourseTitle()}
              </h2>
              {getCourseGradeLevelsTimes()}
            </div>
          </div>
          <div className='courses-toggle-main-card-flex'>
            <div className='courses-main-left'>
              <div className='course-description'>
                {getCourseDescription()}
              </div>
              <h3 className='courses-sub-title'>
                Topics Covered:
              </h3>
              <div className='course-content-flex'>
                <div className='course-content-column'>
                  {getCourseTopics()[0].map((item) => {
                    return (
                      <div className='course-content-item'>
                        <StarIcon className='course-item-icon' />
                        <div className='course-item-text'>
                          {item}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='course-content-column'>
                  {getCourseTopics()[1].map((item) => {
                    return (
                      <div className='course-content-item'>
                        <StarIcon className='course-item-icon' />
                        <div className='course-item-text'>
                          {item}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className='courses-main-right'>
              <h3 className='courses-sub-title'>
                Available Resources:
              </h3>
              <div className='course-content-item'>
                <RadioButtonUncheckedIcon className='course-item-icon2' />
                <h4 className='course-item-text' style={{ fontWeight: '400' }}>
                  In-Depth Lesson Plans
                </h4>
              </div>
              <div className='course-content-item'>
                <RadioButtonUncheckedIcon className='course-item-icon2' />
                <h4 className='course-item-text' style={{ fontWeight: '400' }}>
                  Accompanying {props.isParents? 'Parent' : 'Teacher'} Guides
                </h4>
              </div>
              <div className='course-content-item'>
                <RadioButtonUncheckedIcon className='course-item-icon2' />
                <h4 className='course-item-text' style={{ fontWeight: '400' }}>
                  Presentations Accessible Via PDF & Google Slides 
                </h4>
              </div>
              <div className='course-content-item'>
                <RadioButtonUncheckedIcon className='course-item-icon2' />
                <h4 className='course-item-text' style={{ fontWeight: '400' }}>
                  Interactive Activities & Articles
                </h4>
              </div>
              <div className='course-content-item'>
                <RadioButtonUncheckedIcon className='course-item-icon2' />
                <h4 className='course-item-text' style={{ fontWeight: '400' }}>
                  Integrated Multiple Choice Assessments
                </h4>
              </div>
              <Link to={`/course/${selectedCourse}`}>
                <div className='courses-button'>
                  View {getCourseTitle()} Course
                </div>
              </Link>
              <Link to='/requestStandards'>
                <div className='courses-button' style={{ marginTop: 10 }}>
                  Align With Your State Standards
                </div>
              </Link>
            </div>
          </div>
          <div className='courses-subtext-languages'>
            All curriculum content is available in English and Spanish and hosted through Google Cloud. This ensures all content is screen reader accessible and easy to integrate with existing classroom technology.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCatalog;