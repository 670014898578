import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import GetInTouch from "../components/Educators/GetInTouch";
import { Helmet } from 'react-helmet-async';
import { MetaCoursesScreen } from "../constants/metaDescriptions";
import "../assets/styles/CoursesScreen.css";
import PresentationGuides from "../components/Educators/PresentationGuides";
import StandardsAligned from "../components/Educators/StandardsAligned";
import Accessibility from "../components/Educators/Accessibility";
import TopicsCovered from "../components/Courses/TopicsCovered";
import GamificationBenefits from "../components/Educators/GamificationBenefits";
import GoogleCloud from "../components/Home/GoogleCloud";
import CourseHeader from "../components/Courses/CourseHeader";

const CoursesScreen = (props) => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Personal Finance Courses</title>
        <meta name="description" content={MetaCoursesScreen} />
        <meta property="og:title" content="Rapunzl | Personal Finance Courses" />
        <meta property="og:description" content={MetaCoursesScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/course" />
        <link rel="canonical" href="https://www.rapunzl.com/course" />
      </Helmet>
      <Header />
      <div className="route-subcontainer gradient-route">
        <CourseHeader courseName={props.match.params.courseName} />     
        <TopicsCovered courseName={props.match.params.courseName} />
        <PresentationGuides />
        <StandardsAligned />
        <Accessibility screenName={'course'} />
        <GamificationBenefits isCourse={true} />
        <GoogleCloud />
        <GetInTouch />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default CoursesScreen;