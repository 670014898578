import React from "react";
import IntroductionTop from "../components/Home/IntroductionTop";
import EarnWhileYouLearn from "../components/Home/EarnWhileYouLearn";
import PressFeatures from "../components/Home/PressFeatures";
import RealWorldLearning from "../components/Home/RealWorldLearning";
import WhyRapunzl from "../components/Home/WhyRapunzl";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaHomeScreen } from "../constants/metaDescriptions";
import DataPrivacy from "../components/Home/DataPrivacy";
import HomeSchool from "../components/Home/HomeSchool";
import RapunzlInNumbers from "../components/Home/RapunzlInNumbers";
import TeacherTestimonials from "../components/Home/TeacherTestimonials";
import HowRapunzlWorksVideo from "../components/About/HowRapunzlWorksVideo";
import MeetTheMinds from "../components/Home/MeetTheMinds";
import "../assets/styles/HomeScreen.css";
import FAQHome from "../components/Home/FAQHome";

const HomeScreen = () => {
  return (
    
    <div className="route-subcontainer">
      <Helmet>
        <title>Rapunzl | Personal Finance Curriculum & Trading Simulator</title>
        <meta name="description" content={MetaHomeScreen} />
        <meta property="og:title" content="Rapunzl | Personal Finance Curriculum & Trading Simulator" />
        <meta property="og:description" content={MetaHomeScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/" />
        <link rel="canonical" href="https://www.rapunzl.com/" />
      </Helmet>
     <Header />
      <IntroductionTop />
      <RapunzlInNumbers />
      <EarnWhileYouLearn />
      <PressFeatures />
      <RealWorldLearning />
      <HowRapunzlWorksVideo />
      <MeetTheMinds />
      <FAQHome />
      <WhyRapunzl />
      <HomeSchool />
      <TeacherTestimonials includeContact={false} alt={true} />
      <DataPrivacy darkMode={true} />
      <GoToTop />
      <Footer />
    </div>
  );
};
export default HomeScreen;
