// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-img {
	width: 400px;
    height: auto;
	margin: auto;
	display: block;
	margin-bottom: 10px;
    margin-top: 150px;
}
.not-found-h1 {
	font-size: 24px;
    color: #a7ffdb;
    text-align: center;
    padding-bottom: 8px;
    margin: auto;
}
.not-found-p {
	font-size: 18px;
    font-weight: 200;
    color: #ffffff;
    text-align: center;
    width: 90%;
    margin: auto;
    max-width: 350px;
    margin-bottom: 95px;
}
.not-found-return-button {
    width: 250px;
    margin: auto;
    text-align: center;
    background-color: #05291f;
    padding-top: 18px;
    padding-bottom: 18px;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    transition: 0.2s all;
    cursor: pointer;
}
.not-found-return-button:hover {
    background-color: white;
    color: #05291f;
}
.not-found-issue-button {
    text-align: center;
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
    color: #00ff8d;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Admin/NotFoundScreen.css"],"names":[],"mappings":"AAAA;CACC,YAAY;IACT,YAAY;CACf,YAAY;CACZ,cAAc;CACd,mBAAmB;IAChB,iBAAiB;AACrB;AACA;CACC,eAAe;IACZ,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;AACA;CACC,eAAe;IACZ,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,uBAAuB;IACvB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".not-found-img {\n\twidth: 400px;\n    height: auto;\n\tmargin: auto;\n\tdisplay: block;\n\tmargin-bottom: 10px;\n    margin-top: 150px;\n}\n.not-found-h1 {\n\tfont-size: 24px;\n    color: #a7ffdb;\n    text-align: center;\n    padding-bottom: 8px;\n    margin: auto;\n}\n.not-found-p {\n\tfont-size: 18px;\n    font-weight: 200;\n    color: #ffffff;\n    text-align: center;\n    width: 90%;\n    margin: auto;\n    max-width: 350px;\n    margin-bottom: 95px;\n}\n.not-found-return-button {\n    width: 250px;\n    margin: auto;\n    text-align: center;\n    background-color: #05291f;\n    padding-top: 18px;\n    padding-bottom: 18px;\n    border-radius: 10px;\n    color: white;\n    font-size: 14px;\n    font-weight: 500;\n    margin-bottom: 20px;\n    transition: 0.2s all;\n    cursor: pointer;\n}\n.not-found-return-button:hover {\n    background-color: white;\n    color: #05291f;\n}\n.not-found-issue-button {\n    text-align: center;\n    padding: 15px;\n    font-size: 13px;\n    font-weight: 500;\n    color: #00ff8d;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
