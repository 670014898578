import React from "react";
import "../../assets/styles/EducatorsScreen.css";
import { Link } from "react-router-dom";

const SummerCampInfo = () => {
  return (
    <div className="educator-section reverse-educator-section" style={{ paddingTop: '0px', paddingBottom: '200px' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="Personal Finance Curriculum & Scholarships History" src="/images/Educators/SummerCamp.webp"/>
      <div>
        <h2 className="educator-header">
          Easy To Setup Financial Literacy Summer Camp
        </h2>
        <div className="educator-text">
        Since 2020, Rapunzl has partnered with the WNBA's Chicago Sky Cares Foundation to host  a financial literacy summer camp that has impacted nearly 500 inner-city students across Chicago.
          <br/><br/>
          Each morning, Rapunzl covers a different personal finance topics, and each after students interact with finance professionals to build career exposure.
          <br/><br/>
          Over the past 3 years, our students have averaged 93% on a national financial capabilities assessment - 26% higher than the national average - and we now provide schools and districts with a plug-and-play program to replicate this experience.
        </div>  
        <div className="get-in-touch-flex">
          <Link to="/summer">
            <div className="checkout-rapunzl">
              Learn About Summer Program
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SummerCampInfo;