import React from "react";
import { Helmet } from 'react-helmet-async';
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import "../assets/styles/LegalScreen.css";
import { MetaCompetitionRulesScreen } from "../constants/metaDescriptions";

const CompetitionRulesScreen = () => {
  return (
    <div className="route-subcontainer" style={{ paddingTop: "90px", marginTop: '0px', paddingBottom: '150px' }}>
      <Helmet>
        <title>Rapunzl | Investing Competition Rules</title>
        <meta name="description" content={MetaCompetitionRulesScreen} />
        <meta property="og:title" content="Rapunzl | Investing Competition Rules" />
        <meta property="og:description" content={MetaCompetitionRulesScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/rules" />
        <link rel="canonical" href="https://www.rapunzl.com/rules" />
      </Helmet>
      <Header />
      <h1 className="legal-h1">Rapunzl Simulated Investment Competitions of Skill - Rules & Terms</h1>
      <div className="legal-h4">
        Last Updated January 1st, 2024
      </div>
      <div className="legal-h2">
        Section 1. General Disclaimer and Information
      </div>
      <div className="legal-p">
        NO PURCHASE IS NECESSARY TO ENTER OR WIN. ANY AND ALL PURCHASES DO NOT INCREASE THE CHANCES OF WINNING. ALL PROMOTIONS AND CAMPAIGNS ARE GOVERNED BY THE LAWS OF THE UNITED STATES OF AMERICA AND ILLINOIS, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. ANY VIOLATION OF THESE OFFICIAL RULES BY WINNER, AT THE COMPANY’S SOLE DISCRETION, WILL RESULT IN WINNER’S DISQUALIFICATION AS WINNER OF THE CAMPAIGN, ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED, AND THE WINNER WILL BE PROHIBITED FROM ENTRY INTO ADDITIONAL CAMPAIGNS.
      </div>
      <div className="legal-p">
        All capitalized terms which are not defined herein should be taken upon the definitions outlined in Rapunzl’s Terms of User and Service which is incorporated by reference herein and implicitly agreed to by any User that engages in Campaigns defined herein.
      </div>
      <div className="legal-h2">
        Section 2. Description and Release
      </div>
      <div className="legal-p">
        These Competition Rules (the “Rules”) provide details and rules related to free contests  (the “Campaigns”) where users can simulate stock portfolios and compete in free, skill-based Contests for prizes which vary. Each Campaign is conducted for marketing and promotional purposes and there is no consideration provided by the Contest entrant. By entering into any Campaign further defined in Section 3, You, the Contestant, confirm that you have affirmatively reviewed, accepted and agreed to all the Rules defined herein. You, Contestant, and the Rules are also collectively defined herein.
      </div>
      <div className="legal-h2">
        Section 3. Eligibility
      </div>
      <div className="legal-p">
        Campaigns are open only to Rapunzl users who enter the Campaign through the Rapunzl App (the “App”) available for iOS and Android mobile devices and who are at least 18 years of age as of the date of entry. Campaigns are only open to legal residents of the United States of America, and are individually void where prohibited by law. Employees of Rapunzl Investments LLC (the “Company”), of 323 West Schiller Street, Suite 1W, Chicago, IL 60610 (the “Address”), its affiliates, subsidiaries, advertising and promotion agencies, and suppliers, (collectively, the “Employees''), and immediate family members and, or, those living in the same household of Employees are eligible to participate in Campaigns, however, they receive no material advantage over other entrants. Campaigns are subject to all applicable federal, state, and local laws and regulations; and Campaigns are individually, or collectively, void where prohibited. 
      </div>
      <div className="legal-h2">
        Section 4. Agreement to Rules
      </div>
      <div className="legal-p">
        By participating, the Contestant (“You”) agrees to be fully and unconditionally bound by these competition rules (the “Rules”), and You represent and warrant that You meet the eligibility requirements. In addition, You agree to accept the decisions of the Company as final and binding as it relates to the content of each Campaign. In addition, when You enter a Campaign (the “Entry”), this Entry constitutes permission for the Company to use any name, likeness, social media presence, investment portfolio composition, and Entry associated with You for purposes including, but not limited to, advertising and licensing, without further compensation, unless prohibited by law.
      </div>
      <div className="legal-h2">
        Section 5. Campaign Period
      </div>
      <div className="legal-p">
        Entries will be accepted online through the Platform for the duration of each Campaign, however, this is subject to change at the Company's sole discretion without warning or notice.
      </div>
      <div className="legal-h2">
        Section 6. How To Enter
      </div>
      <div className="legal-p">
        Each Campaign must be entered by submitting an entry using the App (the “Entry”). The Entries must accept the Company’s Terms of Use, accessible at (URL), and adhere to the Rules to be eligible to win a prize. An Entry that is incomplete or does not adhere to the Rules or other specifications outlined herein may be disqualified at the sole discretion of the Company. You may enter each Campaign only once. You must provide the information requested.
      </div>
      <div className="legal-h2">
        Section 7. Multiple Entries
      </div>
      <div className="legal-p">
        You may not enter more times than indicated by using multiple email addresses, identities, or devices in an attempt to circumvent the Rules. If you use fraudulent methods or otherwise attempt to circumvent the rules, your submission may be removed from eligibility and entry into future Campaigns may be prohibited at the sole discretion of the Company.
      </div>
      <div className="legal-h2">
        Section 8. Cash Prizes
      </div>
      <div className="legal-p">
        The Winner(s) of the Campaign (the “Winner”) will receive the allocated prize as detailed on the specific Campaign’s component accessible via the Rapunzl app. The specifics of the prize shall be solely determined by the Company. Substitution of prize or transfer or assignment of prize to others by Winner is expressly prohibited. Any and all prize-related expenses, including without limitation, any and all federal, state, and, or, local taxes, shall be the sole responsibility of the Winner. The prize is non-transferrable and no substitution of prize or transfer or assignment of prize to others or request for the cash equivalent by the Winner is permitted. Prizes must be redeemed by the Winner within 90 days of the Campaign end date through the Rapunzl App or prizes will be forfeit to the Company. Additionally, the Winner must access the Rapunzl App within 90 days prior to the completion of the Campaign, or else prizes will be forfeited to the company. Any and all determinations of Winner activity on the Rapunzl App will be solely determined by the Company and all decisions are final.
      </div>
      <div className="legal-h2">
        Section 9. Non-Cash Prizes
      </div>
      <div className="legal-p">
        In addition to Section 9, there are additional rules related to Campaigns which do not offer cash prizes (the “Non-Cash Campaigns”) which may include, but not limited to, scholarships, virtual in-app currency, and physical goods. For Non-Cash Campaigns, actual and appraised value of the allocated prize may differ at time of prize award. No cash or other prize substitution shall be permitted except at the Company’s sole discretion.  The prize may change at any time.
      </div>
      <div className="legal-h2">
        Section 10. Winner Selection
      </div>
      <div className="legal-p">
        Winners will be selected by ranking portfolio performance during the duration of the Campaign, under the supervision of the Company’s proprietary trade simulator. 
      </div>
      <div className="legal-h2">
        Section 11. Winner Notification
      </div>
      <div className="legal-p">
        Winners will be notified by email within fifteen (15) days following selection of winners (the “Award Notification”). The Company shall have no liability for Winner’s failure to receive notices of winning due to reasons including, but not limited to, spam, junk e-email, other security settings, or for Winner’s provision of incorrect or otherwise non-functioning contact information. If Winner cannot be contacted, is ineligible, fails to claim the prize within 90 days from the date the Award Notification is sent, the prize will be forfeited to the Company. Receipt by Winner of the prize offered in this Campaign is conditioned upon compliance with any and all federal, state, and local laws and regulations.
      </div>
      <div className="legal-h2">
        Section 12. Rights Granted By You
      </div>
      <div className="legal-p">
        By entering this Campaign, You understand and agree that the Company, anyone acting on behalf of the Company, and the Company’s licensees, successors, and assignees, shall have the right, where permitted by law, to print, publish, broadcast, and distribute, and use in any media now known or hereafter developed in perpetuity, and throughout the World, without limitation, your entry, name, portrait, picture, voice, likeness, image, statements about the Campaign, and biographical information for news, publicity, information, trade, advertising, public relations, sponsorship solicitation, and promotional purposes, without any further compensation, notice, review, or consent. You will participate legally and lawfully in this Campaign and shall indemnify, defend, and hold the Company harmless from and against any suit, proceeding, claim, liability, loss, damage, costs or expenses, which the Company may incur, suffer, or be required to pay arising out of such infringement or suspected infringement of any third party’s right.
      </div>
      <div className="legal-h2">
        Section 13. Terms And Conditions
      </div>
      <div className="legal-p">
        The Company reserves the right, in its sole discretion, to cancel, terminate, modify, or suspend any Campaign should virus, bugs, non-authorized human intervention, fraud, or other cause beyond the Company’s control corrupt or affect the administration, security, veracity, fairness, or proper conduct of the Campaign. In such a case, the Company may select the winner from all eligible entries received prior to and/or after (if appropriate) the action taken by the Company or elect, in its sole discretion, to allocate prizes to a future Campaign. The Company reserves the right, in its sole discretion, to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the Campaign, App, or Website or violates either these Terms & Conditions or the Terms of Use accessible on the Company App and Website, which You implicitly agree to understand and be bound by when creating an account with the Website. The Company has the right, in its sole discretion, to maintain the integrity of the Campaign, to void Entries for any reason, including, but not limited to: multiple entries from the same user; multiple entries from the same computer in excess of that allowed by Campaign rules; or the use of bots, macros, scripts, or other technical means for entering. Any attempt by an entrant to deliberately damage any website or undermine the legitimate operation of the Campaign may be a violation of criminal and civil laws. Should such an attempt be made, the Company reserves the right and has every intention to seek damages to the fullest extent permitted by law.
      </div>
      <div className="legal-h2">
        Section 14. Limitation Of Liability
      </div>
      <div className="legal-p">
        By entering, You agree to release and hold the Company harmless and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assignees, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly, whether caused by negligence or not, from:
      </div>
      <div className="legal-li">
        <b>A. </b>Such entrant’s participation in the Campaign and/or their acceptance, possession, use, or misuse of any prize or any portion thereof;
      </div>
      <div className="legal-li">
        <b>B. </b>Technical failures of any kind, including but not limited to the malfunction of any mobile device, computer, cable, network, hardware, or software, or other mechanical equipment;
      </div>
      <div className="legal-li">
        <b>C. </b>The unavailability or inaccessibility of any transmissions, telephone, or Internet Service;
      </div>
      <div className="legal-li">
        <b>D. </b>Unauthorized human intervention in any part of the entry process or the Campaign; and
      </div>
      <div className="legal-li">
        <b>E. </b>Electronic or human error in the administration of the Campaign or the processing of entries.
      </div>
      <div className="legal-h2">
        Section 15. Disputes
      </div>
      <div className="legal-p">
        As a condition of participating in a Campaign, You agree that any and all disputes that cannot be resolved between the parties, and causes of action arising out of or connected with this Campaign, shall be resolved individually, without resort to any form of class action, exclusively before a court located in Illinois having jurisdiction. Further, in any such dispute, under no circumstances shall You be permitted to obtain awards for, and hereby waives all rights to, punitive, incidental, or consequential damages, including reasonable attorney’s fees. Participant further waives all rights to have damages increased or multiplied.
      </div>
      <div className="legal-h2">
        Section 16. Privacy Policy
      </div>
      <div className="legal-p">
        Information submitted with each Entry is subject to Rapunzl’s Privacy Policy stated on the Company Website and App. To read the full Privacy Policy, it is publicly available on the Company Website.
      </div>
      <div className="legal-h2">
        Section 17. Winner’s List
      </div>
      <div className="legal-p">
        To obtain a copy of the Winner name(s) from any Campaign mail your request along with a stamped, self-addressed envelope, and a $25 processing fee in the form of cash or check, to the Address defined in Section 3.
      </div>
      <div className="legal-h2">
        Section 18. Official Rules List
      </div>
      <div className="legal-p">
        To obtain a copy of these Rules, mail your request along with a stamped, self-addressed envelope to the Address. Also include a check addressed to Rapunzl Investments for $25 to cover the processing fee.
      </div>
      <div className="legal-h2">
        Section 19. Sponsor And Endorsement
      </div>
      <div className="legal-p">
        The Company is the Sponsor of each Campaign. The Campaign hosted by the Company is in no way endorsed, administered by, or associated with Apple, Google, Facebook, Twitter, Instagram, Stripe, any of their affiliates, or any other networking, data, or payments platforms other than the Company.
      </div>
      <div className="legal-h2">
        Section 20. Prohibited Conduct
      </div>
      <div className="legal-p">
        The following activities are strictly prohibited and are grounds for elimination from the campaign and further action at the sole discretion of the Company.
      </div>
      <div className="legal-li">
        <b>A. </b>Posting unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation (collectively referred to as “Spamming”);
      </div>
      <div className="legal-li">
        <b>B. </b>Postings for any unlawful or fraudulent purposes (including links that redirect to content which is interpreted unlawful or fraudulent at the Company’s sole discretion);
      </div>
      <div className="legal-li">
        <b>C. </b>Posting materials containing viruses or other malicious or destructive code;
      </div>
      <div className="legal-li">
        <b>D. </b>Posting of offensive content including, but not limited to: profanity, obscenity, racist or pornographic material;
      </div>
      <div className="legal-li">
        <b>E. </b>Engaging in any activities or Posting any materials that are defamatory, violate the personal privacy rights of others, or infringe any person’s rights, including but not limited to, collecting and distributing information about Users without their permission, except as permitted by applicable law;
      </div>
      <div className="legal-li">
        <b>F. </b>Posting any content that is unlawful, harmful, threatening, abusive, intentionally misleading, harassing, tortious, defamatory, vulgar, obscene, libelous, hateful, or otherwise objectionable in any way at the Company’s sole discretion;
      </div>
      <div className="legal-li">
        <b>G. </b>Posting any content that degrades others on the basis of gender, age, race, class, ethnicity, national origin, religion, sexual preference, or disability;
      </div>
      <div className="legal-li">
        <b>H. </b>Copying, modifying, creative a derivative of work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of the Website, App, or other Company technology and related assets;
      </div>
      <div className="legal-li">
        <b>I. </b>Removing or obscuring the copyright notice or other notices displayed in connection with the content accessible through the Website, App, or other Company Services;
      </div>
      <div className="legal-li">
        <b>J. </b>Interfering with or disrupting the Company’s functionalities, Services, or servers and networks connected to the Company, or disobey any requirements, procedures, policies, or regulations of networks connected directly or indirectly to or by the Company;
      </div>
      <div className="legal-li">
        <b>K. </b>Modifying the Website or Ap or Company Services in any manner or form, or use modified versions of the Company Technology, including (without limitation) for the purpose of obtaining unauthorized access to Company Technology or Services;
      </div>
      <div className="legal-li">
        <b>L. </b>Reproducing, printing, caching, storing, or distributing content retrieved from the Company Technology or Services in any way not authorized by this document or for any commercial use without the prior written permission of the Company;
      </div>
      <div className="legal-li">
        <b>M. </b>Selling, assigning, sublicensing, or otherwise transferring any right in the Services or content accessible through Company Technology;
      </div>
      <div className="legal-li">
        <b>N. </b>Accessing Company Technology or Services by any means other than through the interfaces that are provided by the Company.
      </div>
      <div className="legal-li">
        <b>O. </b>Using the Company Technology or Services to violate any law, whether local, state, national, or international, whether or not intentionally;
      </div>
      <div className="legal-li">
        <b>P. </b>Impersonating any person or entity, or falsely state or otherwise misrepresent your affiliation with any person or entity;
      </div>
      <div className="legal-li">
        <b>Q. </b>Manipulating identifiers in order to disguise the origin of any question, answer, or comment you post on Company Technology; and
      </div>
      <div className="legal-li">
        <b>R. </b>Engaging in any processes such as screen scraping, database scraping, or any other automated method to extract proprietary data in connection with the Company and the Company’s users.
      </div>
      <div className="legal-h2">
        Section 21. Unfair Practices
      </div>
      <div className="legal-p">
        In addition to the prohibited conduct listed above, the Company has also recognized the following rules in order to ensure competition fairness.
      </div>
      <div className="legal-li">
        <b>A. </b>You cannot have any position constitute greater than 25% of their portfolio;
      </div>
      <div className="legal-li">
        <b>B. </b>You must not attempt to arbitrage pricing disparities between NASDAQ Last Sale Prices and the execution prices that occur on other exchanges. The Company reserves the right, in its sole discretion, to classify transactions in violation of these Unfair Practices; or
      </div>
      <div className="legal-li">
        <b>C. </b>You must not unfairly attempt to manipulate Company pricing data to obtain an unfair advantage over other entrants.
      </div>
      <div className="legal-h2">
        Section 22. Expulsion From The Campaign
      </div>
      <div style={{ padingBottom: 140 }}>
        A User may be expelled from the Campaign at any time without cause or justification at their sole discretion (the “Expelled User”). It will be left to the Company to decide if a user has violated the Rules or Unfair Practices, and any Company decisions regarding the matter are final. The Company also reserves the right to seek punitive, incidental, and consequential damages from the Expelled User.
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default CompetitionRulesScreen;
