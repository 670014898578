import React from "react";

const CryptoHeader = () => {
  return (
    <div className="crypto-s1" style={{ backgroundImage: `url(/images/Crypto/SpaceBG.jpg)` }}>
      <h1 className="s1-header">
        Rapunzl's Real-Time Investment Simulator <span style={{ color: '#ff8800' }}>Also Has Crypto</span>
      </h1>
    </div>
  );
};

export default CryptoHeader;