import React from "react";

const SavingStandard = () => {
  return (
    <div className="educator-section national-standard-container" style={{ margin: 'auto' }}>
      <img loading="lazy" id="Educator-2" className="educator-section-image" alt="30-day-trial to access Rapunzl curriculum for free" src="/images/Standards/Saving.webp"/>
      <div  className="standard-margin-shift-2">
        <h2 className="national-standard-subtext">
          Financial Literacy Strand 2:
          <br/>
          <span className="national-standard-bold">Saving</span>
        </h2>
        <div className="educator-text" style={{ width: '100%' }}>
          This financial literacy pillar teaches students the importance of setting aside money for future needs and goals.
          <br/><br/>
          By learning to save, students develop habits that promote financial security and the ability to handle unexpected expenses. 
          <br/><br/>
          Understanding the concept of saving helps students appreciate delayed gratification, the long-term benefits of financial planning and the power of financial responsibility.
        </div>  
      </div>
    </div>
  );
};

export default SavingStandard;