import React from "react";
import "../../assets/styles/EducatorsScreen.css";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const InAModule = () => {
  return (
    <div className="educator-section">
      <img loading="lazy" id="Educator-1" className="educator-section-image" alt="Rapunzl personal finance curriculum mdoules for middle and high school" src="/images/Educators/ElevateMath.webp"/>
      <div>
        <h2 className="educator-header">
          Expansive Personal Finance Resources With Each Module
        </h2>
        <div className="educator-text">
          Rapunzl has a library with 100+ hours of financial literacy resources to help you cover the most important aspects of each financial topic.
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
          Real-Time Simulator
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
          In-depth articles
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
          Interactive activities
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
          Shareable Presentations
          </div>
        </div>
        <div className="educator-bullet">
          <ArrowRightAltOutlinedIcon className="educator-bullet-icon"/>
          <div className="educator-bullet-text">
          Printable worksheets
          </div>
        </div>
        <div className="get-in-touch-flex">
          <a href="/images/PDFs/DemoPresentation.pdf" target="_blank">
            <div className="checkout-rapunzl" style={{ marginRight: "5px" }}>
              View Sample Rapunzl Curriculum Module
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default InAModule;