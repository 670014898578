import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import RealTimeData from "../components/Educators/RealTimeData";
import { Helmet } from 'react-helmet-async';
import { MetaDistrictsScreen } from "../constants/metaDescriptions";
import "../assets/styles/CoursesScreen.css";
import TeacherTestimonials from "../components/Home/TeacherTestimonials";
import PortalHeader from "../components/Educators/PortalHeader";
import ScholarshipsInfo from "../components/Educators/ScholarshipsInfo";
import StandardsAligned from "../components/Educators/StandardsAligned";
import PlugAndPlay from "../components/Educators/PlugAndPlay";
import SkillDevelopment from "../components/Educators/SkillDevelopment";
import DataPrivacy from "../components/Home/DataPrivacy";
import ElevateMath from "../components/Educators/ElevateMath";
import StartingInvestmentClub from "../components/Educators/StartingInvestmentClub";
import SummerCampInfo from "../components/Educators/SummerCampInfo";
import CourseDelivery from "../components/Educators/CourseDelivery";

const DistrictLeaders = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | Personal Finance At Your District</title>
        <meta name="description" content={MetaDistrictsScreen} />
        <meta property="og:title" content="Rapunzl | Personal Finance At Your District" />
        <meta property="og:description" content={MetaDistrictsScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/districts" />
        <link rel="canonical" href="https://www.rapunzl.com/districts" />
      </Helmet>
      <Header />
      <div className="route-subcontainer gradient-route">     
        <PortalHeader districtURL={true} />   
        <ScholarshipsInfo />
        <PlugAndPlay />
        <SkillDevelopment />
        <ElevateMath />
        <RealTimeData />
        <CourseDelivery />
        <StandardsAligned />
        <StartingInvestmentClub />
        <SummerCampInfo />
        <TeacherTestimonials includeContact={true} />
        <DataPrivacy darkMode={true} />
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default DistrictLeaders;