import React from 'react';

const ArticleListComponent = (props) => {
  return (
    <div key={props.index} className='article-list-section'>
      <div className='flex-courses-dot-header' style={{ paddingTop: 0 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
      <h2 className='article-list-title'>
        {props.data.title}
      </h2>
      <h3 className='article-list-subtitle'>
        {props.data.subtitle}
      </h3>
      {props.data.content.map((item, index) => {
        return (
          <div key={index} className='article-list-item'>
            <div  className='article-list-item-title'>
              {item.title}
            </div>
            {item.text.map((string,index2) => {
            return (
              <div key={index2} className='article-list-item-text'>
                {string}<br/><br/>
              </div>
            )})}
          </div>
      )})}
    </div>
  );
};
export default ArticleListComponent;