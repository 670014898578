import React from "react";
import { Link } from "react-router-dom";

const MeetTheMinds = () => {
  return (
    <div className="meet-minds-section">
      <div className='flex-courses-dot-header' style={{ paddingTop: 0 }}>
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
        <div className='flex-courses-dot' />
      </div>
      <img alt="invest smarter rapunzl brain " src="/images/Home/InvestSmarterBrain.png" className="meet-minds-image" />
      <h2 className="meet-minds-h2">
        Meet The Minds<br/>
        <span className="meet-minds-subtitle">
          Behind The Mission
        </span>
      </h2>
      <div className="meet-minds-text">
        Our founders met freshman year of high school in 2008, at the height of the financial crisis.
        <br/><br/>
        Learn why we launched Rapunzl in 2017 to transform financial education across the country.
      </div>
        <Link to="/about">
          <div className="meet-minds-button">
            More About Rapunzl's Team
          </div>
        </Link>
    </div>
  );
};

export default MeetTheMinds;