import React from "react";
import GoToTop from "../components/Admin/GoToTop";
import Header from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Helmet } from 'react-helmet-async';
import { MetaPrivacyPolicyScreen } from "../constants/metaDescriptions";
import '../assets/styles/LegalScreen.css';

const PrivacyPolicyScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Rapunzl | General & Student Privacy Policy</title>
        <meta name="description" content={MetaPrivacyPolicyScreen} />
        <meta property="og:title" content="Rapunzl | General & Student Privacy Policy" />
        <meta property="og:description" content={MetaPrivacyPolicyScreen} />
        <meta property="og:url" content="https://www.rapunzl.com/privacy" />
        <link rel="canonical" href="https://www.rapunzl.com/privacy" />
      </Helmet>
      <Header />
      <div className="route-subcontainer" style={{ paddingTop: "90px", marginTop: '0px', paddingBottom: '150px' }}>
        <h1 className="legal-h1">Rapunzl Privacy Policy</h1>
        <div className="legal-h4">
          Last Updated January 1st, 2022
        </div>
        <div className="legal-h2">
          Section 1: Overview
        </div>
        <div className="legal-p">
          Protecting your private information is our priority. This Statement of Privacy applies toRapunzl Investments LLC, an Illinois Limited Liability company at 323 West Schiller Street, Suite #1W, Chicago, IL 60610 (the “Company”) and all of its technology assets, including but not limited to the Rapunzl’s iOS Mobile App, Rapunzl’s Android Mobile App, Rapunzl’s Web Portal, and www.rapunzlinvestments.com (collectively and unlimitedly referred to as Rapunzl Technology).
        </div>
        <div className="legal-p">
          For the purposes of this Privacy Policy, unless otherwise noted, all references to the Company also refer to any Rapunzl Technology. Rapunzl Technology provides services as is, provided by the Company and is intended for us is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service.
        </div>
        <div className="legal-p">
          We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at www.rapunzlinvestments.com/legal.
        </div>
        <div className="legal-h2">
          Section 2. Collection Of Information
        </div>
        <div className="legal-h3">
          2.1 Personally-Identifiable Information
        </div>
        <div className="legal-p">
          To personalize your experience on the App, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Full Name, Email Address and Birthday; which may be retained in aggregate by the Company and used as described in this privacy policy. The app does use third-party services that may collect information used to identify you. These third-party services allow us to serve your profile with notifications, in-app purchases, crash analytics, and personalized ad recommendations linked to these services.
        </div>
        <div className="legal-p">
          Links to these sites can be found for Google Play Services, AdMob, Google Analytics for Firebase, and Firebase Crashlytics. We also want to inform you that whenever you use our Service, in order to rapidly resolve bugs and App issues, collect data and information on your device called Log Data. Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics to help reduce bugs and improve your simulated investing experience.
        </div>
        <div className="legal-h3">
          2.2 Device Information
        </div>
        <div className="legal-p">
          We may gather additional personal or non-personal information in the future. Information about your hardware and software may be automatically collected by Rapunzl. This information can include: your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of Rapunzl. Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through any of Rapunzl’s service, this may be collected and used by others.
        </div>
        <div className="legal-h3">
          2.3 In-App Purchases
        </div>
        <div className="legal-p">
          If you purchase Rapunzl’s products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.
        </div>
        <div className="legal-h3">
          2.4 Cookies
        </div>
        <div className="legal-p">
          This Service uses “cookies” throughout the Platform. However, the app may use third party code and libraries that use “cookies” to collect information, improve services and collect anonymous unique identifiers. In order to use major portions of this Service, you must accept cookies. These cookies are exclusively used to reduce data expenses and track crash analytics. No information will be collected that is personally identifiable or accessible by and third-party affiliates.
        </div>
        <div className="legal-h2">
          Section 3. Use Of Your Information
        </div>
        <div className="legal-h3">
          3.1 General
        </div>
        <div className="legal-p">
          Rapunzl collects and uses information outlined in S2.1, S2.2, and S2.3 to enhance, improve, and deliver the services you have requested. 
        </div>
        <div className="legal-h3">
          3.2 Student Data Considerations
        </div>
        <div className="legal-p">
          Rapunzl may share non-personally identifiable analytics data with educators, analytics partners, and third-party affiliates in order to improve the Company. Under the Family Educational Rights and Privacy Act (FERPA) personal student information including but not limited to a student’s Full Name, Birthdate, School Attended, Email address will remain confidential and protected by Rapunzl. Rapunzl does not collect information pertaining to a student’s location beyond asking the school a student attends. Under FERPA parents and eligible students (aged 18 and over) shall be able to access the student’s records upon request to Rapunzl. Rapunzl does not utilize personal student information to engage in the practice of data-mining to solicit or advertise products from third-party vendors to users under the age of 18 and or classified as high school users. Rapunzl may collect aggregate sentiment data related to user activity for internal purposes to better understand the patterns of our student users for the purpose of creating more tailored educational experiences.
        </div>
        <div className="legal-h3">
          3.3 General Data Usage
        </div>
        <div className="legal-p">
          Rapunzl may use your personally identifiable information to inform you of other products or services available from Rapunzl and its affiliates. Rapunzl may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered. Rapunzl may share data with partners to help perform statistical analysis, send you email, deliver postal mail, provide customer support, or arrange for deliveries.
        </div>
        <div className="legal-p">
          Rapunzl may keep track of the websites and pages our users visit within Rapunzl, in order to determine what Rapunzl services provide the best user experience. This data is used to deliver customized content and, potentially, advertising within Rapunzl to customers whose behavior indicates that they are interested in a particular subject area.
        </div>
        <div className="legal-p">
          Rapunzl will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Rapunzl; (b) to protect and defend the rights or property of Rapunzl; and, (c) act under exigent circumstances to protect the personal safety of users of Rapunzl, or the public.
        </div>
        <div className="legal-h3">
          3.4 Data Restrictions
        </div>
        <div className="legal-p">
          All such third parties are prohibited from using your personal information except to provide these services to Rapunzl, and they are required to maintain the confidentiality of your information. 
        </div>
        <div className="legal-h2">
          Section 4. Children Under Thirteen
        </div>
        <div className="legal-p">
          Rapunzl does not knowingly collect personally identifiable information from children under the age of thirteen. These Services do not address anyone under the age of 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
        </div>
        <div className="legal-h2">
          Section 5. Security of Your Personal Information 
        </div>
        <div className="legal-p">
          We value your trust in providing us your Personal Information. That’s why Rapunzl secures your personal information from unauthorized access, use or disclosure. When personal information, such as a credit card number or bank details, are transmitted to other websites, it is protected through the use of encryption in accordance with the Secure Sockets Layer (SSL) protocol.
        </div>
        <div className="legal-p">
          We leverage decades of experience in financial services to provide industry-leading encryption in order to protect your portfolio and data well beyond commercially-required means of protection. Remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
        </div>
        <div className="legal-p">
          Do not share your password with anyone at any time. The Company will not at any time contact you requesting your password and all such attempts are not authorized by the company. In the event of a security breach, passwords are encrypted and should not be compromised.
        </div>
        <div className="legal-h2">
          Section 6. Connecting With Third Party Websites
        </div>
        <div className="legal-p">
          This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. 
        </div>
        <div className="legal-p">
          However, by using Rapunzl, you may connect your Rapunzl account to third party accounts. Rapunzl encourages you to review the privacy statements of websites you choose to link to from Rapunzl so that you can understand how those websites collect, user, and share your information. Rapunzl is not responsible for the privacy statements or other content on websites outside of the Rapunzl website. 
        </div>
        <div className="legal-p">
          By connecting your Rapunzl Account to your Third-Party Account, you acknowledge and agree that you are consenting to the continuous release of information about you and others (in accordance with your privacy settings on those Third-Party Sites). If you do not want information about you, including personally identifiable information about you, including personally identifying information, to be shared in this manner, do not use this feature. You may disconnect your account from a third party account at any time. Users may learn how to disconnect their accounts from third party websites by visiting their account page, or similar. Users may also contact Rapunzl through contact details on their website.
        </div>
        <div className="legal-h2">
          Section 7. Changes To This Statement
        </div>
        <div className="legal-p">
          Rapunzl will occasionally update this Statement of Privacy to reflect company and customer feedback. Rapunzl encourages you to periodically review this Statement to be informed of how Rapunzl is protecting your information. We will notify you of any changes by posting the new Privacy Policy on this page. If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at hello@rapunzlinvestments.com.
        </div>
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
};
export default PrivacyPolicyScreen;
