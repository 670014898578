import React, { useState } from 'react';

const StandardsRequestScreen = (props) => {
  const [formData, setFormData] = useState({
    curriculumUse: '',
    roleInPurchasing: '',
    implementationTimeline: '',
    numberOfStudents: '',
    schoolName: '',
    titleOneStatus: '',
  });
  const [isError, setError] = useState(false);

  const handleChange = (event) => {
    setError(false);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  function validateFormData() {
    return Object.values(formData).every(value => value !== '');
  }

  function _handleNext() {
    // Validate The Form Data To Ensure That We Can Progress Or Show Error Message
    if (!validateFormData()) {
      setError(true);
    }
    // If All Is Clear, Then Handle Saving Form Data In Parent & Progressing
    else {
      props.handleLearningMore(formData);
    }
  }

  return (
    <div className='standards-request-container'>
      <div className='standards-request-progress-bar'>
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' />
        <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
        <div className='standards-request-progress-bar-inner' style={{ backgroundColor: '#0b392d' }} />
      </div>
      <div className='standards-input-container'>
        <h2 className='request-h2'></h2>
        <h3 className='request-h3'>
          What's The Name Of Your School Or District?
        </h3>
        <input
          onChange={(event) => handleChange(event)}
          type="email"
          id="schoolName"
          name="schoolName"
          className="contact-input"
          placeholder='School/District Name'
          required
          style={{ height: 50 }}
        ></input>
        <div>
          <h3 className='request-h3' style={{ paddingTop: 16 }}>Which best describes your school?</h3>
          <select
            className="request-dropdown dropdown-alt"
            name="curriculumUse"
            onChange={handleChange}
            style={{ color: formData.curriculumUse.length === 0 ? '#00ffbe' : '#FAFAFA' }}
          >
            <option value="">Select an option</option>
            <option value="Traditional Public High School">Traditional Public High School</option>
            <option value="Charter/Magnet Public High School">Charter/Magnet Public High School</option>
            <option value="Private / Faith-based">Private / Faith-based</option>
            <option value="Middle School">Middle School</option>
            <option value="Mircoschool">Mircoschool</option>
            <option value="Homeschool">Homeschool</option>
          </select>
        </div>
        <div>
          <h3 className='request-h3' style={{ paddingTop: 16 }}>What's your role in purchasing curriculum & supplementary tools?</h3>
          <select
            className="request-dropdown dropdown-alt"
            name="roleInPurchasing"
            onChange={handleChange}
            style={{ color: formData.roleInPurchasing.length === 0 ? '#00ffbe' : '#FAFAFA' }}
          >
            <option value="">Select an option</option>
            <option value="Final Decision Maker">Final Decision Maker</option>
            <option value="Contributing Decision Maker">Contributing Decision Maker</option>
            <option value="Advocate/Influencer">Advocate/Influencer</option>
            <option value="Advocate/Influencer">We Don't Purchase Curriculum</option>
          </select>
        </div>
        <div>
          <h3 className='request-h3' style={{ paddingTop: 16 }}>When do you plan to potentially bring Rapunzl to your classroom?</h3>
          <select
            className="request-dropdown dropdown-alt" 
            name="implementationTimeline" 
            onChange={handleChange}
            style={{ color: formData.implementationTimeline.length === 0 ? '#00ffbe' : '#FAFAFA' }}
          >
            <option value="">Select an option</option>
            <option value="Less than a month">Less than a month</option>
            <option value="1-3 months">1-3 months</option>
            <option value="4-6 months">4-6 months</option>
            <option value="6-12 months">6-12 months</option>
            <option value="More than a year">More than a year</option>
          </select>
        </div>
        <div>
          <h3 className='request-h3' style={{ paddingTop: 16 }}>Approximately, how many students will be using the curriculum?</h3>
          <select 
            className="request-dropdown dropdown-alt" 
            name="numberOfStudents" 
            onChange={handleChange}
            style={{ color: formData.numberOfStudents.length === 0 ? '#00ffbe' : '#FAFAFA' }}
          >
            <option value="">Select an option</option>
            <option value="1-50">1-50</option>
            <option value="50-250">50-250</option>
            <option value="250+">250+</option>
          </select>
        </div>
        <div>
          <h3 className='request-h3' style={{ paddingTop: 16 }}>Is your school a Title 1 school?</h3>
          <select 
            className="request-dropdown dropdown-alt" 
            name="titleOneStatus" 
            onChange={handleChange}
            style={{ color: formData.titleOneStatus.length === 0 ? '#00ffbe' : '#FAFAFA' }}
          >
            <option value="">Select an option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Not Sure">I'm Not Sure</option>
          </select>
        </div>
      </div>
      <div className='request-flex-buttons' style={{ paddingTop: 55 }}>
        <div className='request-back-button' onClick={() => props.handleBack()}>
          Back
        </div>
        <div onClick={() => _handleNext()} className={`request-next-button ${validateFormData() ? 'request-enabled' : 'request-disabled'}`}>
          Go
        </div>
      </div>
      <div className='request-error-message'>
        {isError ? 'Please fill out all of the form options to continue.' : ''}
      </div>
    </div>
  );
};
export default StandardsRequestScreen;