// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standards-cee-logo {
	width: 140px;
    margin-bottom: 6px;
}
.national-standard-subtext {
	font-size: 22px;
    font-weight: 200;
    line-height: 1.2;
    padding-bottom: 9px;
	border-bottom: 6px solid #ffb202;
    margin-bottom: 18px;
	color: #04392b;
}
.national-standard-bold {
    font-size: 40px;
    font-weight: 900;
	color: #007154;
}
.national-standard-container {
	padding-top: 0px;
	padding-bottom: 120px;	
	margin-bottom: 30px;
	margin-top: 125px;
}
.standard-margin-shift-1 {
	margin-right: 30px;
}
.standard-margin-shift-2 {
	margin-left: 30px;
}

@media only screen and (max-width: 750px) {
	.standard-margin-shift-1 {
		margin-right: 0px;
	}
	.standard-margin-shift-2 {
		margin-left: 0px;
	}
	.national-standard-container {
		margin-bottom: 30px;
		margin-top: 90px;
		padding-bottom: 105px;
	}
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/NationalStandardsScreen.css"],"names":[],"mappings":"AAAA;CACC,YAAY;IACT,kBAAkB;AACtB;AACA;CACC,eAAe;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;CACtB,gCAAgC;IAC7B,mBAAmB;CACtB,cAAc;AACf;AACA;IACI,eAAe;IACf,gBAAgB;CACnB,cAAc;AACf;AACA;CACC,gBAAgB;CAChB,qBAAqB;CACrB,mBAAmB;CACnB,iBAAiB;AAClB;AACA;CACC,kBAAkB;AACnB;AACA;CACC,iBAAiB;AAClB;;AAEA;CACC;EACC,iBAAiB;CAClB;CACA;EACC,gBAAgB;CACjB;CACA;EACC,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;CACtB;AACD","sourcesContent":[".standards-cee-logo {\n\twidth: 140px;\n    margin-bottom: 6px;\n}\n.national-standard-subtext {\n\tfont-size: 22px;\n    font-weight: 200;\n    line-height: 1.2;\n    padding-bottom: 9px;\n\tborder-bottom: 6px solid #ffb202;\n    margin-bottom: 18px;\n\tcolor: #04392b;\n}\n.national-standard-bold {\n    font-size: 40px;\n    font-weight: 900;\n\tcolor: #007154;\n}\n.national-standard-container {\n\tpadding-top: 0px;\n\tpadding-bottom: 120px;\t\n\tmargin-bottom: 30px;\n\tmargin-top: 125px;\n}\n.standard-margin-shift-1 {\n\tmargin-right: 30px;\n}\n.standard-margin-shift-2 {\n\tmargin-left: 30px;\n}\n\n@media only screen and (max-width: 750px) {\n\t.standard-margin-shift-1 {\n\t\tmargin-right: 0px;\n\t}\n\t.standard-margin-shift-2 {\n\t\tmargin-left: 0px;\n\t}\n\t.national-standard-container {\n\t\tmargin-bottom: 30px;\n\t\tmargin-top: 90px;\n\t\tpadding-bottom: 105px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
